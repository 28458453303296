import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-alert-preferences',
  templateUrl: './site-alert-preferences.component.html',
  styleUrls: ['./site-alert-preferences.component.css']
})
export class SiteAlertPreferencesComponent implements OnInit {

  @Input() site: any;

  constructor() { }

  ngOnInit() {
    console.log(this.site);
  }

  colorMap = {
    true: 'text-success',
    false: 'text-danger',
    none: 'text-danger',
    all: 'text-success',
    filtered: 'text-warning',
  }

  

}
