<!-- <a 
  style="cursor: pointer;"
  class="list-group-item clearfix" 
  [routerLink]="dashboard.dashboardId" 
  routerLinkActive="active"
  (click)="hideNav()">
  
  <h5 class="mb-1">{{ dashboard.name }}</h5>

  <p class="mb-1">{{ dashboard.description }}</p>

</a> -->

<li class="list-group-item list-group-item-action flex-column align-items-start">
  <!-- <select class="form-control form-control-sm">
    <option selected value="volvo">move</option>
    <option value="saab">Saab</option>
    <option value="mercedes">Mercedes</option>
    <option value="audi">Audi</option>
  </select> -->
  <div class="d-flex w-100 justify-content-between">
    <a class="mb-0" [routerLink]="dashboard.dashboardId" routerLinkActive="active"
    (click)="hideNav()">- {{ dashboard.name }}</a>
  </div>
  <p class="mb-0 text-muted small">{{ dashboard.description }}</p>

</li>