import { Subject } from "rxjs/Subject";
import { concat } from "rxjs/observable/concat";
import { ConnStrModule } from "./conn-str.module";
import { BlobFile } from "./../_models/blob-file.model";
import { Alert } from "./../_models/alert.model";
import { Site } from "./../_models/site.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class DataStorageService {
  constructor(private httpClient: HttpClient, private connStr: ConnStrModule) {}

  public fileUploaded = new Subject<boolean>();

  getSitesData() {
    console.log("getSitesData()");
    return this.httpClient
      .get<Site[]>(this.connStr.pspBaseUrl + "sites")
      .map((response) => {
        return response;
      });
  }

  getSiteData(siteId: string) {
    console.log("getSiteData()");
    return this.httpClient
      .get<Site>(this.connStr.pspBaseUrl + "sites/" + siteId)
      .map((response) => {
        return response;
      });
  }

  getAlertsData(siteId: string, onlyOpen: boolean) {
    console.log("getAlertsData()");

    // Initialize Params Object
    let myParams = new HttpParams();
    // Begin assigning parameters
    myParams = myParams.append("onlyOpen", onlyOpen.toString());

    return this.httpClient
      .get<Alert[]>(this.connStr.pspBaseUrl + "sites/" + siteId + "/alerts", {
        params: myParams,
        reportProgress: true,
      })
      .map((response) => {
        if (response == null) {
          console.log("no alerts = new array");
          response = new Array<Alert>();
        }
        return response;
      });
  }

  getMyAlertsData(siteId: string, onlyOpen: boolean) {
    console.log("getMyAlertsData()");

    // Initialize Params Object
    let myParams = new HttpParams();
    // Begin assigning parameters
    myParams = myParams.append("onlyOpen", onlyOpen.toString());

    return this.httpClient
      .get<Alert[]>(this.connStr.pspBaseUrl + "sites/" + siteId + "/myAlerts", {
        params: myParams,
        reportProgress: true,
      })
      .map((response) => {
        return response;
      });
  }

  getAlertData(siteId: string, alertId: string) {
    console.log("getAlertData()");
    return this.httpClient
      .get<Alert>(
        this.connStr.pspBaseUrl + "sites/" + siteId + "/alerts2/" + alertId
      )
      .map((response) => {
        console.log(response);
        return response;
      });
  }

  getAlertFiles(siteId: string, alertId: string) {
    console.log("getAlertFiles()");
    return this.httpClient
      .get<BlobFile[]>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/files"
      )
      .map((response) => {
        return response;
      });
  }

  addAlert(siteId: string, alert: Alert) {
    console.log("addAlert()");

    return this.httpClient
      .post<Alert>(
        this.connStr.pspBaseUrl + "sites/" + siteId + "/alerts/",
        alert
      )
      .map((response) => {
        return response;
      });
  }

  addAlertFeedbackData(siteId: string, alertId: string, feedback: string) {
    console.log("addAlert()");
    return this.httpClient
      .post<string>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/addFeedback",
        new Alert()
      )
      .map((response) => {
        return response;
      });
  }

  updateAlertData(siteId: string, alertId: string, alert: Alert) {
    console.log(
      "updateAlertData() alertId = " + alertId + " siteId = " + siteId
    );
    console.log(alert);
    return this.httpClient
      .put<Alert>(
        this.connStr.pspBaseUrl + "sites/" + siteId + "/alerts/" + alertId,
        alert
      )
      .map((response) => {
        return response;
      });
  }

  unlinkFile(siteId: string, alertId: string, fileId: string) {
    console.log("unlinkFile() alertId = " + alertId + " siteId = " + siteId);
    return this.httpClient
      .delete(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/files/" +
          fileId,
        {
          responseType: "text",
        }
      )
      .map((response) => {
        return response;
      });
  }

  uploadFile(siteId: string, formData: FormData, alertId: string = "") {
    console.log("uploadFile() siteId " + siteId + ". alertId " + alertId);
    return this.httpClient
      .post<string>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/UploadFile",
        formData,
        // { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
        { headers: new HttpHeaders().set("enctype", "multipart/form-data") }
      )
      .map((response) => {
        return response;
      })
      .subscribe((data) => {
        console.log("uploaded file");
        console.log(data);
        this.fileUploaded.next(true);
        return data;
      });
  }
}
