import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { NotificationService } from './../../_services/notification.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notificationsChanged = new Subscription();
  notifications = new Array<{}>();
  loading = true;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    
    this.notificationsChanged = this.notificationService.notificationsChanged
      .subscribe(
        data => {
          this.loading = false;
          this.notifications = data;
        }, 
        error => {
          console.log("error getting notifications")
          this.loading = false;
        }
      )

    this.notificationService.getNotifications(null);
  }
  
  onReadAll() {
    this.loading = true;
    this.notificationService.readAllNotifications();
  }

}
