import { InfoAlertService } from './../_services/info-alert.service';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';


@Component({
    selector: 'app-info-alert',
    templateUrl: 'info-alert.component.html'
})

export class InfoAlertComponent implements OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: InfoAlertService) { 
        // subscribe to alert messages
        this.subscription = alertService.getMessage().subscribe(message => { this.message = message; });
    }

    ngOnDestroy(): void {
        // unsubscribe on destroy to prevent memory leaks
        this.subscription.unsubscribe();
    }
}