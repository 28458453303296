import { ConnStrModule } from './../_services/conn-str.module';
import { AuthenticationService } from './../_services/authentication.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';


@Injectable()
export class MsoInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private connStrModule: ConnStrModule
    ) {}

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
                
        if (req.url.toLowerCase().indexOf(this.connStrModule.msrDataUrl) >= 0) {
            // this is a valid request for this interceptor
            console.log("MSO interceptor intercepting");
          } else {
            return next.handle(req);      
          }
          
          req = this.addToken(req, this.authenticationService.getUniverseDataToken());
  
          return <any> next.handle(req)
              .catch(error => {
              if (error instanceof HttpErrorResponse) {
                  switch (( < HttpErrorResponse > error).status) {
                  case 400:
                      return this.handle400Error(error);
                  case 401:
                      return this.handle401Error(req, next);
                  }
              } else {
                  return Observable.throw(error);
              }
          });
      }

      addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    
        return req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        })  
    }


    
  handle400Error(error) {
    console.log("400 error");
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
    //   return this.logoutUser();
      return Observable.throw("");
    }

    return Observable.throw(error);
  }


  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    console.log("401 error");
    if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        console.log("isRefreshingToken", this.isRefreshingToken);

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);

        return this.authenticationService.getMsrToken()
            .switchMap((data) => {
                this.authenticationService.processMsoTokens(data);
                let newToken = this.authenticationService.getUniverseDataToken();
                if (newToken) {
                    this.tokenSubject.next(newToken);
                    req = this.addToken(req, newToken);
                    return next.handle(req);
                }

                // If we don't get a new token, we are in trouble so logout.
                // return this.logoutUser();
                return Observable.throw("");                
            })
            .catch(error => {
                // If there is an exception calling 'refreshToken', bad news so logout.
                //return this.logoutUser();
                return Observable.throw("");
            })
            .finally(() => {
                this.isRefreshingToken = false;
            });
    } else {
        console.log("awaiting new token...")
        return this.tokenSubject
            .filter(token => token != null)
            .take(1)
            .switchMap(token => {
              req = this.addToken(req, token);
                return next.handle(req);
            });
    }
    
}
// logoutUser() {
//     this.authenticationService.logout();
//     return Observable.throw("");
//   }


}