<nav
  class="navbar  navbar-dark navbar-expand-lg"
  [ngStyle]="{ 'background-color': isFromEnerlytics() ? '#0078DC' : '#3E4551' }"
>
  <span class="navbar-brand">
    <span class="d-none d-xl-block">
      <!-- large -->
      <div *ngIf="isFromEnerlytics(); else PMHLogin">
        <a
          href="https://enerlytics.uniper.engineering"
          title="Back to Enerlytics"
          ><i
            class="fa fa-arrow-left"
            aria-hidden="true"
            style="color:#448aff"
          ></i
          ><img src="/assets/enerlytics-icon.png" height="30" alt=""
        /></a>
        <a routerLink="/" class="navbar-brand">
          PMH
        </a>
      </div>
      <ng-template #PMHLogin>
        <a routerLink="/" class="navbar-brand">
          <img src="/assets/circlewhite.png" height="30" alt="" /> Predictive
          Maintenance Hub
        </a>
      </ng-template>
    </span>
    <span class="d-xl-none">
      <!-- small -->
      <div *ngIf="isFromEnerlytics(); else PMHLogin">
        <a
          href="https://enerlytics.uniper.engineering"
          title="Back to Enerlytics"
          ><i
            class="fa fa-arrow-left"
            aria-hidden="true"
            style="color:#448aff"
          ></i
          ><img src="/assets/enerlytics-icon.png" height="30" alt=""
        /></a>
        <a routerLink="/" class="navbar-brand">
          PMH
        </a>
      </div>
      <ng-template #PMHLogin>
        <a routerLink="/" class="navbar-brand">
          <img src="/assets/circlewhite.png" height="30" alt="" /> PMH
        </a>
      </ng-template>
    </span>
  </span>

  <a
    *ngIf="isAuthenticated()"
    routerLink="/notifications"
    class="d-lg-none "
    [ngClass]="notificationClass()"
  >
    <i class="fa fa-bell-o" aria-hidden="true"></i>
    <span *ngIf="notificationCount > 0" class="badge badge-light">{{
      notificationCount
    }}</span>
  </a>

  <button class="navbar-toggler" type="button" (click)="toggleShow()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="toShow()">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" (click)="unShow()" routerLink="/alerts" i18n
          ><i class="fa fa-exclamation-circle"></i> Alerts</a
        >
      </li>
      <li
        *ngIf="canViewActionableAlerts()"
        class="nav-item"
        routerLinkActive="active"
      >
        <a
          class="nav-link"
          (click)="unShow()"
          routerLink="/ActionableAlerts"
          i18n
          ><i class="fa fa-exclamation-circle"></i> Actionable Alerts</a
        >
      </li>
      <li class="nav-item" (click)="unShow()" routerLinkActive="active">
        <a class="nav-link" routerLink="/reports" i18n
          ><i class="fa fa-file-text"></i> Reports</a
        >
      </li>
      <li class="nav-item" (click)="unShow()" routerLinkActive="active">
        <a class="nav-link" routerLink="/dashboards" i18n
          ><i class="fa fa-area-chart"></i> Live Data</a
        >
      </li>
      <li class="nav-item" (click)="unShow()" routerLinkActive="active">
        <a class="nav-link" routerLink="/MSR"
          ><i class="fa fa-cogs fa-rotate-90"></i> MSR</a
        >
      </li>
      <li class="nav-item" (click)="unShow()" routerLinkActive="active">
        <a class="nav-link" routerLink="/FleetAlerts" i18n
          ><i class="fa fa-globe"></i> Fleet</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          (click)="unShow()"
          href="mailto:support@productionsupportportal.com?subject=Production support request"
          i18n
          ><i class="fa fa-envelope"></i> Contact us</a
        >
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item">
        <!-- <a
          class="nav-link"
          routerLink="/manage/AlertPreferences"
          *ngIf="isAuthenticated()"
          i18n
          ><i class="fa fa-cog" aria-hidden="true"></i> Alert Preferences</a
        > -->
        <div *ngIf="isAuthenticated()">
          <div ngbDropdown class="d-inline-block">
            <button
              class="btn btn-outline-primary "
              id="dropdownBasic1"
              ngbDropdownToggle
              i18n
            >
              Hello {{ name }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                class="dropdown-item"
                routerLink="/manage/AlertPreferences"
                *ngIf="isAuthenticated()"
                (click)="unShow()"
                i18n
              >
                <i class="fa fa-cog" aria-hidden="true"></i> Alert Preferences
              </button>
              <button
                class="dropdown-item"
                routerLink="/login"
                (click)="unShow()"
                *ngIf="!isAuthenticated()"
                i18n
              >
                <i class="fa fa-sign-in" aria-hidden="true"></i> Login
              </button>
              <button
                class="dropdown-item"
                (click)="onLogout()"
                (click)="unShow()"
                *ngIf="isAuthenticated()"
                i18n
              >
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                Logout
              </button>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/login" *ngIf="!isAuthenticated()" i18n
          ><i class="fa fa-sign-in" aria-hidden="true"></i> Login</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="onLogout()" *ngIf="isAuthenticated()" i18n
          ><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a
        >
      </li> -->
      <li
        *ngIf="isAuthenticated()"
        class="nav-item d-none d-lg-block"
        routerLinkActive="active"
      >
        <a routerLink="/notifications" [ngClass]="notificationClass()">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <span *ngIf="notificationCount > 0" class="badge badge-light">{{
            notificationCount
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
