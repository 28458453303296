
<div class="row justify-content-center">
  <div class="col-md-6">
    <h1 i18n>New Report</h1>
    <h4 i18n>Report from {{pickedDate() | date:'mediumDate'}} to {{nowDate | date:'mediumDate'}}</h4>
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                name="dpf" [(ngModel)]="modelFrom" ngbDatepicker #df="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="df.toggle()" type="button">
              <i class="fa fa-calendar-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>

    <br>

    <div class="alert alert-primary" role="alert">
      {{dateSetStr}}
    </div>

    <button [disabled]="working" (click)="createReport()" class="btn btn-primary"><i class="fa fa-file-text" aria-hidden="true"></i> {{createText}}</button>
  </div>
</div>