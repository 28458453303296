import { AlertsService } from './../../_services/alerts.service';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-alert-recipients',
  templateUrl: './alert-recipients.component.html',
  styleUrls: ['./alert-recipients.component.css']
})
export class AlertRecipientsComponent implements OnInit {

  @Input() siteId;
  @Input() alertId;

  feedbackEmails = [];
  updateEmails = [];

  loading = true;

  constructor(
    private alertsService: AlertsService
  ) { }

  ngOnInit() {
    this.alertsService.getAlertRecipients(this.siteId, this.alertId)
      .subscribe(
        data => {
          this.loading = false;
          this.feedbackEmails = data['Alert Feedback Recipients'];
          this.updateEmails = data['Alert Update Recipients'];
        },
        error => {
          this.loading = false;          
        }
      )
  }

}
