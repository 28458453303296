<div class="container-fluid">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4" i18n>
        <i class="fa fa-area-chart"></i> Dashboards</h1>
      <p class="lead" i18n>Please select a dashboard, or create a new one.</p>
      <hr class="my-4">


    </div>
  </div>
</div>
