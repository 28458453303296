import { ConnStrModule } from "./conn-str.module";
import { DataStorageService } from "./data-storage.service";
import { Subscription } from "rxjs/Subscription";
import { HttpClient } from "@angular/common/http";
import { Site } from "./../_models/site.model";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class SitesService {
  sitesChanged: Subject<Site[]> = new Subject<Site[]>();

  siteChanged: Subject<Site> = new Subject<Site>();
  //siteChanged : Observable<Site> = new Observable
  private site: Site;

  private sites: Site[];

  constructor(
    private httpClient: HttpClient,
    private dataStorageService: DataStorageService,
    private connStr: ConnStrModule
  ) {}

  getCompanies() {
    console.log("getCompanies()");
    return this.httpClient
      .get<{}[]>(this.connStr.pspBaseUrl + "companies")
      .map((response) => {
        return response;
      });
  }
  getAllCompanies() {
    console.log("getAllCompanies()");
    return this.httpClient.get<{}[]>(
      this.connStr.pspBaseUrl + "companies/GetAll"
    );
  }
  getAllSites() {
    console.log("getAllSites()");
    return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + "sites/GetAll");
  }

  getSitesData() {
    console.log("getSites()");

    this.dataStorageService.getSitesData();
    // this.sites = data;
    // this.sitesChanged.next(this.sites);
  }

  getSite(siteId: string) {
    console.log("getSite() " + siteId);
    this.dataStorageService.getSiteData(siteId).subscribe((data) => {
      this.site = data;
      this.siteChanged.next(this.site);
    });
  }

  getMsrSites() {
    console.log("getMsrSites()");
    return this.httpClient
      .get<{}[]>(this.connStr.pspBaseUrl + "MsrTemp/sites")
      .map((response) => {
        return response;
      });
  }

  getMsrSiteData() {
    console.log("getMsrSites()");
    return this.httpClient
      .get<{}[]>(this.connStr.pspBaseUrl + "MsrTemp/sites/grchp")
      .map((response) => {
        return response;
      });
  }

  getSiteLoadTags(siteId: string) {
    console.log("getSiteLoadTags() " + siteId);
    return this.httpClient
      .get<{}[]>(this.connStr.pspBaseUrl + "sites/" + siteId + "/loadTags")
      .map((response) => {
        return response;
      });
  }

  setSites(sites: Site[]) {
    this.sites = sites;
    this.sitesChanged.next(this.sites.slice());
  }

  updateMapDetails(
    siteId: string,
    newlongitude: number,
    newlatitude: number,
    newzoom: number
  ) {
    console.log("updateMapDetails");
    let newMap = {
      longitude: newlongitude,
      latitude: newlatitude,
      zoom: newzoom,
    };
    console.log("sending new map details");
    console.log(newMap);
    return this.httpClient
      .put<{}>(
        this.connStr.pspBaseUrl + "sites/" + siteId + "/updateMapDetails",
        newMap
      )
      .map((response) => {
        return response;
      });
  }

  getWeatherData(longitude: number, latitude: number) {
    let customParams =
      "lat=" +
      latitude.toString() +
      "&lon=" +
      longitude.toString() +
      "&APPID=207ede50c3a97afd472cd6bd8d3d3500";

    return this.httpClient
      .get("https://api.openweathermap.org/data/2.5/weather?" + customParams)
      .map((response) => {
        return response;
      });
  }

  getForecastData(longitude: number, latitude: number) {
    let customParams =
      "lat=" +
      latitude.toString() +
      "&lon=" +
      longitude.toString() +
      "&APPID=207ede50c3a97afd472cd6bd8d3d3500";

    return this.httpClient
      .get("https://api.openweathermap.org/data/2.5/forecast?" + customParams)
      .map((response) => {
        return response;
      });
  }
  // getAreas(siteId: string) {
  //   console.log('getAreas() ' + siteId);
  //   this.dataStorageService.getSiteData(siteId)
  //     .subscribe(data => {
  //       this.site = data;
  //       this.siteChanged.next(this.site);
  //     })
  // }
}
