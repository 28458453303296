<div class="container-fluid">
    <div class="row justify-content-center mt-3">
      <div class="col-md-4">

        <h4 class="display-4" i18n><i class="fa fa-bell-o" aria-hidden="true"></i> Notifications</h4>

        <button type="button" (click)="onReadAll()" class="btn btn-link pull-right" i18n><i class="fa fa-eye-slash" aria-hidden="true"></i> Mark all as read</button>
        
        <hr>

        <div *ngIf="loading; else notificationList" class="text-center">
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
        </div>

        <ng-template #notificationList>

          <div class="mt-5">
            <app-notification 
              *ngFor="let notification of notifications" 
              [notification]="notification">
            </app-notification>
          </div>
       </ng-template>

      </div>
    </div>
  </div>