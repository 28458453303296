import { InfoAlertService } from './../../_services/info-alert.service';
import { AuthenticationService } from './../../_services/authentication.service';

import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { concat } from 'rxjs/operator/concat';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css']
})
export class ManageAccountComponent implements OnInit {

  loading = true;
  
  model = {
    "name": "",
    "ccEmail": ""
  };

  constructor(
    private userManagementService: UserManagementService,
    private authenticationService: AuthenticationService,
    private infoAlertService: InfoAlertService   
    
  ) { }

  ngOnInit() {

    // this.model["ccEmail"] = "";

    this.userManagementService.getUserDetails(this.authenticationService.loggedInUserHashId())
      .subscribe(
        data => {
          console.log(data)
            this.loading = false;

            this.model["name"] = data["Name"];
            // this.model["ccEmail"] = data["ccEmail"];

            // for validation to work
            // if (this.model["ccEmail"] == null){
            //   this.model["ccEmail"] = "";
            // }

        },
        error => {
            this.loading = false;
            this.infoAlertService.error(error);
        });
  }

  onSubmit() {
    this.loading = true;

    this.userManagementService.updateUserDetails(this.authenticationService.loggedInUserHashId(),this.model)
      .subscribe(
        data => {
            this.loading = false;
            console.log("successfully changed");
            console.log(data);
            this.infoAlertService.success("Details updated");
            
        },
        error => {
            console.log("error changing");
            console.log(error);
            this.loading = false;
            this.infoAlertService.error(error);
        });
  }

}
