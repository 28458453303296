import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NotificationService } from './../../_services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input() notification;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
  }

  getSeenStyle(){
    let style = 'normal'
    if (this.notification != null) {
      if (!this.notification['seen']){
        return style = 'bold'
      }
    }
    return style;
  }

  onNavigate(){
    console.log("notification")    
    console.log(this.notification)
    this.notificationService.seenNotification(this.notification.hashId);
    this.router.navigate([this.notification.url])
  }

}
