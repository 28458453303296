<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-8 mt-5">

      <div>
        <h1 i18n>My Alert Preferences</h1>
        <p i18n>
          Set site filters so you only see the units, areas, and analysis types relevant to you.<br>
          If you're only interested in one site you might want to start by selecting "Disable everything".
        </p>

        <div [hidden]="loadingSummary" class="mt-2 mb-2">
          <button type="button" class="btn btn-success" (click)="selectAll()" i18n>Enable everything</button>
          <button type="button" class="btn btn-warning" (click)="deselectAll()" i18n>Disable everything</button>
        </div>

        <hr>

        <div *ngIf="loadingSummary; else summary" class="row justify-content-center">            
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>           
        </div>

  
        <ng-template #summary>

          <app-site-alert-preferences *ngFor="let aSite of sites" [site]="aSite"></app-site-alert-preferences>

        </ng-template>

      </div>
    </div>
  </div>
