import { AlertHistory } from "./alert-history.model";
import { PspUser } from "./user.model";

export class Alert {
  public hashId: string;
  public name: string;

  public status: {
    id: number;
    description: string;
  };

  public severity: {
    id: number;
    description: string;
  };

  public analysisTypes: {
    id: string;
    description: string;
  }[];

  public units: {
    unitId: number;
    name: string;
  }[];

  public areas: {
    areaId: number;
    name: string;
  }[];

  public items: {
    itemId: number;
    name: string;
  }[];

  public createdBy: PspUser;
  public utcCreated: Date;
  public modifiedBy: PspUser;
  public utcModified: Date;
  public closedBy: PspUser;
  public utcClosed: Date;

  public summary: {};
  public recommendation: {};
  public feedback: {};

  public changeLog: AlertHistory[];

  constructor() {
    this.status = {
      id: 0,
      description: ""
    };
    this.severity = {
      id: 0,
      description: ""
    };
    this.analysisTypes = [];
    this.units = [];
    this.areas = [];
    this.items = [];
  }

  // constructor(seed: string) {

  //     this.hashId = seed; // HelperFunctions.randomString(6, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
  //     this.name = 'Alert ' + seed;

  //     this.createdBy = new PspUser('Mr' + seed);
  //     this.utcCreated = new Date();

  //     this.modifiedBy = new PspUser('Ms' + seed);
  //     this.utcModified = new Date();

  //     this.summary = 'This is the Alert Summary for ' + this.name + '. It is a brief description of the issue.';
  //     this.feedback = 'This is the Alert Feedback for ' + this.name + '. It is the most recent communication from site.';
  //     this.recommendation = 'This is the Alert Recommendation for ' + this.name + '. It is the most recent communication we have given to site.';

  //     this.history = [];
  //     for (var _i = 0; _i < 6; _i++) {
  //         this.history.push(new AlertHistory(this.modifiedBy))
  //     }

  // }
}
