import { GlobalAdminComponent } from './admin/global-admin/global-admin.component';
import { UserAccessComponent } from './admin/user-access/user-access.component';

import { CompaniesAdminComponent } from './admin/companies-admin/companies-admin.component';
import { AddUserComponent } from './admin/add-user/add-user.component';
import { UsersComponent } from './admin/users/users.component';
import { AdminsComponent } from './admin/admins/admins.component';
import { AuthAdminGuard } from './_guards/auth-admin.guard';
import { AdminComponent } from './admin/admin.component';
import { ActionableHomeComponent } from './analyst-actionable/actionable-home/actionable-home.component';
import { AlertsModule } from './alerts/alerts.module';

import { ExternalComponent } from './login/external/external.component';
import { AlertPreferencesComponent } from './user/alert-preferences/alert-preferences.component';
import { TutorialVideosComponent } from './videos/tutorial-videos/tutorial-videos.component';
import { FleetAlertsHomeComponent } from './fleet-alerts/fleet-alerts-home/fleet-alerts-home.component';
import { FleetAlertsComponent } from './fleet-alerts/fleet-alerts.component';
import { MsrListComponent } from './msr/msr-list/msr-list.component';
import { AuthMsrGuard } from './_guards/auth-msr.guard';
import { MsrDetailComponent } from './msr/msr-detail/msr-detail.component';
import { MsrHomeComponent } from './msr/msr-home/msr-home.component';
import { NotificationsComponent } from './notifications/notifications/notifications.component';
import { ReportsHomeComponent } from './reports/reports-home/reports-home.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';
import { PreferencesComponent } from './user/preferences/preferences.component';
import { AccessComponent } from './user/access/access.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './auth/signin/signin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReportsComponent } from './reports/reports.component';
import { NgModule, Component } from '@angular/core';
import { Routes,RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { ManageAccountComponent } from './user/manage-account/manage-account.component';
import { NewReportComponent } from './reports/new-report/new-report.component';
import { RequestAccountComponent } from './user/request-account/request-account.component';
import { MsrComponent } from './msr/msr/msr.component';
import { MsrSitesComponent } from './msr/msr-sites/msr-sites.component';
import { VideosComponent } from './videos/videos.component';
import { SiteAlertPrefEditComponent } from './user/site-alert-pref-edit/site-alert-pref-edit.component';
import { AnalystActionableComponent } from './analyst-actionable/analyst-actionable.component';
import { SitesAdminComponent } from './admin/sites-admin/sites-admin.component';
import { AdminHomeComponent } from './admin/home/admin-home.component';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { homedir } from 'os';
import { AlertsHomeComponent } from './alerts/alerts-home/alerts-home.component';


const appRoutes: Routes = [

  { path: '',component: HomeComponent, canActivate: [AuthGuard] },

  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},

  { path: 'Admin', component: AdminComponent, canActivate: [AuthGuard, AuthAdminGuard], children: [
    { path: '', component: AdminHomeComponent},
    { path: 'Admins', component: AdminsComponent},
    { path: 'Users', component: UsersComponent},
    { path: 'AddUser', component: AddUserComponent},
    { path: 'EditUser/:userId', component: EditUserComponent},
    { path: 'UserAccess', component: UserAccessComponent, children: [
      { path: 'global', component: GlobalAdminComponent},
      { path: 'company', component: CompaniesAdminComponent},
      { path: 'site', component: SitesAdminComponent},      
    ]},
    { path: 'Sites', component: SitesAdminComponent}
  ]
  },

  // { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard], children: [
  //   { path: '', component: AlertsHomeComponent, canActivate: [AuthGuard] },
  //   { path: 'archived/:siteId', component: AlertArchiveComponent, canActivate: [AuthGuard] },
  //   { path: ':siteId', component: AlertListComponent, canActivate: [AuthGuard] },
  //   { path: ':siteId/new', component: AlertEditComponent, canActivate: [AuthGuard] },
  //   { path: ':siteId/:alertId', component: AlertDetailComponent, canActivate: [AuthGuard]},
  //   { path: ':siteId/:alertId/edit', component: AlertEditComponent, canActivate: [AuthGuard]},
  //   ]
  // },

  { path: 'alerts', canActivate: [AuthGuard], loadChildren: () => import('app/alerts/alerts.module').then(m => m.AlertsModule)},

  { path: 'reports',component: ReportsHomeComponent, canActivate: [AuthGuard], children: [
    { path: '', component: ReportsComponent, canActivate: [AuthGuard] },
    { path: ':siteId/new', component: NewReportComponent, canActivate: [AuthGuard] },
    { path: ':siteId/:reportId', component: ReportDetailComponent, canActivate: [AuthGuard] },
    ]
  },

  { path: 'dashboards', canActivate: [AuthGuard], loadChildren: () => import('app/dashboards/dashboards.module').then(m => m.DashboardsModule)},

  { path: 'MSR', component: MsrComponent, canActivate: [AuthGuard], children: [
    { path: '', component: MsrHomeComponent },
    { path: 'sites', component: MsrSitesComponent, canActivate: [AuthMsrGuard] },
    { path: 'sites/:siteId', component: MsrListComponent, canActivate: [AuthMsrGuard] },
    { path: 'sites/:siteId/:MsrId', component: MsrDetailComponent, canActivate: [AuthMsrGuard] },
    ]
  },

  
  { path: 'FleetAlerts', component: FleetAlertsComponent, canActivate: [AuthGuard], children: [
    { path: '', component: FleetAlertsHomeComponent },
    ]
  },
  
  { path: 'ActionableAlerts', component: AnalystActionableComponent, canActivate: [AuthGuard], children: [
    { path: '', component: ActionableHomeComponent },
    ]
  },

  { path: 'Videos', component: VideosComponent, canActivate: [AuthGuard], children: [
    { path: '', component: TutorialVideosComponent },
    ]
  },

  { path: 'manage', component: ManageAccountComponent, canActivate: [AuthGuard] },
  { path: 'manage/AlertPreferences', component: AlertPreferencesComponent, canActivate: [AuthGuard] },
  { path: 'manage/AlertPreferences/:siteId', component: SiteAlertPrefEditComponent, canActivate: [AuthGuard] },
  { path: 'manage/AlertPreferencesOld', component: PreferencesComponent, canActivate: [AuthGuard] },
  
  { path: 'userAccess/:userId', component: AccessComponent, canActivate: [AuthGuard] },


  { path: 'site/:siteId/files/upload', component: FileUploadComponent, canActivate: [AuthGuard] },

  // { path: 'chart/:id', component: ChartDetailComponent, canActivate: [AuthGuard] },

  { path: 'ExternalAuth/:authProvider', component: ExternalComponent },

  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component: ForgottenPasswordComponent },
  { path: 'resetpassword/:resetId', component: PasswordResetComponent },
  { path: 'RequestAccount', component: RequestAccountComponent },

  { path: '403', component: NotAuthorisedComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
  
];

@NgModule({
  imports: [
    // RouterModule.forRoot(appRoutes, {
    //   preloadingStrategy: PreloadAllModules
    // })
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRouting {

}
