import { appendFile } from 'fs';
import { Params } from '@angular/router';
import { ReportsComponent } from './../reports/reports.component';
import { retry } from 'rxjs/operator/retry';
import { Observable } from 'rxjs/Observable';
import { ConnStrModule } from './conn-str.module';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class ReportsService {

    reportChanged = new Subject<{}>();
    reportsChanged = new Subject<{}[]>();
    alertRemoved = new Subject<{}>();

    private report = {};
    private reports = new Array<{}>();

  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule) { }


    loadReports(){
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'reports')
        .map((response) => {
            return response;
        })
        // .subscribe(
        //     data => {
        //         console.log("reports got")
        //         this.reports = data;
        //         this.reportsChanged.next(this.reports.slice())
        //     }
        // )
    }

    loadReport(siteId: string, reportId: string, filter: boolean){


        let myParams= new HttpParams;
        myParams = myParams.append('filter', filter.toString())

      return this.httpClient.get<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId, {params: myParams})
        .map((response) => {
          console.log("report got")
            return response
        })
        //   .subscribe(
        //     data => {
        //         console.log(this.report)
        //         this.report = data;
        //         this.reportChanged.next(this.report)
        //         }
        //     )
    }

    createReport(siteId: string, dateFrom: Date){
        
       let myParams= new HttpParams;
       myParams = myParams.append('from', dateFrom.toISOString())

      return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId, '', {params: myParams})
        .map((response) => {
            console.log("Created new report");
            console.log(response);
            return response
          })
    }

    getLastReportDate(siteId: string) {
        return this.httpClient.get<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/lastReportDate')
        .map((response) => {
          console.log("last report date got")
            return response
          })
    }

    updateReport(siteId: string, reportId: string, report: {}){
        this.report = report;
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId, this.report)
          .map((response) => {
              return response;
          })
        //   .subscribe(
        //       data => {
        //           this.report = data;
        //           this.reportChanged.next(this.report);
        //       }
        //   )
    }
    sendForApproval(siteId: string, reportId: string){
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/sendForApproval','')
        .map((response) => {
            return response;
        })
    }
    approveReport(siteId: string, reportId: string){
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/approve','')
        .map((response) => {
            return response;
        })
    }
    rejectReport(siteId: string, reportId: string){
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/reject','')
        .map((response) => {
            return response;
        })
    }
    sendReport(siteId: string, reportId: string){
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/send','')
        .map((response) => {
            return response;
        })
    }

    clearAndReGet(siteId: string, reportId: string){
        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/ReloadAllAlerts','')
        .map((response) => {
            return response;
        })
    }

    toggleReportAlertReview(siteId: string, reportId: string, alertId: string, reviewed: boolean){
        console.log("reviewed param = " + reviewed)
        let myParams = new HttpParams();  
        myParams = myParams.append('reviewed', reviewed.toString())

        console.log(myParams);

        return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/' + alertId, '', {params: myParams})
          .map((response) => {
              return response;
          })
          
      }


      removeReportAlert(siteId: string, reportId: string, alertId: string){

        // var index = this.report['alerts'].findIndex(x => x['hashId'] == alertId)
        // if (index > -1) {
        //   this.report['alerts'].splice(index, 1);
        //   this.reportChanged.next(this.report);
        // }

        return this.httpClient.delete<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId + '/' + alertId)
          .map((response) => {
              return response;
          })
          
      }

      deleteReport(siteId: string, reportId: string){

        return this.httpClient.delete<{}>(this.connStr.pspBaseUrl + 'reports/' + siteId + '/' + reportId)
          .map((response) => {
              return response;
          })
          
      }
}
