<h2>User Access</h2>
<small class="text-muted">
    Set user permissions <b>globally</b>, by <b>company</b>, or by <b>site</b>.
</small>
<hr>

<div class="row">
    <div class="col">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['global']" routerLinkActive="active"><i class="fa fa-exclamation-circle"></i> Global</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['company']" routerLinkActive="active">Company</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['site']" routerLinkActive="active">Site</a>
            </li>
        </ul>
        <div class="tab-content border border-top-0 p-2">
            <router-outlet></router-outlet>
        </div>  
    </div>
</div>

