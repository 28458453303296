import { Injectable } from '@angular/core';

@Injectable()
export class ChartService {

    generateRandomData(){
        var result = 'Date,Value\n';
        var rollNum = 0;
        for (var index = 0; index < 1000; index++) {
            var rndNum = Math.random() - 0.5
            rollNum = rollNum + rndNum;
            let today = new Date();
            var rollDate = new Date(today.getTime() + (1000 * 60 * 60 * 24 * index))
            let year = rollDate.getFullYear();
            let month = rollDate.getMonth() + 1;
            let day = rollDate.getDate();
            result = result + year + '/' + month + '/' + day + ',' + rollNum + '\n'
        }
        return result;
    }

    generateRandomDatedData(xMin, xMax){
        var result = 'Date,Value\n';
        var rollNum = 0;
        var stepAmount = (xMax - xMin)/1000
        for (var index = 0; index < 1000; index++) {
            var rndNum = Math.random() - 0.5
            rollNum = rollNum + rndNum;
            let today = new Date();
            var rollDate = new Date(xMin + (stepAmount * index))
            let year = rollDate.getFullYear();
            let month = rollDate.getMonth() + 1;
            let day = rollDate.getDate();
            result = result + year + '/' + month + '/' + day + ',' + rollNum + '\n'
        }
        return result;
    }

}