import { Signal } from './../_models/signal.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSignals',
  pure: true
})
export class FilterSignalsPipe implements PipeTransform {

    transform(signals: Signal[], filterStr: string): any {
      if (signals.length == 0 || filterStr.length == 0 ) {
      return signals;      
    }
    // break the search string into a list of words
    let searchTerms = filterStr.split(" ");

    const resultArray = [];
    // check each signal for all search terms
    for (const signal of signals) {
      let allMatched = true;
        for (let term of searchTerms) {
          if (
            // we stringify each signal so we check the entire contents of the signal by default
            JSON.stringify(signal).toUpperCase().indexOf(term.toUpperCase()) >= 0
          ) {
            // all terms found - do nothing
          } else {
            // this term was missing. Do not add the signal to the results
            allMatched = false;
            break;
          }
        }
      if (allMatched) {
        resultArray.push(signal);
      }

    }
    return resultArray;
  }

}
