<div class="row justify-content-center text-center mt-3">

  <div class="col-md-6">
    <h4 class="display-4" i18n> <i class="fa fa-history" aria-hidden="true"></i> Alert Archives for<br> {{site.name}}</h4>
  </div>

</div>

<div class="row justify-content-center">

  <div class="col-md-6">

    <form (ngSubmit)="onSearch()">
      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="searchStr" name="searchStr" placeholder="Search all alerts">
      </div>
      <button type="submit" class="btn btn-primary" i18n><i class="fa fa-search" aria-hidden="true"></i> Search Archives</button>
    </form>

    <hr>

    <div *ngIf="searching" class="text-center">
      <div>
        <h3 i18n>Searching Archives</h3>
        <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
        <br>
        <small i18n>Please be patient, your search might return many results!</small>
      </div>
    </div>

    <div i18n *ngIf="errorMessage.length > 0" class="alert alert-danger" role="alert">
      Error:<br>
      {{errorMessage}}
    </div>

  
      </div>
    </div>  
    

    <div class="row justify-content-center">
      <div class="col-md-6">

    <div *ngIf="searched && !searching">
      <h4 i18n>{{alerts.length}} results found in archives</h4>

      <div *ngIf="alerts.length > 0">

        
        <form>
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="filterStr" name="filterStr" placeholder="Filter Results">
          </div>
          <!-- <button type="submit" class="btn btn-primary"><i class="fa fa-filter" aria-hidden="true"></i> Filter Results</button> -->
        </form>


        
        <div class="row">
          
          <div class="col">
            <div class="btn btn-success pull-left mr-1" (click)="exportAlertsToCSV(alerts);" role="button" i18n>
                <i class="fa fa-download" style="color:white" aria-hidden="true"></i> Export Alerts
            </div>
            <div class="btn-group pull-right" ngbRadioGroup name="radioBasic" [(ngModel)]="displayMode">
              <label ngbButtonLabel class="btn-primary"i18n>
                <input ngbButton type="radio" value="detailed" >Detailed View <i class="fa fa-expand" aria-hidden="true"></i>
              </label>
              <label ngbButtonLabel class="btn-primary" i18n>
                <input ngbButton type="radio" value="short"><i class="fa fa-compress" aria-hidden="true"></i> Short View
              </label>
          </div>
      </div>
    </div>
        <div class="row">
          <div id = "selectedAlerts" class="col">
                    
        <div *ngIf="displayMode=='detailed'" class="list-group">
          <app-alert-item *ngFor="let alertElement of alerts | filterAlerts:filterStr:selectedStatus | sortAlerts:'utcModified'" [alert]="alertElement"></app-alert-item>
        </div>

        <div *ngIf="displayMode=='short'" class="list-group">
          <app-alert-item-short *ngFor="let alertElement of alerts | filterAlerts:filterStr:selectedStatus | sortAlerts:'utcModified'"
            [alert]="alertElement"></app-alert-item-short>
        </div>

          </div>
        </div>

          
      </div>

    </div>
    
  </div>
</div>

