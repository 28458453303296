import { concat } from 'rxjs/operator/concat';
import { Alert } from './../_models/alert.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAlerts',
  pure: true
})
export class SortAlertsPipe implements PipeTransform {

  transform(alerts: Alert[], fieldName: string): any {
    if (alerts.length === 0) {
      console.log("no alerts to sort");
      return alerts;
    };
    // // sort by date (oldest to newest)
    // alerts.sort((obj1, obj2) => {
    //   if (obj1["utcModified"] > obj2["utcModified"]) {
    //       return 1;
    //   }
    //   if (obj1["utcModified"] < obj2["utcModified"]) {
    //       return -1;
    //   }
    //   return 0;
    // });

    // console.log("alerts filtered by date");
    // // newest to oldest
    // alerts.reverse();
    // console.log(alerts);

    // then sort by severity (i.e. results are by severity then date)
    // least severe to most severe
    alerts.sort((obj1, obj2) => {
      if (obj1["severity"].id > obj2["severity"].id) {
          return 1;
      }
      if (obj1["severity"].id < obj2["severity"].id) {
          return -1;
      }
      if (obj1["severity"].id = obj2["severity"].id) {
        if (obj1["utcModified"] > obj2["utcModified"]) {
          return -1;
      }
      if (obj1["utcModified"] < obj2["utcModified"]) {
          return 1;
      }
      }
      return 0;
    });

    
    console.log("alerts filtered by severity then date");
    console.log(alerts);

    // most severe to least severe
    //alerts.reverse();

  return alerts;
  }

  // transform(alerts: Alert[], fieldName: string, reverse: boolean): any {
  //   if (alerts.length === 0) {
  //     return alerts;
  //   };
  //   alerts.sort((obj1, obj2) => {
  //     if (obj1[fieldName] > obj2[fieldName]) {
  //         return 1;
  //     }
  //     if (obj1[fieldName] < obj2[fieldName]) {
  //         return -1;
  //     }
  //     return 0;
  // });
  // if (reverse) {
  //     alerts.reverse();
  // }
  // return alerts;
  // }

}
