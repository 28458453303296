<div class="row justify-content-center mb-3 ml-3 mr-3">
  <div class="col-md-3 col-md-offset-3 mt-3">

    <h2 i18n>Request an Account</h2>
    <form name="form" (ngSubmit)="f.form.valid && Submit()" #f="ngForm" novalidate>


      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
        <input type="text" name class="form-control" name="name" [(ngModel)]="model.name" #name="ngModel" required placeholder="Name"/>
        <div *ngIf="f.submitted && !name.valid" class="help-block" i18n>Please enter your name</div>
      </div>

      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
        <input type="email" email class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required placeholder="Email"/>
        <div *ngIf="f.submitted && !email.valid" class="help-block" i18n>Please enter a valid email</div>
      </div>

      <div class="form-group">
          <button [disabled]="loading || sent" class="btn btn-primary">{{requestText}}</button>
          <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin fa-2x"></i>
      </div>
    </form>
    <app-info-alert></app-info-alert>
  </div>
</div>  
