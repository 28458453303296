<div class="row justify-content-center mb-3 ml-3 mr-3">
  <div class="col-md-3 col-md-offset-3 mt-3">
    <h2 i18n>Reset Password</h2>
    <form [hidden]="!codeOk" name="form" (ngSubmit)="passf.form.valid && SubmitReset()" #passf="ngForm" novalidate>

        <div class="form-group">
            <label for="passwordA" i18n>Password</label>
            <input type="password" class="form-control" id="passwordA" 
                required 
                [(ngModel)]="model.passwordA" name="passwordA" #passwordA="ngModel"/>
            <div class="alert alert-danger"
              [hidden]="passwordA.valid || passwordA.pristine" i18n>
                Password is required
            </div>
            <small id="passwordHelp" class="form-text text-muted" i18n>At least 6 characters with a bit of variety - include some capitals, numbers, or special characters.</small>
        </div>

        <div class="form-group">
            <label for="passwordB" i18n>Repeat Password</label>
            <input type="password" class="form-control" id="passwordB" 
                required 
                [(ngModel)]="model.passwordB" name="passwordB" #passwordB="ngModel"/>
        </div>

        <div [hidden]="passwordA.pristine || passwordB.pristine">
            <!-- <div class="alert alert-danger"
                [hidden]="passwordA.value == passwordB.value">
                Passwords do not match
            </div> -->
            <span [hidden]="passwordA.value != passwordB.value" i18n><i class="fa fa-check text-success"></i> Passwords match<br></span>
            <span [hidden]="passwordA.value == passwordB.value" i18n><i class="fa fa-times text-danger"></i> Passwords match</span>
        </div>


        <div class="form-group">
            <button [disabled]="loading || !codeOk || passwordA.value != passwordB.value" class="btn btn-primary" i18n>Change password</button>
            <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin fa-2x"></i>
        </div>
        
    </form>
    <app-info-alert></app-info-alert>
    <div [hidden]="codeOk">
        <p [hidden]="!otherError" i18n>
            If you can only see the reset link below<br>
            and cannot see an error code, please get in touch.
        </p>
        <a routerLink="/resetpassword" i18n><i class="fa fa-key" aria-hidden="true"></i> Get a new reset link</a>
    </div>
  </div>
</div>
