<div class="row">
  <div class="col-sm-8">

    <h3>Share Dashboard</h3>
    <p>
      You can share dashboards with anyone who has a Production Solutions account.
    </p>
      
  </div>
</div>

<div class="row">
  <div class="col-md-6 mt-3">
    <form (ngSubmit)="onInvite()" #inviteForm="ngForm">

      <div class="form-row">
        <div class="form-group col-lg-8">
          <label for="exampleInputEmail1">Email address</label>
          <input 
            [(ngModel)]="invite.email" 
            name="email" 
            #email="ngModel" 
            email
            class="form-control" 
            placeholder="Enter email">
          <small id="emailHelp" class="form-text text-muted">The user will be sent an invite link.</small>
        </div>

        <div class="form-group col-lg-4">
          <label>Access</label>
          <select 
            [(ngModel)]="invite.type" class="form-control"
            name="type"
            #type="ngModel">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
        </div> 
      </div>

      <button [disabled]="!inviteForm.form.valid" type="submit" class="btn btn-primary"><i class="fa fa-lock" aria-hidden="true"></i> Invite</button>
      <span>{{ status }}</span>
   
    </form>
  </div>

</div>

<hr>

<div class="row">
  <div class="col-md-6 mt-3">
    
    <h5>Invited Users</h5>
    <ul>
      <li *ngFor="let user of invitedUsers()"><span [innerHTML]="user.user | pspUser"></span> ({{user.role}})</li>
    </ul>
    
    <h5>Active Users</h5>
    <ul>
      <li *ngFor="let user of activeUsers()"><span [innerHTML]="user.user | pspUser"></span> ({{user.role}})</li>
    </ul>

  </div>
</div>

