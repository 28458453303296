import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params } from '@angular/router';
import { DataStorageService } from './../_services/data-storage.service';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput',{static: false}) fileInput;
  @ViewChild('fileDescription', {static: false}) fileDescription: any;

  siteId: string;
  alertId: string;
  uploading = false;

  @Input() siteIdIn: string;
  @Input() alertIdIn: string;

  ngOnInit() {
    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId']
        if (this.siteIdIn != null) {
          this.siteId = this.siteIdIn;
        }
      }
    );
    this.route.queryParams
    .subscribe(
      (params: Params) => {
        this.alertId = params['alertId'] || ''; // defaults to '' if no alertId parameter supplied
        if (this.alertIdIn != null) {
          this.alertId = this.alertIdIn;
        }
      }
    );

    this.dataStorageService.fileUploaded.subscribe(
      (uploaded: boolean) => {
        if (uploaded) {
          this.uploading = false; 
          this.activeModal.dismiss('Upload complete');
        } else {
          
        }
      }
    )

  }
  
    constructor(
      private dataStorageService: DataStorageService,
      private route: ActivatedRoute,
      public activeModal: NgbActiveModal) {}

    
    upload() {
      let fileBrowser = this.fileInput.nativeElement;
      if (fileBrowser.files && fileBrowser.files[0] && this.fileDescription.nativeElement.value.toString().length > 0) {

        this.uploading = true;

        const formData = new FormData();
        formData.append('file', fileBrowser.files[0]);
        formData.append('description', this.fileDescription.nativeElement.value);
        console.log(this.fileDescription);
        console.log(this.fileDescription.nativeElement.value);
        this.dataStorageService.uploadFile(this.siteId, formData, this.alertId);
        // this.projectService.upload(formData, this.project.id).subscribe(res => {
        //   // do stuff w/my uploaded file
        // });
      }
    }
  }
