import { FormattingService } from './../../_services/formatting.service';
import { AlertsService } from './../../_services/alerts.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fleet-alerts-kpi',
  templateUrl: './fleet-alerts-kpi.component.html',
  styleUrls: ['./fleet-alerts-kpi.component.css']
})
export class FleetAlertsKpiComponent implements OnInit {

  @Input() company = {
    name: ''
  };
  loading = true;

  fleetAlertData;

  chartHeight = 122;

  chartKpiSeverity;
  optionsKpiSeverity: Object;

  chartAllSeverity;
  optionsAllSeverity: Object;
  allSeveritySeries;

  chartAllStatus;
  optionsAllStatus: Object;
  allStatusSeries;



  sitesCritSeverity = [];
  sitesRequireFeedback = [];
  sitesSentFeedback = [];

  sevCollapsed = true;
  statCollapsed = true;


  categoryLinks;

  constructor(
    private alertsService: AlertsService,
    private formattingService: FormattingService
  ) { }

  ngOnInit() {

    this.categoryLinks = {};
    var comp = this;

    this.fleetAlertData = {
      alertCount: '-'
    };

    this.optionsKpiSeverity = {
      chart: {
        type: 'bar',
        height: null,
        width: null,
        reflow: true,
    },
    title: {
        text: 'Alerts per Site by Severity and Status'
    },
    xAxis: {
        categories: [],
        labels: {
          formatter: function () {                       
            return '<a href="../alerts/' + comp.stationLink(this.value) + '">' + this.value + '</a>';            
          }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Alert count'
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
            }
        },
        gridLineWidth: 0,
        minorGridLineWidth: 0
    },
    responsive: {
      rules: [{
          condition: {
              maxWidth: 2000
          }
      }]
    },
    colors:['#ff0000', '#ffa500', '#ffff00', '#dbdbdb', '#52f2fa', '#92d050'],
    legend: {
        enabled: false
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0.1,
      }
    },
    credits: {
      enabled: false
    },
    series: []
    }
    

    this.optionsAllStatus = {
      chart: {
        type: 'pie',
        marginTop: 0,
        reflow: true,
      },
      title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: -5,
          style: {
            fontSize: '1.5rem',
            color:'#495057'
          }
      },
      subtitle: {
          text: ''
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,             
          }
            // showInLegend: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: 'All Status',
        innerSize: '70%',
        data: []
      }],
  };

  this.optionsAllSeverity = {
    chart: {
      type: 'pie',
      marginTop: 0,
      reflow: true,
    },
    title: {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        y: -5,
        style: {
          fontSize: '1.5rem',
          color:'#495057'
        }
    },
    subtitle: {
        text: ''
    },
    plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,             
        }
          // showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      type: 'pie',
      name: 'All Severity',
      innerSize: '70%',
      data: []
    }],
};


    this.alertsService.getFleetAlertSummary(this.company['name'])
      .subscribe(
        data => {
          this.loading = false;
          console.log("fleet data for " + this.company['name'] + " got.")
          console.log(data);
          this.fleetAlertData = data;
          this.buildChartData();
        }
      )
  }

  siteCountStr(){
    if (this.fleetAlertData == null){
      return "-";
    }
    if (this.fleetAlertData['sites'] == null){
      return "-";
    }
    return this.fleetAlertData['sites'].length;
  }

  siteCount(){
    if (this.fleetAlertData == null){
      return 0;
    }
    if (this.fleetAlertData['sites'] == null){
      return 0;
    }
    return this.fleetAlertData['sites'].length;
  }

  stationLink(stnName: string){
    return this.categoryLinks[stnName];
  }

  saveInstanceSeverity(chartInstance) {
    console.log("saveInstanceSeverity");
    this.chartKpiSeverity = chartInstance;
    console.log(this.chartKpiSeverity);
  }

  saveInstanceAllSeverity(chartInstance) {
    console.log("saveInstanceAllSeverity");
    this.chartAllSeverity = chartInstance;
    console.log(this.chartAllSeverity);
  }

  saveInstanceAllStatus(chartInstance) {
    console.log("saveInstanceAllStatus");
    this.chartAllStatus = chartInstance;
    console.log(this.chartAllStatus);
  }

  buildChartData(){
    let seriesSevs = [];
    let categoriesSites = [];
    console.log("fleetAlertData");
    console.log(this.fleetAlertData);

    this.fleetAlertData['sites'].forEach(aSite => {
      categoriesSites.push(aSite['name']);
      this.categoryLinks[aSite['name']] = aSite['siteId'];
      this.chartHeight += 50;

    });

    console.log("categoryLinks")
    console.log(this.categoryLinks)
    
    if ( this.fleetAlertData['sites'].length == 0){
      return;
    }
    
    // each severity type is a series
    // build this from the first site's data
    this.fleetAlertData['sites'][0]['alertsBySeverity'].forEach(aSev => {
      seriesSevs.push({
        name: aSev['severityDescription'],
        stack: 'severity',
        id: aSev['severityId'],
        data: [],
      });
    });

    // each status type is also a series
    // build this from the first site's data
    this.fleetAlertData['sites'][0]['alertsByStatus'].forEach(aStat => {

      var statusCol = this.formattingService.getStatusColour(aStat['statusId'])

      // add "requires feedback" status to the start, so it appears first 
      if (aStat['statusId'] == 3) {
        seriesSevs.unshift({
          name: aStat['statusDescription'],
          stack: 'status',
          id: aStat['statusId'],
          data: [],
          color: statusCol
        });
      } else {
        seriesSevs.push({
          name: aStat['statusDescription'],
          stack: 'status',
          id: aStat['statusId'],
          data: [],
          color: statusCol
        });        
      }


    });


    // add the severity stacks
    seriesSevs.forEach(aSev => {
      if (aSev['stack'] != 'severity'){
        console.log("not severity. skipping")
        return;
      }
      this.fleetAlertData['sites'].forEach(aSite => {
        let alertCount = aSite['alertsBySeverity'].find(x => x.severityId == aSev.id)['alertCount']
        aSev['data'].push(alertCount);

        if (aSev.id == 10 && alertCount > 0){
          this.sitesCritSeverity.push(
            {
              id: aSite['siteId'],
              name: aSite['name'],
              alerts: alertCount
            }
          )
        }


      });
    });

    // add the status stacks
    seriesSevs.forEach(aStat => {
      if (aStat['stack'] != 'status'){
        console.log("not status. skipping")
        return;
      }
      this.fleetAlertData['sites'].forEach(aSite => {
        let alertCount = aSite['alertsByStatus'].find(x => x.statusId == aStat.id)['alertCount']
        aStat['data'].push(alertCount);

      });
    });

    console.log("seriesSevs");
    console.log(seriesSevs);

    console.log("categoriesSites");
    console.log(categoriesSites);

    this.chartKpiSeverity.xAxis[0].setCategories(categoriesSites);
    this.chartKpiSeverity.series = [];
    for( let aSevSeries of seriesSevs ) {
      this.chartKpiSeverity.addSeries(aSevSeries, false);
    }
    // seriesSevs.forEach(aSevSeries => {
    //   this.chartKpiSeverity.addSeries(aSevSeries);
    // });
    
    console.log("setting height to " + this.chartHeight)
    this.chartKpiSeverity.setSize(null, this.chartHeight);
    this.chartKpiSeverity.redraw();

    this.buildAllSeverityPie(this.fleetAlertData["alertsBySeverity"]);
    this.buildAllStatusPie(this.fleetAlertData["alertsByStatus"]);
  }

  buildAllSeverityPie(severityTypes: {}[]) {    
    
    var critSeverity = 0;
    // set the series
    console.log("building all severity pie")
    this.allSeveritySeries = [];
    severityTypes.forEach(sevElement => {
      
        if (sevElement["severityId"] == 10){
          critSeverity = sevElement["alertCount"];
        }

        this.allSeveritySeries.push(
          {
            name: sevElement["severityDescription"],
            y: sevElement["alertCount"],
            color: this.formattingService.getSeverityColour(sevElement['severityId'])
          }
        );

    });


    // set the title
    this.chartAllSeverity.setTitle({
      text: critSeverity + ` Alerts req.<br>
      <span style="color:#ff0000">Immediate</span><br>
      <span style="color:#ff0000">Action</span>`,
      align: 'center',
      verticalAlign: 'middle',
      y: -5,
      style: {
        fontSize: '1rem',
        color:'#495057'
      }
    })
    this.chartAllSeverity['series'][0].setData(this.allSeveritySeries);
  }


  buildAllStatusPie(statusTypes: {}[]) {    
    
    var actionStatus = 0;
    // set the series
    console.log("building all status pie")
    this.allStatusSeries = [];
    statusTypes.forEach(statusElement => {
      
      if(statusElement['statusId'] == 3){
        actionStatus = statusElement['alertCount'];
      }

      this.allStatusSeries.push(
        {
          name: statusElement["statusDescription"],
          y: statusElement["alertCount"],
          color: this.formattingService.getStatusColour(statusElement['statusId']),
        }
      );

      this.sitesSentFeedback = [];
      this.sitesRequireFeedback = [];
      this.fleetAlertData['sites'].forEach(siteElement => {

        siteElement['alertsByStatus'].forEach(statusElement => {

          //Requires Feedback
          if (statusElement['statusId'] == 3 && statusElement['alertCount'] > 0) {
            this.sitesRequireFeedback.push({
              name: siteElement['name'],
              id: siteElement['siteId'],
              alerts: statusElement['alertCount']
            })
          }

           // Feedback Received
           if (statusElement['statusId'] == 11 && statusElement['alertCount'] > 0) {
            this.sitesSentFeedback.push({
              name: siteElement['name'],
              id: siteElement['siteId'],
              alerts: statusElement['alertCount']
            })
          }

        });

      });

    });


    // set the title
    this.chartAllStatus.setTitle({
      text: actionStatus + ` Alerts req.<br>
      <span style="color:#007bff">Action from</span><br>
      <span style="color:#007bff">Sites</span>`,
      align: 'center',
      verticalAlign: 'middle',
      y: -5,
      style: {
        fontSize: '1rem',
        color:'#495057'
      }
    })
    this.chartAllStatus['series'][0].setData(this.allStatusSeries);
  }




}
