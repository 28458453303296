<a [routerLink]="['./..']">
  &larr; Back to Site</a> <br>
  
<div *ngIf="loading; else msrDetail" class="row justify-content-center">
  <div>
    <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
  </div>
</div>

<ng-template #msrDetail>

<div class="col text-center">
  <div class="row justify-content-center">
    <h4> - PLACEHOLDER DATA - </h4>
  </div>
</div>


<div class="row justify-content-center">
  <div class="col-md-6">
    <h4 class="display-4">{{plantMsr.plantItem}}</h4>
  </div>
</div>

<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
    
    <p class="card-subtitle mt-1 text-muted"><i class="fa fa-map-marker"></i> Functional Location</p>
    <h4>{{plantMsr.cmmS_FunctionalLocation}}</h4>

  </div>
</div>

<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
    <div class="card-deck">

      <div class="card">
        <div class="card-body">

          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-industry"></i> Plant type</p>
          <h3>{{plantMsr.plantType}}</h3>

        </div>
      </div>

      <div class="card">
        <div class="card-body">

          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-wrench"></i> Maintenance Strategy</p>
          <h3>{{plantMsr.plantStrategy.maintenanceStrategy}}</h3>
          <a (click)="treeCollapsed = !treeCollapsed" [routerLink]="" class="card-link"><i class="fa fa-angle-down"></i> Decision logic</a>
          <div [ngbCollapse]="treeCollapsed">

            <span *ngIf="plantMsr.plantStrategy.maintenanceStrategyDecision.cbmGate1">
              Gate 1 <i class="fa fa-check text-success"></i> <b>Passed</b><br>
            </span>
            <span *ngIf="!plantMsr.plantStrategy.maintenanceStrategyDecision.cbmGate1">
              Gate 1 <i class="fa fa-times text-danger"></i> <b>Failed</b><br>
            </span>
           
            <span *ngIf="plantMsr.plantStrategy.maintenanceStrategyDecision.cbmGate2">
              Gate 2 <i class="fa fa-check text-success"></i> <b>Passed</b><br>
            </span>
            <span *ngIf="!plantMsr.plantStrategy.maintenanceStrategyDecision.cbmGate2">
              Gate 2 <i class="fa fa-times text-danger"></i> <b>Failed</b><br>
            </span>
            
            CBM Confidence <b>{{cbmConfidence()}}%</b><br>
            <span *ngIf="plantMsr.plantStrategy.cbmGate1_FailureReason && plantMsr.plantStrategy.cbmGate1_FailureReason.length > 0">
              Gate 1 details: 
              <b>{{plantMsr.plantStrategy.cbmGate1_FailureReason}}</b>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
    <div class="card-deck">

      <div class="card">
        <div class="card-body">

          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-calendar"></i> Expected Overhaul Date</p>
          <h3>{{plantMsr.expectedOverhaul | date:'MMMM yyyy'}}</h3>
          <hr>
          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-history"></i> EOH</p>
          <h3>{{plantMsr.currentEOH}}</h3>
          <hr>
          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-clock-o"></i> Plant Life</p>
          <h3>{{plantMsr.maxEOH}}</h3>

        </div>
      </div>

      <div class="card">
        <div class="card-body">

          <p class="card-subtitle mb-2 text-muted"><i class="fa fa-heartbeat"></i> EOH Consumed</p>
          <chart [options]="eohOptions" (load)="saveInstanceEoh($event.context)">
          </chart>

        </div>
      </div>

    </div>
  </div>
</div>


<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
    
    <div class="card">
      <h5 class="card-header"><i class="fa fa-file-text-o"></i> SAP Notifications <span class="badge badge-primary">{{plantMsr.notifications.length}}</span></h5>
      <div class="card-body">

        <!-- <p>
          1 x <span class="badge badge-danger">Priority 3</span><br>
          1 x <span class="badge badge-warning">Priority 2</span><br>
          1 x <span class="badge badge-secondary">Priority 1</span>
        </p> -->

      <a (click)="sapCollapsed = !sapCollapsed" [routerLink]="" class="card-link"><i class="fa fa-angle-down"></i> Details</a>
          
      <div [ngbCollapse]="sapCollapsed">

          <div *ngFor="let notification of plantMsr.notifications">
            <div class="row mb-2">

              <div class="col-md-6">
                <h5 *ngIf="notification.priorty == 1"><span class="badge badge-danger">Priority 1</span></h5>
                <h5 *ngIf="notification.priorty == 2"><span class="badge badge-warning">Priority 2</span></h5>
                <h5 *ngIf="notification.priorty == 3"><span class="badge badge-secondary">Priority 3</span></h5>
                <span class="text-muted">Notification</span> <b>{{notification.notificationID}}</b>
                <br>
                <span class="text-muted">Created</span> <b>{{notification.created | date:'dd MMM yyyy'}}</b>
              </div>
              
              <div class="col-md-6">
                <span class="text-muted">Remedial action:</span><br>
                <b>{{notification.remedialAction}}</b>
              </div>

            </div>
              <div class="row">
                  <div class="col">
                      <span class="text-muted">Category</span> <b>{{notification.category}}</b><br>
                      <span class="text-muted">Asset</span> <b>{{notification.asset}}</b><br>
                      <span class="text-muted">Defect</span> <b>{{notification.defect}}</b><br>
                  </div>
                </div>

            <hr>
          </div>
      </div>
      
      </div>      
    </div>
  </div>
</div>


<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
   
    <div class="card">
      <h5 class="card-header"><i class="fa fa-info-circle"></i> Condition Info. Available  </h5>
      
      <div class="card-body">

        <h3>{{plantMsr.conditionInformation.cbM_Model}}</h3>
        
        <a (click)="detailsCollapsed = !detailsCollapsed" [routerLink]="" class="card-link"><i class="fa fa-angle-down"></i> Asset info.</a>
          
        <div [ngbCollapse]="detailsCollapsed">

          <!-- available -->
          <span *ngFor="let condInfo of plantMsr.conditionInformation.cbM_ModelMatrix">
            <span *ngIf="condInfo.measurementBit == 1"><i class="fa fa-check text-success"></i> {{condInfo.measurementType}}<br></span>
          </span>

          <!-- not available -->
          <span *ngFor="let condInfo of plantMsr.conditionInformation.cbM_ModelMatrix">
            <span *ngIf="condInfo.measurementBit == 0"><i class="fa fa-check text-danger"></i> {{condInfo.measurementType}}<br></span>
          </span>


          <!-- <table class="table">
            <thead>
              <tr>
                <th scope="col">Available</th>
                <th scope="col">Unavailable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                </td>
                <td>
                </td>
              </tr>
            </tbody>
          </table> -->

        </div>
      </div>
    </div>

  </div>
</div>



<div class="row justify-content-center mb-4">
  <div class="col-lg-6">
    
    <div class="card">
      <h5 class="card-header"><i class="fa fa-cubes"></i> SpheriCAL ACM Models <span class="badge badge-primary">{{plantMsr.acmModels.length}}</span></h5>
      <div class="card-body">
        
        <p>
          <span *ngFor="let aModel of plantMsr.acmModels">
            <b>{{aModel.model}}</b><br>
          </span>
        </p>

        <a (click)="sphericalCollapsed = !sphericalCollapsed" [routerLink]="" class="card-link"><i class="fa fa-angle-down"></i> Details</a>
              
        <div [ngbCollapse]="sphericalCollapsed">
          
          <p *ngFor="let detailedModel of plantMsr.acmModels">
            <b>{{detailedModel.model}}</b><br>
            <span class="text-muted"><i class="fa fa-tags"></i> {{detailedModel.modelTags.length}} Tags</span><br>
            <span *ngFor="let aTag of detailedModel.modelTags">{{aTag}}<br></span>
          </p>
          <hr>
                
        </div>

      </div>
    </div>

  </div>
</div>


</ng-template>