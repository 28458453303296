import { SignalData } from './../_models/signal-data.model';
import { ConnStrModule } from './conn-str.module';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class SignalGetterService {

  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule) {}

    // getSignalData(sourceType: string, dataSource: string, idAtSource: string){
    //   console.log("getSignalData()")
    //   console.log(sourceType)
    //   if(sourceType == 'RND'){
    //     this.getTestSignalData(idAtSource);
    //   };
    //   if(sourceType == 'PI'){
    //     this.getPiData(dataSource, idAtSource);
    //   }
    // }
  
    getPiData(dataSource: string, idAtSource: string){
      console.log("getPiData()");
      let tagNames = [idAtSource];
  
      var to = new Date(Date.now());    
      var from = new Date(to);
      from.setDate(from.getDate() - 7);
  
      // Initialize Params Object
      let myParams = new HttpParams();  
  
      // Begin assigning parameters
      let toStr = to.toISOString().replace(/T/g," ");
      toStr = toStr.replace(/Z/g,"")
      console.log("toStr " + toStr);

      let fromStr = from.toISOString().replace(/T/g," ");
      fromStr = fromStr.replace(/Z/g,"")
      console.log("fromStr " + fromStr);
      
      myParams = myParams.append('from', fromStr);
      myParams = myParams.append('to', toStr);
      // myParams = myParams.append('from', '2017-12-10');
      // myParams = myParams.append('to', '2017-12-17');
      console.log(myParams)
      // return this.httpClient.post < {}[] > (this.connStr.universeDataUrl + 'dataseries/' + dataSource + '/PIdata', tagNames, {params: myParams})
      return this.httpClient.post <{}[]> (this.connStr.universeDataUrl + 'dataseries/' + dataSource + '/PIdata', tagNames, {params: myParams})
      .map((response) => {
        return response;
      })
      // .subscribe(data => {
        
      //   console.log("Pi Signal got! - " + idAtSource);
      //   console.log(data);
      //   for (var iTag = 0, lenTag = data.length; iTag < lenTag; iTag++) {
  
      //     let aTagData = data[iTag];
  
      //     // define the signal returned
      //     let newSignal = new SignalData;
      //     newSignal.idAtSource = idAtSource;
      //     newSignal.engUnits = aTagData['engUnits'];
      //     newSignal.data = [];
      //     let values = aTagData['piTagData'];
      //     // let breakout = 0;
  
      //     for (var iPoint = 0, lenVals = values.length; iPoint < lenVals; iPoint++) {
  
      //       let pointElement = values[iPoint];
  
      //       let dateString = pointElement['dateTime'] + "Z";
  
      //       newSignal.data.push({
      //         "dateTime": dateString,
      //         "value": parseFloat(pointElement['tagValue'])
      //       })
      //       // newSignal.data[dateString] = parseFloat(pointElement['tagValue']);
           
      //     };
      //     console.log("pi signal data");
      //     this.signalChanged.next(newSignal);
            
      //   };
      // })
    }
  
    getDatedPiData(dataSource: string, idAtSource: string, from: Date, to: Date){
      console.log("getPiData()");
      let tagNames = [idAtSource];
    
      // Initialize Params Object
      let myParams = new HttpParams();  
  
      // Begin assigning parameters
      let toStr = to.toISOString().replace(/T/g," ");
      toStr = toStr.replace(/Z/g,"")
      console.log("toStr " + toStr);

      let fromStr = from.toISOString().replace(/T/g," ");
      fromStr = fromStr.replace(/Z/g,"")
      console.log("fromStr " + fromStr);
      
      myParams = myParams.append('from', fromStr);
      myParams = myParams.append('to', toStr);
      
      return this.httpClient.post <{}[]> (this.connStr.universeDataUrl + 'dataseries/' + dataSource.toUpperCase() + '/PIdata', tagNames, {params: myParams})
      .map((response) => {
        return response;
      })
    }

    // getTestSignalData(signalId: string) {
    //   console.log("getTestSignalData(" + signalId + ")")
  
    //   return this.httpClient.get < {}[] > (this.connStr.pspBaseUrl + 'dashboards/signals/' + signalId + "/data")
    //     .map((response) => {
    //       return response;
    //     })
    //     .subscribe(data => {
    //       console.log("Signal got! - " + signalId + ". Waiting 0 seconds.");
    //       setTimeout(
    //         () => {
    //           console.log("Signal got! - " + signalId);
    //           // define the signal returned
    //           let newSignal = new SignalData;
    //           newSignal.idAtSource = signalId;
    //           newSignal.data = data;
    //           console.log("rnd data");
    //           // update the signals array, 
    //           // and push out a signal update
  
    //           // if the signal exists...
    //           // let signalFound: boolean = false;
    //           // for (let existingSignal of this.signals ) {
    //           //   if (existingSignal.signalId === existingSignal.signalId ) {
    //           //     console.log("Signal exists. Updating signal.");
    //           //     existingSignal.data = data;
    //           //     signalFound = true;
    //           //   }
    //           // }
    //           // // if the signal doesn't exist...
    //           // if (!signalFound) {
    //           //     console.log("Signal does not exist. Adding new signal.");
    //           //     let newSignal = new Signal;
    //           //     newSignal.signalId = signalId;
    //           //     newSignal.data = data;
    //           //   this.signals.push(newSignal);
    //           // }
  
    //           // push update 
    //           this.signalChanged.next(newSignal);
    //         }, 0
    //       )
    //     })
    // }

}
