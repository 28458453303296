import { Router } from "@angular/router";
import { FormattingService } from "./../../_services/formatting.service";
import { AlertsService } from "./../../_services/alerts.service";
import { Component, OnInit, Input } from "@angular/core";
import "rxjs/add/observable/timer";
import { timer, from, observable } from "rxjs";
import { AuthenticationService } from "./../../_services/authentication.service";
import { element } from "protractor";

@Component({
  selector: "app-actionable-company",
  templateUrl: "./actionable-company.component.html",
  styleUrls: ["./actionable-company.component.css"]
})
export class ActionableCompanyComponent implements OnInit {
  @Input() company = {
    name: ""
  };
  loading = true;

  fleetAlertData = [];

  constructor(
    private authenticationService: AuthenticationService,
    private alertsService: AlertsService,
    private formattingService: FormattingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.GetActionableAlerts();
  }

  timerAlerts;
  timerAlertsStarted = false;

  isBridgehead() {
    return this.authenticationService.isBridgehead();
  }
  GetFilterArgs() {
    if (this.isBridgehead()) {
      return { description: "Pending Translation" };
    }
    return "";
  }
  filterArgs = this.GetFilterArgs();

  GetActionableAlerts() {
    this.loading = true;
    console.log("GetActionableAlerts()");
    if (!this.timerAlertsStarted) {
      console.log("notification check not started. Starting");
      this.timerAlertsStarted = true;
      timer(50, 180000).subscribe(
        () => {
          this.loading = true;
          this.fleetAlertData = [];
          const fleetsAlerts = this.alertsService.getFleetActionableAlerts(
            this.company["name"]
          );
          from(fleetsAlerts)
            .pipe(data => data)
            .subscribe(data => {
              console.log("alert data for " + this.company["name"] + " got.");
              console.log(data);
              //data.forEach(element => {if( element['alerts'].status['description'] === 'Feedback Received' ){
              this.fleetAlertData = data;
            });

          //.filter(data => data.alert.status.description = 'Pending Translation');
          this.loading = false;
          // this.buildChartData();
        },
        error => {
          this.loading = false;
        }
      );
    }
  }

  getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  siteCountStr() {
    if (this.fleetAlertData === null) {
      return "-";
    }
    return this.fleetAlertData.length;
  }

  alertCountStr() {
    if (this.fleetAlertData === null) {
      return "-";
    }

    let alertCounter = 0;
    for (let siteElement of this.fleetAlertData) {
      const alerts: [] = siteElement.alerts;
      alertCounter += alerts.length;
    }
    return alertCounter;
  }
}
