import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Signal } from '../../_models/signal.model';
import { concat } from 'rxjs/operator/concat';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit {

  @Input() signalResult;

  @Output() selectedSignal = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  onSignalAddClick(){
    console.log("signal clicked: ");
    console.log(this.signalResult);
    this.selectedSignal.emit(this.signalResult);
    // this.dashboardsService.addSignalClick(this.signalResult['sourceType'],this.signalResult['sourceId'],this.signalResult['idAtSource'],this.signalResult['name']);
  }

}
