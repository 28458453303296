import { UserManagementService } from './../../_services/user-management.service';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-preferences',
  templateUrl: './alert-preferences.component.html',
  styleUrls: ['./alert-preferences.component.css']
})
export class AlertPreferencesComponent implements OnInit {

  loadingSummary = true;
  loadingSite = true;
  siteSelected = false;

  model = {};

  dropdownListUnits = [];
  selectedUnits = [];

  dropdownListAreas = [];
  selectedAreas = [];

  dropdownListAnalysis = [];
  selectedAnalysis = [];



  dropdownSettings = { 
    text:"Select",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true
  };

  sites = new Array<any>();

  colorMap = {
    true: 'table-success',
    false: 'table-warning',
    none: 'table-danger',
    all: 'table-success',
    filtered: 'table-secondary',
  }

  constructor(
    private userManagementService: UserManagementService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.model = {
      "site": "",
      "alertUpdates": true,
      "alertFeedback": true,
      "alertReports": true,
      "alertSummaryType": "",
      "allUnits": true,
      "allAreas": true,
      "allItems": true,
      "allAnalysis": true
    }
    
    this.userManagementService.getAcmPreferences()
      .subscribe(
        data => {
          console.log(data)
            this.loadingSummary = false;
            this.sites = data;

        },
        error => {
            this.loadingSummary = false;
            // this.infoAlertService.error(error);
        });
  }

   
  getSummary(){
    console.log("loading summary");
    this.loadingSummary = true;
    this.userManagementService.getAcmPreferences()
    .subscribe(
      data => {
        console.log(data)
        this.loadingSummary = false;
        this.sites = data;

      },
      error => {
          this.loadingSummary = false;
          // this.infoAlertService.error(error);
      });
  }

  onRowClick(site:{}){
    console.log(site)
    this.siteSelected = true;
    this.model = {};
    this.getSitePrefs(site['site'])
  }

  getSitePrefs(siteId: string){
    this.loadingSite = true;
    this.userManagementService.getSiteAcmPreferences(siteId)
    .subscribe(
      data => {
        console.log(data)
        this.loadingSite = false;
        this.model = data;

        this.dropdownListUnits = [];
        this.selectedUnits = [];
        for (let unit of this.model['availableUnits']){
          this.dropdownListUnits.push({
            id: unit['UnitId'],
            itemName: unit['Name']
          })
        };
        if(this.model['allUnits']){
          this.model['selectedUnits'] = [];
        }
        for (let unit of this.model['selectedUnits']){
          this.selectedUnits.push({
            id: unit['UnitId'],
            itemName: unit['Name']
          })
        };

        this.dropdownListAreas = [];
        this.selectedAreas = [];
        for (let area of this.model['availableAreas']){
          this.dropdownListAreas.push({
            id: area['AreaId'],
            itemName: area['Name']
          })
        };
        if(this.model['allAreas']){
          this.model['selectedAreas'] = [];
        }
        for (let area of this.model['selectedAreas']){
          this.selectedAreas.push({
            id: area['AreaId'],
            itemName: area['Name']
          })
        };

        this.dropdownListAnalysis = [];
        this.selectedAnalysis = [];
        for (let analysis of this.model['availableAnalysis']){
          this.dropdownListAnalysis.push({
            id: analysis['Id'],
            itemName: analysis['Description']
          })
        };
        if(this.model['allAnalysis']){
          this.model['selectedAnalysis'] = [];
        }
        for (let analysis of this.model['selectedAnalysis']){
          this.selectedAnalysis.push({
            id: analysis['Id'],
            itemName: analysis['Description']
          })
        };

      },
      error => {
          this.loadingSite = false;
          this.siteSelected = false;
          // this.infoAlertService.error(error);
      });
    }
  

  onSubmit(){
    console.log(this.model);
    this.loadingSite = true;
    this.model['selectedUnits'] = this.selectedUnits;
    this.model['selectedAreas'] = this.selectedAreas;
    this.model['selectedAnalysis'] = this.selectedAnalysis;
    this.userManagementService.updateSiteAcmPreferences(this.model['site'], this.model)
      .subscribe(
        data => {
          console.log("successful save")
          this.loadingSummary = false;
          this.siteSelected = false;
          this.getSummary();
        },
        error => {
            this.loadingSummary = false;
            this.siteSelected = false;
            // this.infoAlertService.error(error);
        });

  }

  selectAll(){
    let confirmSelection = confirm("Are you sure?\nThis will reset all filters and you will receive everything.");
    if (!confirmSelection){
      return;
    }
    this.loadingSummary = true;
    this.userManagementService.enableAllAcmPreferences()
    .subscribe(
      data => {
        this.getSummary();
      },
      error => {
          this.loadingSummary = false;
          this.siteSelected = false;
          alert("Error to enabling all");
          // this.infoAlertService.error(error);
      });
  }

  deselectAll(){
    let confirmSelection = confirm("Are you sure?\nThis will reset all filters and you will receive nothing.");
    this.loadingSummary = true;
    this.userManagementService.disableAllAcmPreferences()
    .subscribe(
      data => {
        this.getSummary();
      },
      error => {
          this.loadingSummary = false;
          this.siteSelected = false;
          alert("Error to disabling all");
          // this.infoAlertService.error(error);
      });
  }

}