import { Subscription } from 'rxjs/Subscription';
import { ReportsService } from './../_services/reports.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  reportsSubscription: Subscription = new Subscription;
  loading = true;

  constructor(
    private reportsService: ReportsService
  ) { }

  reports: {}[];

  ngOnInit() {

    this.reportsSubscription = this.reportsService.reportsChanged
      .subscribe(
        (data) => {
          console.log("Report change detected")
          this.getReports();
        },
        (error) => {
          console.log("reportsService.getReports() ERROR")
          console.log(error);
        }
      )
      this.getReports()
  }

  getReports(){
    this.loading = true;
    this.reportsService.loadReports()
      .subscribe(
        data => {
          this.loading = false;
          this.reports = data;
        },
        error => {
          this.loading = false;
          alert("Error getting reports");
        }
      )
  }

  ngOnDestroy(){
    this.reportsSubscription.unsubscribe();
  }

}
