//import { setTimeout } from 'timers';

import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, RouterModule } from '@angular/router';

@Component({
  selector: 'app-accept-dashboard-invitation',
  templateUrl: './accept-dashboard-invitation.component.html',
  styleUrls: ['./accept-dashboard-invitation.component.css']
})
export class AcceptDashboardInvitationComponent implements OnInit {

  constructor(
    private dashboardsService: DashboardsService,
    private route: ActivatedRoute,
    private router: Router ) { }

  inviteId = "";
  checking = true;
  checkGood = false;
  checkBad = false;

  info = "";

  ngOnInit() {

    this.dashboardsService.hideSidebar()

    this.route.params
      .subscribe(
        (params: Params) => {
          this.inviteId = params['inviteId']
          console.log("inviteId " + this.inviteId)

          this.dashboardsService.acceptDashboardInvite(this.inviteId)
            .subscribe(
              (data) => {
                this.checking = false;
                this.checkGood = true;
                this.redirectToDashboard(data['dashboard']);
              },
              (error) => {
                this.checking = false;
                this.checkBad = true;
                if (error['error'] != null){
                  this.info = error['error'];
                }
              }
            )

        }
      )

  }

redirectToDashboard(dashId: string){
  this.info = ""
  setTimeout(
    () => {
      let navStr = 'dashboards/' + dashId
      this.router.navigate([navStr]);
    }
    ,1500
  )
}

}
