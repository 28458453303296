<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-8">

      <div>
        <h1 i18n>Alert Preferences</h1>

        <div class="mt-2 mb-2">
          <button type="button" class="btn btn-success" (click)="selectAll()" i18n>Enable everything</button>
          <button type="button" class="btn btn-warning" (click)="deselectAll()" i18n>Disable everything</button>
        </div>


        <div *ngIf="loadingSummary; else summary" class="row justify-content-center">            
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>           
        </div>
  
          <ng-template #summary>

        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" i18n>Site</th>
              <th scope="col" class="text-center"i18n>Update Emails</th>
              <th scope="col" class="text-center" i18n>Feedback Emails</th>
              <th scope="col" class="text-center" i18n>Main Reports</th>
              <th scope="col" class="text-center" i18n>Short Reports</th>
              <th scope="col" class="text-center" i18n>Units</th>
              <th scope="col" class="text-center" i18n>Areas</th>
              <th scope="col" class="text-center" i18n>Analysis</th>
            </tr>
          </thead>
          <tbody *ngFor="let site of sites">
            <tr (click)="onRowClick(site)">
              <th scope="row">{{site.site}}</th>
              <td [ngClass]="colorMap[site.alertUpdates]" class="text-center">
                <i [hidden]="!site.alertUpdates" class="fa fa-check"></i>
                <i [hidden]="site.alertUpdates" class="fa fa-times"></i>
              </td>
              <td [ngClass]="colorMap[site.alertFeedback]" class="text-center">
                <i [hidden]="!site.alertFeedback" class="fa fa-check"></i>
                <i [hidden]="site.alertFeedback" class="fa fa-times"></i>
              </td>
              <td [ngClass]="colorMap[site.alertReports]" class="text-center">
                <i [hidden]="!site.alertReports" class="fa fa-check"></i>
                <i [hidden]="site.alertReports" class="fa fa-times"></i>
              </td>
              <td class="text-center">{{site.alertReportType}}</td>
              <td [ngClass]="colorMap[site.selectedUnitsDesc]" class="text-center">{{site.selectedUnitsDesc}}</td>
              <td [ngClass]="colorMap[site.selectedAreasDesc]" class="text-center">{{site.selectedAreasDesc}}</td>
              <td [ngClass]="colorMap[site.selectedAnalysisDesc]" class="text-center">{{site.selectedAnalysisDesc}}</td>
            </tr>
          </tbody>
        </table>

        </ng-template>


        <div *ngIf="loadingSite; else siteForm" class="row justify-content-center">
          <div *ngIf="siteSelected">
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
          </div>
        </div>

        <ng-template #siteForm>

          <form (ngSubmit)="onSubmit()" #acmPrefForm="ngForm">

            <!-- <div class="form-group">
          <label for="site">For site</label>
          <select class="form-control" id="site"
                  required
                  [(ngModel)]="model.site" name="site"
                  #site="ngModel">
            <option *ngFor="let site of sites" [value]="site">{{site}}</option>
          </select>
        </div> -->
            <h3 i18n>Site: {{model.site}}</h3>



            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="alertUpdates" [(ngModel)]="model.alertUpdates" type="checkbox" value="" i18n> Receive alert updates
              </label>
            </div>

            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="alertFeedback" [(ngModel)]="model.alertFeedback" type="checkbox" value=""> Receive alert feedback
              </label>
            </div>

            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="alertReports" [(ngModel)]="model.alertReports" type="checkbox" value="" i18n> Receive main reports
              </label>
            </div>

            <hr>

            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="allUnits" [(ngModel)]="model.allUnits" type="checkbox" value="" i18n> See all units
              </label>
            </div>
            <div [hidden]="model.allUnits" class="form-group">
              <label for="units" i18n>Units</label>
              <angular2-multiselect id="units" [data]="dropdownListUnits" name="selectedUnits" [(ngModel)]="selectedUnits" [settings]="dropdownSettings">
              </angular2-multiselect>
              <hr>
            </div>

            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="allAreas" [(ngModel)]="model.allAreas" type="checkbox" value="" i18n> See all areas
              </label>
            </div>
            <div [hidden]="model.allAreas" class="form-group">
              <label for="areas" i18n>Areas</label>
              <angular2-multiselect id="areas" [data]="dropdownListAreas" name="selectedAreas" [(ngModel)]="selectedAreas" [settings]="dropdownSettings">
              </angular2-multiselect>
              <hr>
            </div>

            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" name="allAnalysis" [(ngModel)]="model.allAnalysis" type="checkbox" value="" i18n> See all analysis types
              </label>
            </div>
            <div [hidden]="model.allAnalysis" class="form-group">
              <label for="analysis" i18n>Analysis</label>
              <angular2-multiselect id="analysis" [data]="dropdownListAnalysis" name="selectedAnalysis" [(ngModel)]="selectedAnalysis"
                [settings]="dropdownSettings">
              </angular2-multiselect>
              <hr>
            </div>

            <!-- <div class="form-group">
          <label for="alterEgo">Alter Ego</label>
          <input type="text" class="form-control" id="alterEgo"
                [(ngModel)]="model.alterEgo" name="alterEgo">
        </div>
  
        <div class="form-group">
          <label for="power">Hero Power</label>
          <select class="form-control" id="power"
                  required
                  [(ngModel)]="model.power" name="power"
                  #power="ngModel">
            <option *ngFor="let pow of powers" [value]="pow">{{pow}}</option>
          </select>
          <div [hidden]="power.valid || power.pristine" class="alert alert-danger">
            Power is required
          </div>
        </div> -->

            <button type="submit" class="btn btn-success" [disabled]="!acmPrefForm.form.valid" i18n>
              Save
            </button>
            <!-- <button type="button" class="btn btn-default" (click)="newHero(); acmPrefForm.reset()">New Hero</button> -->
          </form>

        </ng-template>

      </div>
    </div>
  </div>
