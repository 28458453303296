<div class="row mb-3">
  <div class="col-sm-1"></div>
  <div class="col-sm-6">
    <div *ngIf="alertId != null">
      <h3 i18n class="mt-3 mb-3">Edit Alert</h3>
    </div>

    <div *ngIf="alertId == null">
      <h3 i18n class="mt-3 mb-3">Create Alert</h3>
    </div>

    <div *ngIf="loadingAlert" class="row justify-content-center">
      <div>
        <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
      </div>
    </div>
    <span *ngIf="loadingStatuses"
      ><i class="fa fa-circle-o-notch fa-spin"></i
    ></span>

    <form [formGroup]="alertForm" novalidate>
      <div *ngIf="!is_bridgehead">
        <div [hidden]="!editMode" class="form-group">
          <label for="status" i18n
            >Status
            <span *ngIf="loadingStatuses"
              ><i class="fa fa-circle-o-notch fa-spin"></i></span
          ></label>
          <angular2-multiselect
            id="status"
            [data]="dropdownListStatus"
            [(ngModel)]="selectedStatus"
            [settings]="dropdownSettingsSingle"
            formControlName="statusTypes"
          >
          </angular2-multiselect>
        </div>

        <div class="form-group">
          <label for="units" i18n>Units</label>
          <angular2-multiselect
            id="units"
            [data]="dropdownListUnits"
            [(ngModel)]="selectedUnits"
            [settings]="dropdownSettings"
            formControlName="units"
            (onSelect)="unitAreaChanged($event)"
            (onDeSelect)="unitAreaChanged($event)"
          >
          </angular2-multiselect>
        </div>

        <div class="form-group">
          <label for="areas" i18n>Areas</label>
          <angular2-multiselect
            id="areas"
            [data]="dropdownListAreas"
            [(ngModel)]="selectedAreas"
            [settings]="dropdownSettings"
            formControlName="areas"
            (onSelect)="unitAreaChanged($event)"
            (onDeSelect)="unitAreaChanged($event)"
          >
          </angular2-multiselect>
        </div>

        <div class="form-group">
          <label for="items" i18n>Items</label>
          <angular2-multiselect
            id="items"
            [data]="dropdownListItems"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            formControlName="items"
          >
          </angular2-multiselect>
        </div>

        <div class="form-group">
          <label for="analysis" i18n
            >Analysis Types
            <span *ngIf="loadingAnalysis"
              ><i class="fa fa-circle-o-notch fa-spin"></i></span
          ></label>
          <angular2-multiselect
            id="analysis"
            [data]="dropdownListAnalysis"
            [(ngModel)]="selectedAnalysis"
            [settings]="dropdownSettings"
            formControlName="analysisTypes"
          >
          </angular2-multiselect>
        </div>

        <div class="form-group">
          <label i18n>Severity</label>
          <div class="ml-5">
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="10"
              />
              <span style="background-color: #FF0000">&nbsp;&nbsp;</span>
              Significant indications of damage requiring immediate action.
            </div>
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="20"
              />
              <span style="background-color: orange">&nbsp;&nbsp;</span> Plant
              operating with suspected or known fault with signs of slow
              degradation.
            </div>
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="30"
              />
              <span style="background-color: yellow">&nbsp;&nbsp;</span>
              Deviations have been noted. Close monitoring being applied.
            </div>
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="40"
              />
              <span style="background-color: #DBDBDB">&nbsp;&nbsp;</span>
              Communications / instrumentation issues.
            </div>
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="50"
              />
              <span style="background-color: #52F2FA">&nbsp;&nbsp;</span> Plant
              unavailable.
            </div>
            <div class="form-check" i18n>
              <input
                class="form-check-input"
                type="radio"
                id="severity"
                name="severity"
                formControlName="severity"
                value="60"
              />
              <span style="background-color: #92D050">&nbsp;&nbsp;</span>
              Satisfactory.
            </div>
          </div>
        </div>

        <!-- <div class="form-group">
            <label for="severity">Severity</label>
            <angular2-multiselect 
              id="severity"
              [data]="dropdownListSeverity" 
              [(ngModel)]="selectedSeverity" 
              [settings]="dropdownSettingsSingle" 
              formControlName="severity">
            </angular2-multiselect>
          </div> -->

        <hr />
      </div>
      <!-- Loading spinner -->
      <div *ngIf="loadingAlert; else normalAlert">
        <p class="text-center mt-5">
          <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
        </p>
      </div>

      <ng-template #normalAlert>
        <!-- <div class="form-group">
        <label for="name">Alert Name</label>
        <input type="text" id="name" formControlName="name" class="form-control">
      </div> -->
        <div class="form-group">
          <label for="name" i18n>Alert Name</label>
          <div class="input-group">
            <!-- <div class="input-group-prepend">
            <span class="input-group-text">{{siteId}}-{{selectedUnits[0]?.shortName}}</span>
          </div> -->
            <input
              type="text"
              id="name"
              formControlName="name"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="summary" i18n>Summary</label>
          <textarea
            type="text"
            id="summary"
            class="form-control"
            formControlName="summary"
            rows="5"
          >
          </textarea>
        </div>

        <div class="form-group">
          <label for="recommendation" i18n>Recommendation</label>
          <textarea
            type="text"
            id="recommendation"
            class="form-control"
            formControlName="recommendation"
            rows="5"
          >
          </textarea>
        </div>

        <div>
          <label i18n>Feedback</label>
          <p>{{ this.alert.feedback.newContent }}</p>
        </div>

        <button
          type="submit"
          class="btn btn-success"
          [disabled]="!alertForm.valid"
          (click)="onSubmit()"
          style="cursor: pointer;"
        >
          Save
        </button>

        <!-- <button 
        *ngIf="editMode && isAcmUser"
        type="submit" 
        class="btn btn-success" 
        [disabled]="!alertForm.valid" 
        (click)="onSubmit()" 
        style="cursor: pointer;">
        Save and Send Update</button> -->

        <button type="button" class="btn btn-danger" (click)="onCancel()" i18n>
          Cancel
        </button>
      </ng-template>
    </form>
  </div>
  <div class="col-sm-4">
    <app-open-alerts
      *ngIf="!is_bridgehead"
      [units]="selectedUnits"
      [areas]="selectedAreas"
    ></app-open-alerts>
  </div>
</div>
