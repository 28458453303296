<div class="row justify-content-center">
  <div class="col-sm-8">
    <h3 i18n>
      Added Series
    </h3>

    <app-series-detail *ngFor="let addedSignal of series" [series]="addedSignal"></app-series-detail>

     
    <button type="submit" class="btn btn-success" (click)="onSubmit()" i18n>Save</button>
    <button type="button" class="btn btn-danger" (click)="onCancel()" i18n>Cancel</button>

    <hr>

    <app-tag-search></app-tag-search>
  </div>
</div>
