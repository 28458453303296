import { ChartSeries } from './../../_models/series.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DashboardsService } from '../../_services/dashboards.service';

@Component({
  selector: 'app-chart-series-edit',
  templateUrl: './chart-series-edit.component.html',
  styleUrls: ['./chart-series-edit.component.css']
})
export class ChartSeriesEditComponent implements OnInit {

  private dashboardId = '';
  private chartId = '';
  series = new Array<{}>();

  constructor(
    private route: ActivatedRoute,
    private dashboardsService: DashboardsService,
    private router: Router) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.dashboardId = params['dashId'];
          this.chartId = params['chartId'];
          console.log("SeriesEditComponent dashId = " + this.dashboardId);          
        }
      );

    this.dashboardsService.chartChanged
      .subscribe(
        (data => {
          console.log("ChartSeriesEditComponent - chart changed")
          console.log(data.series);
          this.series = data.series;
        })
      )
    
    this.dashboardsService.getChart(this.chartId);
        
  }

  onSubmit(){
    console.log("submitted");
    this.dashboardsService.updateSeries(this.dashboardId, this.chartId, this.series);
    this.router.navigate(['../../../../'], {relativeTo: this.route});
  }
  onCancel(){
    console.log("cancelled");
    this.router.navigate(['../../../../'], {relativeTo: this.route});
  }
}
