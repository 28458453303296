import { InfoAlertService } from './../../_services/info-alert.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.css']
})
export class RequestAccountComponent implements OnInit {
  loading = false;
  sent = false;
  requestText = "Submit Request";
  model: any = {};

  constructor(
    private authenticationService: AuthenticationService,
    private infoAlertService: InfoAlertService
  ) { }

  ngOnInit() {
  }

  Submit() {
    console.log("Requesting new account creation")
    this.loading = true;
    this.authenticationService.requestAccount(this.model.email, this.model.name)
        .subscribe(
            data => {
              console.log("Account requested");
              console.log(data);
              this.loading = false;
              this.requestText = "Request sent. We'll be in touch!";
            },
            error => {
              console.log("Account request error");
              console.log(error);
              this.requestText = "Error sending request";              
              this.infoAlertService.error("Account request unsuccessful");
                this.loading = false;
            }
          );
          this.sent = true;
          
  }

}