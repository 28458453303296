import { AlertItemComponent } from './../alerts/alert-item/alert-item.component';
import { AlertItemShortComponent } from './../alerts/alert-item-short/alert-item-short.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoAlertService } from './../_services/info-alert.service';
import { InfoAlertComponent } from './../_directives/info-alert.component';
import { AgmCoreModule } from '@agm/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { TagSparklineComponent } from './tag-sparkline/tag-sparkline.component';
import { FilterSignalsPipe } from './../_pipes/filter-signals.pipe';
import { ChatIconPipe } from './../_pipes/chat-icon.pipe';
import { FilterAnyPipe } from './../_pipes/filter-any.pipe';
import { StatusTextPipe } from './../_pipes/status-text.pipe';
import { SeverityTextPipe } from './../_pipes/severity-text.pipe';
import { ToFloatPipe } from './../_pipes/to-float.pipe';
import { PspUserPipe } from './../_pipes/psp-user.pipe';
import { SortAlertsPipe } from './../_pipes/sort-alerts.pipe';
import { FilterAlertsPipe } from './../_pipes/filter-alerts.pipe';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart/chart.component';
import { TagSearchComponent } from './tag-search/tag-search.component';
import { SearchItemComponent } from './search-item/search-item.component';
import { ChartModule } from 'angular2-highcharts'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeAgoPipe } from '../_pipes/time-ago.pipe';
import {MyFilterPipe} from '../_pipes/actionableAlerts.pipe';

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    RouterModule,
    FormsModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxUNmp6cS_veGa_VsLT6UV9UeyKuK4vtM'
    })
  ],
  providers:[
    InfoAlertService,
  ],
  declarations: [

    //components
    InfoAlertComponent,
    ChartComponent, 
    TagSearchComponent, 
    SearchItemComponent, 
    TagSparklineComponent,
    // alerts
    AlertItemShortComponent,
    AlertItemComponent,

    //pipes
    FilterAlertsPipe,
    SortAlertsPipe,
    PspUserPipe,
    ToFloatPipe,
    TimeAgoPipe,
    SeverityTextPipe,
    StatusTextPipe,
    FilterAnyPipe,
    ChatIconPipe,
    FilterSignalsPipe,
    MyFilterPipe,
    // TimeAgoPipe,
    // TitledTimeAgoPipe,

  ],
  exports: [
    AngularMultiSelectModule,
    ChartModule,
    AgmCoreModule,
    ReactiveFormsModule,

    //components
    ChartComponent,
    TagSparklineComponent,
    InfoAlertComponent,
    TagSearchComponent,
    //  alerts
    AlertItemShortComponent,
    AlertItemComponent,

    //pipes
    FilterAlertsPipe,
    SortAlertsPipe,
    PspUserPipe,
    ToFloatPipe,
    TimeAgoPipe,
    SeverityTextPipe,
    StatusTextPipe,
    FilterAnyPipe,
    ChatIconPipe,
    FilterSignalsPipe,
    NgbModule,
    MyFilterPipe,
    // TimeAgoPipe,
    // TitledTimeAgoPipe,
  ]
})
export class SharedModule { }
