<nav class=" sticky-top pl-3 bg-secondary text-white">
  <p>{{ role }}</p>
</nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
