<div class="row justify-content-center mb-5">

  <div class="col-sm-8">
    
    <!-- Error alert area -->
    <app-info-alert></app-info-alert>
    
    <!-- Loading spinner -->
    <div *ngIf="loading; else normalAlert">
      <p class="text-center mt-5">
        <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
      </p>
    </div>

    <ng-template #normalAlert>

      <!-- Jumbotron Header -->
      <div class="jumbotron">
          <a i18n [routerLink]="['./..']">&larr; Back to Alerts</a>
        <h4 class="display-4">{{ alert.name }}</h4>
        <p i18n class="lead text-right">
          Created on 
          <span [innerHTML]="alert.utcCreated | date"></span>
          by
          <span [innerHTML]="alert.createdBy | pspUser"></span>
          <br> Updated on
          <span [innerHTML]="alert.utcModified | date"></span>
          by
          <span [innerHTML]="alert.modifiedBy | pspUser"></span>
        </p>
        <hr class="my-4">
        <ul class="list-inline">
          <li class="list-inline-item">
            <i i18n class="fa fa-industry" aria-hidden="true"></i> Units
            <b><span *ngFor="let unit of alert.units">{{ unit.name }} </span> </b> |
          </li>
          <li class="list-inline-item">
            <i i18n class="fa fa-cogs" aria-hidden="true"> </i> Areas 
            <b><span *ngFor="let area of alert.areas">{{ area.name }} </span></b> |
          </li>
          <li class="list-inline-item">
            <i i18n class="fa fa-cubes" aria-hidden="true"></i> Items 
            <b><span *ngFor="let item of alert.items">{{ item.name }} </span></b>
          </li>
        </ul>
        <div *ngIf ="!isBridgehead"class="mb-2">
          <button i18n type="button" class="btn btn-link m-0 p-0" (click)="editNotiNum()">Notification No. <b>{{alertNote('Notification Number')}}</b></button>
           | 
          <button i18n type="button" class="btn btn-link m-0 p-0" (click)="editMaintNum()">Maintenance No. <b>{{alertNote('Maintenance Order Number')}}</b></button>
        </div>

        <h4 i18n>
          Status: 
          <div *ngIf="!isAcm">
            <span class="badge badge-primary" *ngIf="alertStatus() == '3'; else normalBadge">{{ alert.status.description }}
            </span>
            <ng-template #normalBadge>
              <span class="badge badge-secondary">
                {{ alert.status.description }}
              </span>
            </ng-template>
          </div>

          <div ngbDropdown class="d-inline-block" *ngIf="openStatuses && isAcm">
            <button class="btn" [ngClass]="statusClass()" id="statusDropdown" ngbDropdownToggle>{{alert.status.description}}</button>
            <div ngbDropdownMenu aria-labelledby="statusDropdown">
              <button class="dropdown-item" *ngFor="let aStatus of openStatuses" id="aStatus" (click)="updateAlertStatus(aStatus)">{{aStatus.description}}</button>
            </div>
          </div>

          <button  *ngIf="isAcm && alert.status.id != 1" class="btn btn-outline-primary ml-1" (click)="closeAlert()">Close Alert</button>
        </h4>

        <div class="row">
          <div class="col">
              
            <div class="pull-right">
              <button i18n
                *ngIf="isAcm" 
                class="btn btn-danger my-1"
                (click)="deleteAlert()">
                Delete Alert
              </button>
              <button i18n 
                *ngIf="can_edit" 
                class="btn btn-primary my-1 ml-1"
                [routerLink]="['./edit']">
                Edit Alert
              </button>
              <button i18n
                *ngIf="can_edit && !alertIsPending()" 
                class={{SendEmailclassesObj}}
                (click)="onSendEmailUpdate($event.target)">
                <i class="fa fa-envelope-o"></i> Send Email Update
              </button>
              <button i18n
                *ngIf="isAcm && alertIsPending()" 
                class={{SendEmailclassesObj}}
                (click)="onApproveAlert($event.target)">
                <i class="fa fa-check-square-o"></i> Approve Alert
              </button>
              <button i18n
              *ngIf="requiresBridgeHead" [hidden] ="isBridgehead"
              class="btn btn-success my-1 ml-1"
              (click)="onSendForTranslation($event.target)">
              <i class="fa fa-check-square-o"></i> Send For Translation
            </button>
            </div>

          </div>
        </div>

      </div>


      <!-- Main information -->
      <div [ngClass]="severity()">
        <div class="alert" role="alert">
          <h4 i18n class="mb-0">Alert Summary</h4>
          <div *ngIf="alert.summary.newContent != null">
             <small class="text-muted mt-0">by {{ communicationModifiedBy('summary') }} (<span [innerHTML]="communicationModifiedOn('summary') | date"></span>)</small>
            <p style="white-space: pre-line;">{{ alert.summary.newContent }}</p>
          </div>  
         
        </div>
      </div>

      <div class="alert alert-info" role="alert">
        <h4 i18n class="alert-heading mb-0">Our Recommendation</h4>
        <div *ngIf="alert.recommendation.newContent != null">
          <small i18n class="text-muted mt-0">by {{ communicationModifiedBy('recommendation') }} (<span [innerHTML]="communicationModifiedOn('recommendation') | date"></span>)</small>
          <p style="white-space: pre-line;">{{ alert.recommendation.newContent }}</p>
        </div>
      </div>

      <div class="alert" role="alert">
        <h4 i18n class="alert-heading mb-0">Latest Feedback</h4>
        <div *ngIf="alert.feedback.newContent != null">
          <small class="text-muted mt-0">by {{ communicationModifiedBy('feedback') }} (<span [innerHTML]="communicationModifiedOn('feedback') | date"></span>)</small>        
          <p style="white-space: pre-line;">{{ alert.feedback.newContent }}</p>
        </div>
        <form  (ngSubmit)="onSubmitFeedback(feedbackForm,true)" #feedbackForm="ngForm">
          <div class="form-group">
            <div class="container" [ngSwitch]='isBridgehead'>
              <div *ngSwitchCase = "true">
                  <label i18n for="feedbackTextarea">Translate Latest Feedback</label>
                  <textarea 
                    class="form-control" 
                    id="feedbackTextarea" 
                    rows="3"
                    required
                    [(ngModel)]="feedback" name="name"></textarea>
              </div>
              <div *ngSwitchDefault>
                      <label i18n for="feedbackTextarea">Update Feedback</label>
                          <textarea 
                            class="form-control" 
                            id="feedbackTextarea" 
                            rows="3"
                            required
                            [(ngModel)]="feedback" name="name"></textarea>
              </div>
            </div>
      
          </div>
          <button i18n
            type="submit" 
            class="btn btn-primary"
            [disabled]="!feedbackForm.form.valid">
            Submit
          </button>
          <button i18n
            *ngIf="isAcm" 
            type="submit" 
            class="btn btn-outline-primary ml-1"
            [disabled]="!feedbackForm.form.valid" 
            (click)="onSubmitFeedback(feedbackForm,false)">
            Submit without emailing
          </button>
        </form>
      </div>

      <hr>

      <div>
        <button *ngIf ="!isBridgehead" i18n class="btn btn-primary mb-2" (click)="openUploader()">Add file</button>
        <ngb-accordion #accFiles="ngbAccordion">
        <ngb-panel i18n-title title='Show {{alertFiles.length}} files'>
          <ng-template ngbPanelContent>

            <h4 i18n >Documents</h4>
            <div *ngFor="let anAlertDocFile of alertDocs()">
              <p >
                <a href="{{anAlertDocFile.url}}">{{ anAlertDocFile.blobName }}</a> 
                <button  class="btn btn-warning btn-sm" (click)="unlinkFile(anAlertDocFile.hashId)" i18n>Remove</button><br>
                {{anAlertDocFile.description}}<br>
                <ng-container i18n>Uploaded on</ng-container>
                <span [innerHTML]="anAlertDocFile.utcUploaded | date"></span>
                <ng-container i18n>by</ng-container>>
                <span [innerHTML]="anAlertDocFile.uploadedBy | pspUser"></span>
              </p>
            </div>

            <hr>

            <h4 i18n>Images</h4>


            
                <div ngbSlide *ngFor="let anAlertImageFile of alertImages()">
                  <div>
                    <div>

                      <form>

                        <div class="form-check">
                          <input 
                            (change)="toggleFileSendWithUpdates(anAlertImageFile.hashId)" 
                            type="checkbox" 
                            [checked]="anAlertImageFile.sendWithUpdates">
                          <label i18n class="form-check-label" for="exampleCheck1">Include in emails</label>
                        </div>

                        <button i18n type="button" class="btn btn-outline-primary btn-sm" (click)="moveToTop(anAlertImageFile.hashId)">&uarr; Move to top<br></button>
                      </form>
                    
                     
                    </div>
                    <p>
                      <a i18n href="{{anAlertImageFile.url}}">{{ anAlertImageFile.blobName }}</a> <button class="btn btn-warning btn-sm" (click)="unlinkFile(anAlertImageFile.hashId)">Remove</button><br>
                      {{ anAlertImageFile.description }}<br>
                    Uploaded on 
                    <span [innerHTML]="anAlertImageFile.utcUploaded | date"></span>
                    by
                    <span [innerHTML]="anAlertImageFile.uploadedBy | pspUser"></span>
                  </p>
                  </div>
                  <img src="{{ anAlertImageFile.url }}" alt="{{ anAlertImageFile.description }}" class="img-fluid">
                  <hr>
                  <br>
                </div>

                <div *ngFor="let anAlertVidFile of alertVideos()">
                  <p>
                    <video 
                      controls="controls" 
                      width="800" height="600" 
                      name="Video Name" 
                      src="{{anAlertVidFile.url}}"></video>
                    <button i18n class="btn btn-warning btn-sm" (click)="unlinkFile(anAlertVidFile.hashId)">Remove</button><br>
                    {{anAlertVidFile.description}}<br>
                    Uploaded on
                    <span [innerHTML]="anAlertVidFile.utcUploaded | date"></span>
                    by
                    <span [innerHTML]="anAlertVidFile.uploadedBy | pspUser"></span>
                  </p>
                </div>

              <!-- </ng-template>   -->
                
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <hr>

      <ngb-accordion #accChart="ngbAccordion" activeIds="alertLiveChart" [destroyOnHide]="false">
        <ngb-panel id="alertLiveChart" i18n-title title='{{chartLoadingText()}}'>
          <ng-template ngbPanelContent>
            <chart
              [options]="chartOptions"
              (load)="saveInstance($event.context)">
            </chart>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <hr>

      <div *ngIf ="!isBridgehead">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel i18n-title title="Show alert recipients">
            <ng-template ngbPanelContent>
              <app-alert-recipients [siteId]="siteId" [alertId]="alertId"></app-alert-recipients>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <hr>

      <div *ngIf ="!isBridgehead">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel i18n-title title="Show history of {{ alertChangeLogCount() }} changes">
            <ng-template ngbPanelContent>
              <app-alert-history *ngFor="let histItem of alertHist()" [alertHistory]="histItem"></app-alert-history>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </ng-template>
  </div>
</div>
