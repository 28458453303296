import { ConnStrModule } from './conn-str.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class UserManagementService {

  permissionLevels = [];
  grantableCompanies = [];
  grantableSites = [];
  grantableRoles = [];


  grantableLevelsChanged = new Subject < {}[] > ();
  grantableCompaniesChanged = new Subject < {}[] > ();
  grantableSitesChanged = new Subject < {}[] > ();
  grantableRolesChanged = new Subject < string[] > ();
  otherUserPermissionsGot = new Subject <{}[] > ();
  grantedRolesChaged = new Subject < string[] > ();

  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule) {}

        
    getGrantablePermissionLevels() {
      console.log("getGrantablePermissionLevels()")
  
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/grantableLevels')
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Grantable levels got");
          console.log(data);
          this.permissionLevels = data;
          if (this.permissionLevels != null) {
            this.grantableLevelsChanged.next(this.permissionLevels);
          }
        })
    }

    getGrantableCompanies() {
      console.log("getGrantableCompanies()")
  
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/grantableCompanies')
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Grantable companies got");
          console.log(data);
          this.grantableCompanies = data;
          if (this.grantableCompanies != null) {
            this.grantableCompaniesChanged.next(this.grantableCompanies);
          }
        })
    }

    getGrantableSites() {
      console.log("getGrantableSites()")
  
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/grantableSites')
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Grantable sites got");
          console.log(data);
          this.grantableSites = data;
          if (this.grantableSites != null) {
            this.grantableSitesChanged.next(this.grantableSites);
          }
        })
    }

    getGrantableRoles(){
      console.log("getGrantableRoles()")

      return this.httpClient.get<string[]>(this.connStr.pspBaseUrl + 'users/allRoles')
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Roles got");
          console.log(data);
          if (data != null) {
            this.grantableRolesChanged.next(data);
          }
        }
      )      
    }

    getUserPermissions(userId: string) {
      console.log("getUserPermissions()")
  
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/' + userId + '/permissions')
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("User Permissions got");
          console.log(data);
          if (data != null) {
            this.otherUserPermissionsGot.next(data);
          }
        }
      )
    }

    getSetUserPermissions(userId: string, level: string, forItem: string) {
      console.log("getSetUserPermissions()")

      // Initialize Params Object
      let Params = new HttpParams();  
      // Begin assigning parameters
      Params = Params.append('level', level);
      Params = Params.append('for', forItem);
      return this.httpClient.get<string[]>(this.connStr.pspBaseUrl + 'users/' + userId + '/checkRoles',{ params: Params })
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Set User Permissions got");
          console.log(data);
          if (data != null) {
            this.grantedRolesChaged.next(data);
          }
        }
      )
    }

    setUserPermissions(userId: string, level: string, forItem: string, permissions: string[]){
      console.log("addUserPermission()")

      // Initialize Params Object
      let Params = new HttpParams();  
      // Begin assigning parameters
      Params = Params.append('level', level);
      Params = Params.append('for', forItem);
      return this.httpClient.put<{}[]>(this.connStr.pspBaseUrl + 'users/' + userId + '/setPermissions', permissions, { params: Params })
        .map((response) => {
          return response;
        })
        .subscribe(data => {
          console.log("Updated User Permissions");
          console.log(data);
          if (data != null) {
            this.otherUserPermissionsGot.next(data);
          }
        }
      )
    }


    getUserDetails(userId: string) {
      return this.httpClient.get<{}>(this.connStr.pspBaseUrl + 'users/details/' + userId)
    }
    updateUser(userId: string, user) {
      return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'users/details/' + userId, user)
    }
    sendWelcomeEmail(username: string) {
      let userList = {
        usernames: [username]
    }
      return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'users/SendWelcomeEmail/', userList)
    }


    getAcmPreferences() {
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/AcmPreferences')
      .map((response) => {
        return response;
      })
    }


    getSiteAcmPreferences(siteId: string) {
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/AcmPreferences/' + siteId)
      .map((response) => {
        return response;
      })
    }

    updateSiteAcmPreferences(userId: string, prefModel:{}) {
      return this.httpClient.put(this.connStr.pspBaseUrl + 'users/AcmPreferences/' + userId, prefModel,{
        responseType: 'text',
      })
      .map((response) => {
        return response;
      })
    }

    enableAllAcmPreferences() {
      return this.httpClient.post(this.connStr.pspBaseUrl + 'users/AcmPreferences/EnableAll', null,{
        responseType: 'text',
      })
      .map((response) => {
        return response;
      })
    }
    
    disableAllAcmPreferences() {
      return this.httpClient.post(this.connStr.pspBaseUrl + 'users/AcmPreferences/DisableAll', null,{
        responseType: 'text',
      })
      .map((response) => {
        return response;
      })
    }


    createNewUser(user){
      return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'user/AddNewUser', user);
    }

    updateUserDetails(userId: string, userDetails: {}) {
      return this.httpClient.put(this.connStr.pspBaseUrl + 'users/' + userId, userDetails,{
        responseType: 'text',
      })
      .map((response) => {
        return response;
      })
    }

    getAllUsers(){
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/AllUsers');
    }
    searchAllUsers(search){
      const qParams = new HttpParams({
        fromObject: {
          'searchStr': search,
        }
      });
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/AllUsers/search', {params: qParams});
    }
    getAdmins(){
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/Admins');
    }
    removeAdmin(adminIntId){
      return this.httpClient.delete<{}>(this.connStr.pspBaseUrl + 'users/Admins/' + adminIntId);
    }
    addAdmins(admins){
      return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'users/Admins', admins);
    }


    
    addUsers(userIds, level: string, thing: string, role: string){
      const qParams = new HttpParams({
        fromObject: {
          'accessLevel': level,
          'accessFor': thing,
          'accessRole': role
        }
      });
      return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'users/permissions', userIds, {params: qParams});
    }

    removeUser(userIntId, level: string, thing: string){
      const qParams = new HttpParams({
        fromObject: {
          'accessLevel': level,
          'accessFor': thing,
        }
      });
      return this.httpClient.delete<{}>(this.connStr.pspBaseUrl + 'users/permissions/' + userIntId, {params: qParams});
    }


    getGlobalUsers(){
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/permissions/global');
    }
    
    getUsers(level: string, thing: string){
      const qParams = new HttpParams({
        fromObject: {
          'level': level,
          'thing': thing
        }
      });
      return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'users/permissions', {params: qParams});
    }

}


