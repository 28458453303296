<div class="jumbotron jumbotron-fluid">
  <div class="container">

    <h1 class="display-3">
      <i class="fa fa-cogs fa-rotate-90"></i> MSR
    </h1>    
    <p class="lead">Maintenance Strategy Review</p>

    <hr class="my-4">

    <p>Review the status of your plant items.</p>
    
    <h5>Login to MSR</h5>
    <!-- <button [disabled]="loggingIn" (click)="msrLogin()" type="button" class="btn btn-primary"><i class="fa fa-sign-in"></i> with Predictive Maintenance Hub account</button> -->
    <a href='https://acm-login-app.azurewebsites.net/login?client_id=mso&grant_type=authorization_code' class="btn btn-primary"><i class="fa fa-sign-in"></i> with Predictive Maintenance Hub account</a>

  </div>
</div>