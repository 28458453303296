<a [routerLink]="['./..']" i18n> &larr; Back to Sites</a> <br />

<a [routerLink]="['./../archived', siteId]" i18n>
  <i class="fa fa-history" aria-hidden="true"></i> Search Alert Archives</a
>

<!-- Jumbotron Header -->

<div class="row justify-content-center">
  <!-- Left Column -->
  <div class="col-lg-3">
    <h4 class="display-4">{{ siteName }}</h4>
    <h5>
      <!-- <strong id="live">Live: </strong> -->
      <i
        class="fa fa-thermometer-half"
        aria-hidden="true"
        (click)="showMoon = true"
      ></i>
      {{ temperature }} &nbsp; | &nbsp;
      <i class="fa fa-flag-o" aria-hidden="true"></i> {{ wind }}
      <app-moon-phase
        *ngIf="siteId.toLowerCase() == 'sur' || showMoon"
        [lat]="lat"
        [long]="lng"
      ></app-moon-phase>
    </h5>
    <hr />
    <div *ngIf="sparkLines != null && sparkLines.length > 0" class="mb-2">
      <h5 i18n><i class="fa fa-lightbulb-o"></i> 24 hours of generation</h5>
      <app-tag-sparkline
        *ngFor="let spark of sparkLines"
        [piServer]="spark.siteId"
        [tagName]="spark.piLoadTag"
        [sparkname]="spark.description"
      ></app-tag-sparkline>
      <hr />
    </div>
  </div>

  <div class="col-lg-6">
    <div class="row">
      <div class="col-sm-6">
        <chart
          id="severityPie"
          [options]="optionsSeverity"
          (load)="saveInstanceSeverity($event.context)"
          style="cursor: pointer;"
        >
        </chart>
      </div>

      <div class="col-sm-6 text-center">
        <div class="container" [ngSwitch]="feedbackReqStr">
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('requiresFeedback')"
            *ngSwitchCase="'Loading...'"
          >
            <span class="badge badge-primary" i18n>Loading...</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('requiresFeedback')"
            *ngSwitchCase="'No open alerts'"
          >
            <span class="badge badge-primary" i18n>No open alerts</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('requiresFeedback')"
            *ngSwitchDefault
          >
            <span class="badge badge-primary" i18n
              >{{ feedbackReqStr }} Require Feedback</span
            >
          </h4>
        </div>
        <div class="container" [ngSwitch]="feedbackGivenStr">
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('feedbackReceived')"
            *ngSwitchCase="'Loading...'"
          >
            <span class="badge badge-success" i18n>Loading...</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('feedbackReceived')"
            *ngSwitchCase="'No open alerts'"
          >
            <span class="badge badge-success" i18n>No open alerts</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('feedbackReceived')"
            *ngSwitchDefault
          >
            <span class="badge badge-success" i18n
              >{{ feedbackGivenStr }} Feedback Received</span
            >
          </h4>
        </div>
        <div class="container" [ngSwitch]="acknowledgedStr">
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('acknowledgedAlerts')"
            *ngSwitchCase="'Loading...'"
          >
            <span class="badge badge-secondary" i18n>Loading...</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('acknowledgedAlerts')"
            *ngSwitchCase="'No open alerts'"
          >
            <span class="badge badge-secondary" i18n>No open alerts</span>
          </h4>
          <h4
            class="mt-4"
            style="cursor: pointer;"
            (click)="addFilterOption('acknowledgedAlerts')"
            *ngSwitchDefault
          >
            <span class="badge badge-secondary" i18n
              >{{ acknowledgedStr }} Acknowledged</span
            >
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="row">
      <div class="col">
        <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [styles]="styles"
        >
          <agm-marker
            *ngIf="lat != 0"
            [latitude]="lat"
            [longitude]="lng"
          ></agm-marker>
        </agm-map>
        <small
          *ngIf="isAcmUser"
          (click)="setLongLatZoom()"
          class="pull-right text-muted"
          ><i class="fa fa-map-marker" aria-hidden="true"></i> Edit
          location</small
        >
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-lg-6">
    <div
      class="btn-group"
      ngbRadioGroup
      name="radioBasic"
      [(ngModel)]="alertmode"
      (ngModelChange)="alertModeChanged()"
    >
      <label ngbButtonLabel class="btn-primary" i18n>
        <input ngbButton type="radio" value="allalerts" />All alerts
        <i class="fa fa-users" aria-hidden="true"></i>
      </label>
      <label ngbButtonLabel class="btn-primary" i18n>
        <input ngbButton type="radio" value="myalerts" /><i
          class="fa fa-user"
          aria-hidden="true"
        ></i>
        My alerts
      </label>
    </div>

    <a
      *ngIf="isAcmUser"
      class="btn btn-success mb-2 pull-right"
      [routerLink]="['./new']"
      role="button"
      i18n
    >
      <i class="fa fa-plus-square" aria-hidden="true"></i> New Alert
    </a>

    <div
      class="btn btn-success pull-right mr-1"
      (click)="exportAlertsToCSV(alerts)"
      role="button"
      i18n
    >
      <i class="fa fa-download" style="color:white" aria-hidden="true"></i>
      Export Alerts
    </div>

    <form>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filterStr"
          name="filterStr"
          placeholder="{{ alertPlaceholder() }}"
        />
      </div>
    </form>
  </div>
</div>

<div *ngIf="loading; else alertsList" class="row justify-content-center">
  <div>
    <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
  </div>
</div>

<div class="mb-5">
  <ng-template #alertsList>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6 text-center">
            <form [formGroup]="alertForm" novalidate>
              <div class="form-group">
                <angular2-multiselect
                  id="status"
                  [data]="dropdownListStatus"
                  [(ngModel)]="selectedStatus"
                  [settings]="dropdownSettings"
                  (onSelect)="onStatusSelectionChange()"
                  (onDeSelect)="onStatusSelectionChange()"
                  formControlName="status"
                >
                </angular2-multiselect>
              </div>
            </form>
          </div>

          <div class="col-lg-6 text-center">
            <div
              class="btn-group pull-right"
              ngbRadioGroup
              name="radioBasic"
              [(ngModel)]="displayMode"
            >
              <label ngbButtonLabel class="btn-primary" i18n>
                <input ngbButton type="radio" value="detailed" />Detailed View
                <i class="fa fa-expand" aria-hidden="true"></i>
              </label>
              <label ngbButtonLabel class="btn-primary" i18n>
                <input ngbButton type="radio" value="short" />Short View<i
                  class="fa fa-compress"
                  aria-hidden="true"
                ></i>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div id="selectedAlerts" [(ngModel)]="alerts" class="col-lg-6">
        <div *ngIf="displayMode == 'detailed'" class="list-group">
          <app-alert-item
            *ngFor="
              let alertElement of alerts
                | filterAlerts: filterStr:selectedStatus
                | sortAlerts: 'utcModified'
                | myfilter: filterArgs
            "
            [alert]="alertElement"
          ></app-alert-item>
        </div>

        <div *ngIf="displayMode == 'short'" class="list-group">
          <app-alert-item-short
            *ngFor="
              let alertElement of alerts
                | filterAlerts: filterStr:selectedStatus
                | sortAlerts: 'utcModified'
                | myfilter: filterArgs
            "
            [alert]="alertElement"
          ></app-alert-item-short>
        </div>
      </div>
    </div>
  </ng-template>
</div>
