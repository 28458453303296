<div class="list-group-item flex-column">

  <!-- <span class="btn-group"> -->
    <p class="mb-0">
      <span (click)="expanded = !expanded">
        <i *ngIf="expanded" class="fa fa-folder-open-o"></i> 
        <i *ngIf="!expanded" class="fa fa-folder-o"></i> 
         <b>{{name}}</b> 
         <span class="text-muted">({{folders.length}} <i class="fa fa-folder-o"></i> | {{items.length}} <i class="fa fa-area-chart"></i>)</span>
        </span>
    
    <span ngbDropdown>
      <button class="btn btn-sm btn-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-cog"></i></button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button class="dropdown-item" (click)="newFolder()" i18n>New folder</button>
        <button class="dropdown-item" (click)="editName()"i18n>Edit name</button>
        <button class="dropdown-item" (click)="deleteFolder()"i18n>Delete folder</button>
      </div>
    </span>
    <span ngbDropdown>
      <button class="btn btn-sm btn-link" id="dropdownBasic1" ngbDropdownToggle title="Move Dashboard here"><i class="fa fa-arrow-circle-o-right"></i> <i class="fa fa-area-chart"></i></button>
      <div ngbDropdownMenu>
        <button *ngFor="let aDash of dashboardList()" class="dropdown-item" (click)="moveDashboard(aDash.dashboardId)">{{aDash.name}}</button>
      </div>
    </span>
    </p>
  <!-- </span> -->

  <div *ngIf="expanded"  class="list-group">
    <app-dashboard-item *ngFor="let item of items" [dashboard]="item">
    </app-dashboard-item>
  </div>

  <div *ngIf="expanded" class="list-group">
    <app-dash-folder *ngFor="let folder of folders" [folders]="folder.folders" [items]="folder.items" [name]="folder.name" [directory]="folderPath()">
    </app-dash-folder>
  </div>


</div>