import { Subscribable } from 'rxjs/Observable';
import { DashboardsService } from './../../_services/dashboards.service';
import { Signal } from './../../_models/signal.model';
import { Component, OnInit} from '@angular/core';
import { Dashboard } from '../../_models/dashboard.model';
import { setTimeout } from 'timers';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.css']
})
export class TagSearchComponent implements OnInit {

  constructor(
    private dashboardsService: DashboardsService
  ) { }

  sourcesListSubscription: Subscription = new Subscription;

  sourceSelected = false;
  sourcesListLoading = true;
  signalListLoading = false;

  sourcesList = [];
  sourceSignals = [];
  filterStr = "";
  myList = 0;
  selectedSourceType = '';
  selectedSourceId = '';

  ngOnInit() {
    this.dashboardsService.signalListChanged
      .subscribe(
        (data) =>{
          console.log("signalListChanged detected");
          this.sourceSignals = data;
          this.sourcesListLoading = false;
          this.signalListLoading = false;
        }
      )
      
      this.sourcesList = [];
      this.sourcesList.push({
        'id': 'RNDSample',
        'sourceType': 'RND',
        'sourceId': 'Sample',
        'siteid': 'Sample',
        'name': 'Sample'
      })
      this.sourcesListSubscription = this.dashboardsService.loadSources()
        .subscribe(
          (data) => {
            console.log(data)
            data.forEach(sourceElement => {
              sourceElement['sourceType'] = "PI"
              sourceElement['id'] = sourceElement['sourceType'] + sourceElement['siteid']
              sourceElement['name'] = sourceElement['name'].toUpperCase();
              this.sourcesList.push(sourceElement);
            });
            console.log(this.sourcesList)
          },
          (error) => {
            // this.sourcesList = ["Unable to load"];            
          }
        )
  }

  ngOnDestroy() {
    this.sourcesListSubscription.unsubscribe();
  }

  signalSelected(event){
    console.log("signal selected");
    console.log(event)
    this.dashboardsService.addSignalClick(this.selectedSourceType, this.selectedSourceId, event['dataId'], event['name']);
  }

  loadSourceSignals(newSourceId: string){
    console.log(newSourceId);
    this.sourceSignals = [];
    this.sourceSelected = true;
    this.signalListLoading = true;
    console.log("Source selected: ");
    console.log(newSourceId);
    let newSource = this.sourcesList.find(x => x.id == newSourceId);
    this.selectedSourceType = newSource['sourceType'];
    this.selectedSourceId = newSource['siteid'];
    console.log("selectedSourceType = " + this.selectedSourceType + " selectedSourceId = " + this.selectedSourceId) 
    this.dashboardsService.loadSignalList(this.selectedSourceType, this.selectedSourceId);
    
  }


}
