import { ActivatedRoute, Params } from '@angular/router';
import { UserManagementService } from './../../_services/user-management.service';
import { concat } from 'rxjs/observable/concat';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subscribable } from 'rxjs/Observable';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit {

  dropdownListGlobal = [];
  selectedGlobal = [];

  dropdownListCompany = [];
  selectedCompany = [];

  dropdownListSites = [];
  selectedSites = [];

  grantableRoles = [];
  selectedRoles = [];

  dropdownSettings = {};

  loggedInUserAccessTree = "";
  otherUserAccessTree: {}[];

  otherUserId: string;

  readyForRole: boolean;
  isGlobal: boolean;  
  isCompany: boolean;
  isSite: boolean;

  selectedLevel: string;
  selectedThing: string;

  levelChangeSub: Subscription;
  companiesChangeSub: Subscription;
  sitesChangeSub: Subscription;
  userPermissionsChanged: Subscription;
  rolesChanged: Subscription;
  grantedRolesChanged: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.readyForRole = false;
    this.isGlobal = false;
    this.isCompany = false;
    this.isSite = false;

    this.loggedInUserAccessTree = this.authenticationService.getUserAccess();

    console.log("params");

    this.route.params
    .subscribe(
      (params: Params) => {
        this.otherUserId = params['userId'];
      }
    );

    // this.route.queryParams
    // .subscribe(
    //   (params: Params) => {
    //     if (params['email']){
    //       this.otherUserEmail = params['email'];
    //       console.log(this.otherUserEmail);
    //     }
    //   }
    // );

    this.rolesChanged = this.userManagementService.grantableRolesChanged
      .subscribe(
        (roles: string[]) => {
          this.grantableRoles = [];
          roles.forEach(roleElement => {
            this.grantableRoles.push(
              {
                "id": roleElement['id'],
                "itemName": roleElement['itemName']
              }
            )
          });
        }
      )

      this.grantedRolesChanged = this.userManagementService.grantedRolesChaged
        .subscribe(
          (roles: string[]) => {
            this.selectedRoles = [];
            roles.forEach(roleElement => {
              this.selectedRoles.push(
                {
                  "id": roleElement,
                  "itemName": roleElement
                }
              )
            });
          }
        )

    this.levelChangeSub = this.userManagementService.grantableLevelsChanged
      .subscribe(
        (levels: {}[]) => {
          this.dropdownListGlobal = [];
          levels.forEach(levelElement => {
            this.dropdownListGlobal.push(
              {
                "id": levelElement['id'],
                "name": levelElement['itemName']
              }
            )
          });
        }
      );

      this.companiesChangeSub = this.userManagementService.grantableCompaniesChanged
      .subscribe(
        (companies: {}[]) => {
          this.dropdownListCompany = [];
          companies.forEach(companyElement => {
            this.dropdownListCompany.push(
              {
                "id": companyElement['id'],
                "name": companyElement['itemName']
              }
            )
          });
        }
      );

      this.userPermissionsChanged = this.userManagementService.otherUserPermissionsGot
        .subscribe(
          (permissions: {}[]) => { 
            this.otherUserAccessTree = permissions;
          }
        )

      this.sitesChangeSub = this.userManagementService.grantableSitesChanged
      .subscribe(
        (sites: {}[]) => {
          this.dropdownListSites = [];
          sites.forEach(siteElement => {
            this.dropdownListSites.push(
              {
                "id": siteElement['id'],
                "name": siteElement['itemName']
              }
            )
          });
        }
      );

    this.userManagementService.getGrantablePermissionLevels();
    this.userManagementService.getUserPermissions(this.otherUserId);

    console.log(this.loggedInUserAccessTree);
    
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Fields",
      enableCheckAll: false,
      enableSearchFilter: false,
      groupBy: "category"
    };

    
  }

  loadCompanyOrSite(level: string){
    this.selectedLevel = level;

    console.log(this.selectedLevel);
    this.dropdownListCompany = [];
    this.dropdownListSites = [];

    this.isCompany = false;
    this.isSite = false;
    this.isGlobal = false;
    this.readyForRole = false;
    

    if(this.selectedLevel == 'company'){
      console.log("selected company");
      this.isCompany = true;
      this.userManagementService.getGrantableCompanies();      
    }
    if(this.selectedLevel == 'site') {
      console.log("selected site");
      this.isSite = true;
      this.userManagementService.getGrantableSites();      
    } 
    if(this.selectedLevel == 'global'){
      this.isGlobal = true;
      this.readyForRole = true;
      console.log("selectedglobalsite");
      this.loadUserRoles('global','');   
    }
  }

  otherUserAccess(){
    // return JSON.stringify(this.otherUserAccessTree)
    return JSON.stringify(this.otherUserAccessTree);
  }

  loadUserRoles(type: string, thing: string){
    this.selectedLevel = type;
    this.selectedThing = thing;
    
    this.readyForRole = true;
    this.grantableRoles = [];
    this.userManagementService.getGrantableRoles();
    this.userManagementService.getSetUserPermissions(this.otherUserId, type, thing);
  }

  onSubmit() {
    const userPermissionsList = [];
    this.selectedRoles.forEach(roleElement => {
      userPermissionsList.push(roleElement['itemName']);
    });
    this.userManagementService.setUserPermissions(this.otherUserId, this.selectedLevel, this.selectedThing, userPermissionsList);
    
  }


}
