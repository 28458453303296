<!-- <form>
  <div class="form-row align-items-center">
    <div class="col-auto">
      <label class="sr-only" for="inlineFormInput">Source</label>
      <input type="text" class="form-control mb-2 mb-sm-0" id="inlineFormInput">
    </div>
    <div class="col-auto">
      <div class="input-group mb-2 mb-sm-0">
        <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Series name">
      </div>
    </div>
    <div class="col-auto">
      <button class="btn btn-primary">Save</button>
      <button *ngIf="changed" class="btn btn-danger">Remove</button>
    </div>
  </div>
</form> -->

<p>
  <button class="btn btn-sm btn-danger" (click)="onRemoveClick()"><i class="fa fa-times" aria-hidden="true"></i></button> 
  {{series.name}} ( {{series.sourceType}} - {{series.idAtSource}} ) 
</p>

