<nav class="sticky-top pl-3 bg-secondary text-white">
  <p>System Admin</p>
</nav>

<div class="container-fluid">
  <div class="row">

    <div class="col-lg-2 col-md-3 col-sm-4">

      <nav class="nav nav-pills flex-column">
        <b><i class="fa fa-unlock-alt"></i> Admins</b>
        <nav class="nav nav-pills flex-column">
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Admins']">Manage Admins</a>
        </nav>
        <b><i class="fa fa-users"></i> Users</b>
        <nav class="nav nav-pills flex-column">
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Users']">All users</a>
          <a class="nav-link ml-3 my-0 p-1"  [routerLink]="['AddUser']">Add new</a>
          <a class="nav-link ml-3 my-0 p-1"  [routerLink]="['UserAccess']">Access rights</a>
        </nav>
        <!--
        <b><i class="fa fa-key"></i> User Access</b>
        <nav class="nav nav-pills flex-column">
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Global']">Global <i class="fa fa-exclamation-circle text-muted"></i></a>
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Companies']">Company & Site </a>
        </nav> -->
        <!-- <b><i class="fa fa-building-o"></i> Companies</b>
        <nav class="nav nav-pills flex-column">
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Companies']">All Companies</a>
        </nav>
        <b><i class="fa fa-industry"></i> Sites</b>
        <nav class="nav nav-pills flex-column">
          <a class="nav-link ml-3 my-0 p-1" [routerLink]="['Sites']">All Sites</a>
        </nav> -->
      </nav>
      
    </div>

    <div class="col-lg-10 col-md-9 col-sm-8">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>