<nav class="navbar navbar-dark bg-dark">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleNav()" i18n>
        <i *ngIf="!_opened" class="fa fa-bars" aria-hidden="true"></i>
        <i *ngIf="_opened" class="fa fa-arrow-left" aria-hidden="true"></i>
        My Dashboards
      </a>
      <!-- <a  *ngIf="_opened" class="nav-link" (click)="toggleNav()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i> Show Dashboards
      </a> -->
    </li>
  </ul>
</nav>

<!-- nav -->
<div [hidden]="_opened">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5 col-xl-3">
        <app-dashboards-nav></app-dashboards-nav>
      </div>
      <div class="d-none d-lg-block col-md-7 col-xl-9">
        <app-dashboard-start></app-dashboard-start>
      </div>
    </div>
  </div>
</div>

<!-- Page content -->
<div [hidden]="!_opened">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
