import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule],
})
export class ConnStrModule {
  //
  // FOR ACM ACCOUNTS
  //
  //public acmAccountUrl = 'http://localhost:49374/api/';
  public acmAccountUrl = "https://acm-login-api.azurewebsites.net/api/";

  //
  // FOR THIS WEB APP
  //
  public smartHubBaseUrl = "https://app.prod.solutions/";
  public es_smartHubBaseUrl = "https://app-es.prod.solutions/";
  public de_smartHubBaseUrl = "https://app-de.prod.solutions/";

  //
  // FOR ALERT DATA
  //
  public pspBaseUrl = "https://acmpsp-api.azurewebsites.net/api/"; //Production API
  //public pspBaseUrl = "https://acmpsp-api-dev.azurewebsites.net/api/"; //Dev API
  //public pspBaseUrl = "http://localhost:61957/api/"; //Debug API

  //
  // FOR TIMESERIES DATA
  //
  //public universeDataUrl = 'https://universe-api-ext.azurewebsites.net/api/';
  public universeDataUrl = "https://acmuniversedata-api.azurewebsites.net/api/";

  //
  // FOR MSR DATA
  //
  //public universeDataUrl = 'https://universe-api-ext.azurewebsites.net/api/';
  public msrDataUrl = "https://acmuniverse-msr-api.azurewebsites.net/api/";

  //clientIds for enerlytics redirects
  public es_clientId: string = "TeG74HzlMMPHqGkzCJDRMQQOrDOi6PQY";
  public de_clientId: string = "4fv4W4QIcgBp99IBkLeHQIy99xcN0kAj";
  public default_clientId: string = "b0e14b0075374dbf997cb9ff9ab53200";

  //enerlytics Base URL

  public enerlyticsBaseUrl: string = "https://enerlytics.uniper.engineering";

  //TO-DO make sure stattion code is correct, this info should be gotten from API eventually
  public enerlyticsSiteIds = [
    {
      enerlyticsSiteId: "d85b37ee-b112-4073-a6a3-35be210c61eb",
      enerlyticsDisplayName: "Staudinger",
      pmh1StattionCode: "STA",
    },
    {
      enerlyticsSiteId: "9e449fd2-b989-4a6b-be34-af9a931bd45e",
      enerlyticsDisplayName: "Coal Test",
      pmh1StattionCode: "COAL1",
    },
    {
      enerlyticsSiteId: "3eafc35b-1f7b-440f-9064-070692ed64c0",
      enerlyticsDisplayName: "Yallourn",
      pmh1StattionCode: "YALL",
    },
    {
      enerlyticsSiteId: "741cb2ba-e860-4b84-a588-3f9ea2ca9f78",
      enerlyticsDisplayName: "Gonyu",
      pmh1StattionCode: "GO",
    },
    {
      enerlyticsSiteId: "ec9677a7-c3bd-47f8-b90f-1ccb7ebc6192",
      enerlyticsDisplayName: "Maasvlakte",
      pmh1StattionCode: "MV",
    },
    {
      enerlyticsSiteId: "66f63a4a-8981-476f-b121-7241b3bd40ca",
      enerlyticsDisplayName: "Fenix",
      pmh1StattionCode: "FNX",
    },
    {
      enerlyticsSiteId: "10b5a01c-030c-447b-a08b-190cb475482b",
      enerlyticsDisplayName: "Connah's Quay",
      pmh1StattionCode: "CQ",
    },
    {
      enerlyticsSiteId: "aacc35a8-3fca-4ea2-b7d7-dabb68b30576",
      enerlyticsDisplayName: "CDC",
      pmh1StattionCode: "CDC",
    },
    {
      enerlyticsSiteId: "6cdebd7c-c260-4b0d-a4f5-c6ad90df5ce7",
      enerlyticsDisplayName: "Enfield",
      pmh1StattionCode: "EF",
    },
    {
      enerlyticsSiteId: "e8cdc9f7-8e79-4b80-8536-e319c471cd31",
      enerlyticsDisplayName: "Grain",
      pmh1StattionCode: "GRCHP",
    },
    {
      enerlyticsSiteId: "529e22ec-d1ed-43c4-b475-5b7a5a456452",
      enerlyticsDisplayName: "Competitor Report",
      pmh1StattionCode: "",
    },
    {
      enerlyticsSiteId: "19fd6cac-a77f-486c-a52d-1e06f093ce2d",
      enerlyticsDisplayName: "Gas Demo",
      pmh1StattionCode: "CCGT1",
    },
    {
      enerlyticsSiteId: "c31e6665-d30c-48ba-986d-cd0ebea71a4d",
      enerlyticsDisplayName: "Coal Test 2",
      pmh1StattionCode: "COAL1",
    },
    {
      enerlyticsSiteId: "9e4f4dc8-f11f-4966-8fd9-4934703db98f",
      enerlyticsDisplayName: "Nehuenco",
      pmh1StattionCode: "NEH",
    },
    {
      enerlyticsSiteId: "8e0e6c3f-cf4d-4c16-942e-ca13b07ef6b4",
      enerlyticsDisplayName: "Ratcliffe",
      pmh1StattionCode: "RATS",
    },
    {
      enerlyticsSiteId: "e4c2bd39-ef9e-401a-b6d3-468582694107",
      enerlyticsDisplayName: "Kirchmoeser",
      pmh1StattionCode: "",
    },
    {
      enerlyticsSiteId: "38e8ad59-8fbb-487e-9e57-4b714bc5dc39",
      enerlyticsDisplayName: "Coal Demo Baseload",
      pmh1StattionCode: "",
    },
    {
      enerlyticsSiteId: "919e4b03-14ad-45ff-87f3-f3ce051c0b2c",
      enerlyticsDisplayName: "Renewables - Wind",
      pmh1StattionCode: "WIND1",
    },
    {
      enerlyticsSiteId: "23aeae02-e9c6-4224-a616-ab26cad3ac07",
      enerlyticsDisplayName: "Gas Test",
      pmh1StattionCode: "",
    },
    {
      enerlyticsSiteId: "c4c5d348-1966-4100-bfd0-d24947e0ee80",
      enerlyticsDisplayName: "IoT",
      pmh1StattionCode: "",
    },
    {
      enerlyticsSiteId: "e1eafa34-83e2-43eb-abc2-57fbf43b2e47",
      enerlyticsDisplayName: "Killingholme",
      pmh1StattionCode: "KH",
    },
    {
      enerlyticsSiteId: "a6b398ae-8ef7-4427-8417-9801f8c87fb5",
      enerlyticsDisplayName: "Heyden",
      pmh1StattionCode: "HEY",
    },
  ];
}
