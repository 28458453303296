import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Params } from '@angular/router';
import { SitesService } from './../../_services/sites.service';
import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-msr-list',
  templateUrl: './msr-list.component.html',
  styleUrls: ['./msr-list.component.css']
})
export class MsrListComponent implements OnInit {

  siteId;
  site;
  plantItems = [];
  filterStr;

  loading = false;
  siteSubscription: Subscription = new Subscription;
  
// testing
temperature = "loading...";
wind = "loading...";

lat = 50;
lng = 10;
zoom = 3;


styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]


  constructor(
    private route: ActivatedRoute,
    private sitesService: SitesService
  ) { }

  ngOnInit() {

    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId'];
        this.sitesService.getSite(this.siteId);
      }
    );

    this.siteSubscription = this.sitesService.siteChanged
      .subscribe(
        (site) => {
          console.log("site data")
          console.log(site)
          this.site = site;
          
          this.lng = parseFloat(site['longitude'].toString());
          this.lat = parseFloat(site['latitude'].toString());
          this.zoom = parseInt(site['zoom'].toString());

          this.getWeatherData();
          this.getMsrItems();
          // this.getForecastData();
        }
      );
    
  }

  getMsrItems(){
    // testing
    for (var _i = 1; _i < 6; _i++) {
      this.plantItems.push(
        {
          'id': _i,
          'name':'Boiler Feed Water Pump ' + _i,
          'functionalLocation': "Demo-00-LAC-50-AP001-KP0" + _i,
          'strategy': "Condition Based"
        }
      )
    }
  }

  getWeatherData(){
    this.sitesService.getWeatherData(this.lng, this.lat)
      .subscribe(
        (data:HttpResponse<any>)=> {
          console.log("weather data");
          data = data.body.json();
          console.log(data);
          this.temperature = (parseFloat((data['main']['temp'])) - 273).toFixed(1) + ' ℃';
          this.wind = parseFloat((data['wind']['speed'])).toFixed(1) + ' m/s' ;
        },
        error => {
          this.temperature = "no data";
          this.wind = "no data";
        }
      )
  }
  

}
