export class Signal {
    public dataId:string;
    public name:string;
    public description:string;
    public measuredIn:string;

    constructor() {
        this.dataId = '';
        this.description = '';
        this.name = '';
        this.measuredIn = '';
    }

}