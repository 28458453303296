import { AuthenticationService } from './../../_services/authentication.service';
import { FormattingService } from './../../_services/formatting.service';
import { concat } from 'rxjs/observable/concat';
import { retry } from 'rxjs/operator/retry';
import { Subscription } from 'rxjs/Subscription';
import { ReportsService } from './../../_services/reports.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertsService } from '../../_services/alerts.service';
import { Subscribable } from 'rxjs/Observable';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit {

  displayMode = 'short';
  alertmode = 'allalerts';
  loading = true;
  reportId;
  siteId;
  report = {};
  filterStr = '';
  working = false;
  filter = false;

  approveText = "Approve";
  approveSendText= "Approve and Send"
  rejectText = "Reject";
  sendApproveText = "Send for Approval";
  sendText = "Send Report";

  feedbackReqStr = "Loading..."
  closedStr = "Loading..."
  acknowledgedStr = "Loading..."

  optionsSeverity: Object;
  chartSeverity;
  severityPieMade = false;

  optionsStatus: Object;
  chartStatus;
  statusPieMade = false;

  reportSubscription: Subscription = new Subscription();
  alertChangeSubscription: Subscription = new Subscription();

  constructor(
    private alertService: AlertsService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private router: Router,
    private formattingService: FormattingService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {

    this.optionsStatus = {
      chart: {
        type: 'pie',
        marginTop: 0,
        reflow: true,
      },
      title: {
          text: 'Alert<br>Statuses',
          align: 'center',
          verticalAlign: 'middle',
          y: -5,
          style: {
            fontSize: '1.5rem',
            color:'#495057'
          }
      },
      subtitle: {
          text: ''
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            // showInLegend: true
        }
      },
      // this replaces the series name with the number of points
      legend: {
        labelFormatter: function () {
          return this.y
        }
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 300,
                maxHeight: 300
            },
            chartOptions: {
                series: [{
                    id: 'versions',
                    dataLabels: {
                        enabled: false
                    }
                }]
            }
        }]
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: 'Alerts',
        innerSize: '70%',
        data: []
      }],
  };

    this.optionsSeverity = {
      chart: {
        type: 'pie',
        marginTop: 0,
        reflow: true,
      },
      title: {
          text: 'Alert<br>Severities',
          align: 'center',
          verticalAlign: 'middle',
          y: -5,
          style: {
            fontSize: '1.5rem',
            color:'#495057'
          }
      },
      subtitle: {
          text: ''
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            // showInLegend: true
        }
      },
      // this replaces the series name with the number of points
      legend: {
        labelFormatter: function () {
          return this.y
        }
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 300,
                maxHeight: 300
            },
            chartOptions: {
                series: [{
                    id: 'versions',
                    dataLabels: {
                        enabled: false
                    }
                }]
            }
        }]
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: 'Alerts',
        innerSize: '70%',
        data: []
      }],
  };



    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId =params['siteId'];
        this.reportId = params['reportId'];
        console.log("siteId() " + this.siteId);
        console.log("reportId() " + this.reportId);
        this.getReport();
      }
    );

    this.reportsService.reportChanged
      .subscribe(
        (data) => {
          this.getReport();
        }
      )

      this.reportsService.alertRemoved
        .subscribe(
          data => {
            var alertId = data['alertId'];
            console.log("removing alert from list with id " + alertId);

            var index = this.report['alerts'].findIndex(x => x['hashId'] == alertId)
            if (index > -1) {
              console.log("alert found, removing");              
              this.report['alerts'].splice(index, 1);
            }
          }
        )

  }

  ngOnDestroy(){
    this.reportSubscription.unsubscribe();
    this.alertChangeSubscription.unsubscribe();
  }

  makeSeverityPie(data: {}[]){
    console.log("making pie chart");
    console.log(data);
    let severityData = [];

    if (data == null || data.length == 0) {
      return;
    }

    for (let anAlert of data){
      
      let pointColour = this.formattingService.getSeverityColour(anAlert['severity']['id']);
      var thisSeverity = severityData.find( x => x.id == anAlert['severity']['id']);
      if (thisSeverity == null){
        // severity hasn't been added yet - add it
        severityData.push(
          {
            id: anAlert['severity']['id'],
            name: anAlert['severity']['description'],
            y: 0,
            color: pointColour,
          }
        );
    }
      // increment the severity
      thisSeverity = severityData.find( x => x.id == anAlert['severity']['id']);
      thisSeverity['y'] += 1;    
  }

  this.chartSeverity['series'][0].setData(severityData);
}

makeStatusPie(data: {}[]){

  if (data == null || data.length == 0) {
    console.log("aborting status pie build")
    return;
  }

  let statusData = [];

  // CLOSED
  statusData.push(
    {
      id: 1,
      name: 'Closed',
      y: 0,
      color: this.formattingService.getStatusColour(1),
    }
  );

  // Req Feedback
  statusData.push(
    {
      id: 3,
      name: 'Require Feedback',
      y: 0,
      color: this.formattingService.getStatusColour(3),
    }
  );

  // Acknowledged
  statusData.push(
    {
      id: 0,
      name: 'Acknowledged',
      y: 0,
      color: this.formattingService.getStatusColour(0),
    }
  );

  for (let anAlert of data){
    
    var thisStatus = anAlert['status']['id'];
    
    //closed
    if (thisStatus == 1){
      statusData.find(x => x.id == 1)['y'] += 1;
    }
    // req. feedback
    else if (thisStatus == 3){
      statusData.find(x => x.id == 3)['y'] += 1;
    }
    // all others
    else {
      statusData.find(x => x.id == 0)['y'] += 1;
    }
  }
  this.chartStatus['series'][0].setData(statusData);

}


  saveInstanceSeverity(chartInstance) {
    console.log("save instance severity")
    this.chartSeverity = chartInstance;
    console.log(this.chartSeverity)
    if (!this.severityPieMade && this.report['alerts'] != null){
      console.log("first pie build")
      this.severityPieMade = true;
      this.makeSeverityPie(this.report['alerts']);
    }
  }

  saveInstanceStatus(chartInstance) {
    console.log("save instance status")
    this.chartStatus = chartInstance;
    console.log(this.chartStatus)
    if (!this.statusPieMade && this.report['alerts'] != null){
      console.log("first status pie build")
      this.statusPieMade = true;
      this.makeStatusPie(this.report['alerts']);
    }
  }
  

  alertModeChanged(){
    if (this.alertmode == "allalerts"){
      this.filter = false;
    } else {
      this.filter = true;      
    }
    console.log("alert mode = " + this.alertmode)
    console.log("getReport() filter = " + this.filter)
    this.getReport();
  }

  getReport() {
    
    this.loading = true;
    this.reportSubscription = this.reportsService.loadReport(this.siteId, this.reportId, this.filter)
      .subscribe(
        (data) => {
          this.report = data;
          this.loading = false;
          // this.makeSeverityPie(this.report['alerts'])
          // this.makeStatusPie(this.report['alerts'])
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.router.navigate(['404']);
        }
      )
  }
  refreshAlerts() {
    this.getReport();
  }

  clearAllAlertsAndReload(){
    let confirmRemove = confirm("Are you sure?\nThis will add any removed alerts back in.");
    if (confirmRemove) {
      this.loading = true;
      this.reportsService.clearAndReGet(this.siteId, this.reportId)
        .subscribe(
          data => {
            this.getReport();
          },
          error => {
            this.loading = false;
            alert('Error re-getting alerts')
          }
        )
    }
  }

  onDelete(){
    let confirmDelete = confirm("Are you sure?");

    if (confirmDelete) {
      this.working = true;
      this.reportsService.deleteReport(this.siteId, this.reportId)
        .subscribe(
          data => {
            this.router.navigate(['../../'], {relativeTo: this.route});
          }, 
          error => {
            this.router.navigate(['../../'], {relativeTo: this.route});
          }
        )
    }
  }

  sendForApproval() {
    this.working = true;
    console.log("sendForApproval");
    this.sendApproveText = "Sending...";
    this.reportsService.sendForApproval(this.siteId, this.reportId)
    .subscribe(
      data => {
        this.working = false;
        console.log(data)
        this.sendApproveText = "Sent for approval";
        this.router.navigate(['../../'], {relativeTo: this.route});
      }, 
      error => {
        this.working = false;
        console.log(error);
        alert(error.error);
        this.sendApproveText = "Send for Approval";
      }
    )
  }

  onApprove(send: boolean){
    console.log("send? " + send)
    this.working = true;
    console.log("approve report");
    this.approveText = "Approving...";
    this.approveSendText = "Approving...";
    this.reportsService.approveReport(this.siteId, this.reportId)
      .subscribe(
        data => {
          this.working = false;
          console.log(data)
          this.approveText = "Approved";
          this.approveSendText = "Approved";
          if (send){
            this.onSend();
          } else {
            this.router.navigate(['../../'], {relativeTo: this.route});
          }
        }, 
        error => {
          this.working = false;
          console.log(error);
          alert(error.error);
          this.approveSendText = "Approve and Send";
          this.approveText = "Approve";   
        }
      )
  }
  onSend(){
    this.working = true;
    this.approveSendText = "Sending..."
    this.sendText = "Sending..."
    this.reportsService.sendReport(this.siteId, this.reportId)
      .subscribe(
        data => {
          this.working = false;
          console.log(data)
          this.approveSendText = "Sent"
          this.sendText = "Sent"
          this.router.navigate(['../../'], {relativeTo: this.route});
          
        }, 
        error => {
          this.working = false;
          console.log(error);
          alert(error.error);
          this.approveSendText = "Approve and Send";
          this.approveText = "Approve";
          this.sendText = "Send Report"
        }
      )
  }

  onReject(){
    this.working = true;
    console.log("reject report")
    this.rejectText = "Rejecting..."
    this.reportsService.rejectReport(this.siteId, this.reportId)
      .subscribe(
        data => {
          console.log(data)
          this.rejectText = "Rejected"
          this.router.navigate(['../../'], {relativeTo: this.route});
        }, 
        error => {
          console.log(error);
          alert(error.error);
          this.rejectText = "Reject"        
        }
      )
  }

  inEditMode(){
    if (this.report['status'].toLowerCase() == 'pending approval') {
      return false;
    }
    if (this.report['status'].toLowerCase() == 'sent') {
      return false;
    }
    if (this.report['status'].toLowerCase() == 'approved') {
      return false;
    }
    return true;
  }

  canSendForApproval(){
    if(this.report['approvedBy'] == null && this.report['status'].toLowerCase() != 'pending approval') {
      return true;
    }
    return false;
  }

  canDelete(){
    if(this.report['status'].toLowerCase() != 'sent') {
      return true;
    }
    return false;
  }

  canApprove(){
    if(this.report['status'].toLowerCase() == 'pending approval') {
      return true;
    }
    return false;
  }

  canSendReport(){
    if(this.report['status'].toLowerCase() == 'sent') {
      return true;
    }
    if(this.report['status'].toLowerCase() == 'approved') {
      return true;
    }
    return false;
  }

  isAcm(){
    if (this.authenticationService.isAcm('')){
      return true;
    }
    return false;
  }

}
