<!-- <a class="btn btn-outline-primary btn-sm" role="button"> -->
  <a class="btn btn-outline-primary btn-sm" [routerLink]="['./chart',myChart.chartId,'edit']" role="button" i18n>
  <i class="fa fa-pencil" aria-hidden="true"></i>
  <i class="fa fa-font"></i> 
  Edit Details
</a>
<!-- <a class="btn btn-outline-primary btn-sm" role="button"> -->
  <a class="btn btn-outline-primary btn-sm" [routerLink]="['./chart',myChart.chartId,'edit','series']" role="button" i18n>
  <i class="fa fa-pencil" aria-hidden="true"></i> 
  <i class="fa fa-line-chart" aria-hidden="true"></i> 
  Edit Series
</a>
<a class="btn btn-outline-primary btn-sm" (click)="toggleMultiscale()" role="button" i18n>
  Toggle Multiscale
</a>
<a class="btn btn-outline-danger btn-sm" (click)="deleteChart()" role="button" i18n><i class="fa fa-trash" aria-hidden="true"></i> Delete Chart</a>

  <chart
    [options]="options"
    (load)="saveInstance($event.context)">
  </chart>
  
<hr>
