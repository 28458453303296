import { ChartSeries } from './../../_models/series.model';
import { ConnStrModule } from './../../_services/conn-str.module';
import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-series-detail',
  templateUrl: './series-detail.component.html',
  styleUrls: ['./series-detail.component.css']
})
export class SeriesDetailComponent implements OnInit {

  @Input()
  series;
  
  changed = false;

  constructor( 
    private dashboardsService: DashboardsService 
  ) { }

  ngOnInit( ) {
    console.log("series detail init")
    console.log(this.series)
  }

  onRemoveClick(){
    console.log("remove click")
    this.dashboardsService.removeSeries(this.series['sourceType'],this.series['sourceId'],this.series['idAtSource']);
  }

}
