
<div>
  <form (ngSubmit)="onComment()" #commentForm="ngForm">

    <div class="form-group">
      <textarea i18n
        class="form-control" 
        id="commentArea" 
        rows="2" 
        required 
        [(ngModel)]="comment.newComment" 
        name="newComment" 
        #newComment="ngModel" 
        i18n-placeholder placeholder="Add a new comment">
      </textarea>
      <small id="commentAreaHelp" class="form-text text-muted" i18n>Comments are only visible to users the dashboard is shared with</small>
    </div>

    <div class="text-right mb-0">
      <button 
        type="button" 
        class="btn btn-outline-secondary" 
        (click)="onCommentCancel()" i18n>
        Cancel
      </button>

      <button 
        [disabled]="!commentForm.form.valid" 
        type="submit" 
        class="btn btn-primary">
        {{commentStr()}}
      </button>
      
    </div>

  </form>

</div>

<div class="mt-0">
  <app-comment *ngFor="let comment of comments" [comment]="comment"></app-comment>
</div>
  