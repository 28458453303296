import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';


@Injectable()



export class AuthMsrGuard implements CanActivate {
   
    constructor(private router: Router ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const jwtHelper = new JwtHelperService;
        if (localStorage.getItem('msrToken')) {

            if (jwtHelper.isTokenExpired( localStorage.getItem('msrToken'))) {
                           
                console.log("Token expired");     
            } else {
                return true; // logged in so return true
            }
               
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/MaintenanceStrategyReview']);
        let urlRedirect = state.url.substr(state.url.indexOf("/MSR/") + 5);
        this.router.navigate(['/MSR'], { queryParams: { returnUrl: urlRedirect }});
        return false;
    }
}