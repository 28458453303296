import { retry } from 'rxjs/operator/retry';
import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, RouterModule } from '@angular/router';
import { Dashboard } from '../../_models/dashboard.model';

@Component({
  selector: 'app-share-dashboard',
  templateUrl: './share-dashboard.component.html',
  styleUrls: ['./share-dashboard.component.css']
})
export class ShareDashboardComponent implements OnInit {

  dashId: string;
  dashboard: Dashboard;

  status = '';

  types = [
    {
      id:'view',
      name:'Can View'
    },
    {
      id:'edit',
      name:'Can Edit'
    },
  ]

  invite = {
    'email':'',
    'type':'view'
  }

  constructor(
    private dashboardsService: DashboardsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.route.params
      .subscribe(
        (params: Params) => {
          this.dashId = (params['dashId']);
        }
      )

      this.dashboardsService.dashboardChanged
      .subscribe(
        (dashboard: Dashboard) => {
          this.dashboard = dashboard;
          console.log(dashboard)
        }
      )

    this.route.params
      .subscribe(
        (params: Params) => {
          this.dashboardsService.getFullDashboard(params['dashId']);
        }
      )

  }

  activeUsers(){
    if (this.dashboard != null){
      if (this.dashboard['activeUsers'] != null){
        return this.dashboard['activeUsers']
      }
    }
    return [];
  }
  invitedUsers(){
    if (this.dashboard != null){
      if (this.dashboard['invitedUsers'] != null){
        return this.dashboard['invitedUsers']
      }
    }
    return [];
  }

  onInvite(){
    console.log(this.invite)

    this.status = 'sending...';

    this.dashboardsService.sendDashboardInvite(this.dashId, this.invite.email, this.invite.type)
      .subscribe(
        (data) =>{
          this.status = 'sent';
          console.log(data)
        },
        (error) => {
          console.log(error)
          this.status = 'not sent'
          if (error['error']){
            alert("Unable to send invite: " + error['error']);
          }
        }
      )

    this.invite = {
      'email':'',
      'type':'view'
    }
  }



}
