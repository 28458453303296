import { NotificationService } from './../../_services/notification.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-box',
  templateUrl: './video-box.component.html',
  styleUrls: ['./video-box.component.css']
})
export class VideoBoxComponent implements OnInit {

  @Input() videoName: string;
  @Input() videoTitle: string;

  videoUrl;
  videoError = false;
  loading = true;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.notificationService.getVideo(this.videoName)
      .subscribe(
        data => {
          console.log("data returned");
          console.log(data);
          this.videoUrl = data;
          this.loading = false;
        },
        error => {
          this.videoUrl = null;
          this.loading = false;
          this.videoError = true;
        }
      )
  }

}
