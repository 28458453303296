<div class="row justify-content-center mt-5">
  <div class="col-sm-8 text-center">

    <div *ngIf="checking">
      <h1 i18n>Checking invitation</h1>
      <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
    </div>

    <div *ngIf="checkGood">
      <h1 i18n>Invitation accepted!</h1>
      <i class="fa fa-check fa-5x" aria-hidden="true" style="color:green"></i>
      <p i18n>Redirecting to dashboard</p>
    </div>

    <div *ngIf="checkBad">
      <h1 i18n>Dashboard not added</h1>
      <p *ngIf="info.length > 0" i18n>Reason: {{info}}</p>
      <i class="fa fa-times fa-5x" aria-hidden="true" style="color:red"></i>
    </div>

  </div>  
</div>  
