import { ActivatedRoute, Router, Params } from "@angular/router";
import { AuthenticationService } from "./../../_services/authentication.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-external",
  templateUrl: "./external.component.html",
  styleUrls: ["./external.component.css"]
})
export class ExternalComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  authProv = "";
  failed = false;
  tokenGot = false;
  loading = true;
  completed = false;

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.authProv = params["authProvider"];
      this.loginWithAppropriateAuth();
    });
  }

  loginWithAppropriateAuth() {
    if (this.authProv.toLowerCase() == "enerlytics") {
      this.getEnerlyticsUser();
      return;
    }

    if (this.authProv.toLowerCase() == "smarthub") {
      this.getSmartHubUser();
      return;
    }
    if (this.authProv.toLowerCase() == "msr-sh") {
      this.getMsrUser();
      return;
    }
  }

  getMsrUser() {
    let tokenHash = this.route.snapshot.queryParams["token"];

    this.authenticationService.msrLogin(tokenHash).subscribe(
      data => {
        this.router.navigate(["/MSR/sites/grchp"]);
      },
      error => {
        console.log(error);
        this.failed = true;
        this.loading = false;
      }
    );
  }
  getSmartHubUser() {
    let tokenHash = this.route.snapshot.queryParams["token"];

    this.authenticationService.acmLogin(tokenHash).subscribe(
      data => {
        this.router.navigate([""]);
      },
      error => {
        console.log(error);
        this.failed = true;
        this.loading = false;
      }
    );
  }

  getEnerlyticsUser() {
    console.log("local:" + this.authenticationService.locale);

    let tokenHash = this.route.snapshot.queryParams["code"];
    this.authenticationService
      .enerlyticsLogin(tokenHash, this.authenticationService.locale)
      .subscribe(
        data => {
          this.router.navigate([""]);
          this.failed = true;
        },
        error => {
          console.log(error);
          this.failed = true;
          this.loading = false;
        }
      );
  }
}
