import { SharedModule } from './../shared/shared.module';
import { DashboardsRoutingModule } from './dashboards.routing';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AcceptDashboardInvitationComponent } from './accept-dashboard-invitation/accept-dashboard-invitation.component';
import { CommentsComponent } from './comments/comments.component';
import { CommentComponent } from './comment/comment.component';
import { DashFolderComponent } from './dash-folder/dash-folder.component';
import { ShareDashboardComponent } from './share-dashboard/share-dashboard.component';
import { ChartSeriesEditComponent } from './chart-series-edit/chart-series-edit.component';
import { SeriesDetailComponent } from './series-detail/series-detail.component';
import { ChartEditComponent } from './chart-edit/chart-edit.component';
import { ChartDetailComponent } from './chart-detail/chart-detail.component';
import { DashboardEditComponent } from './dashboard-edit/dashboard-edit.component';
import { DashboardDetailComponent } from './dashboard-detail/dashboard-detail.component';
import { DashboardsNavComponent } from './dashboards-nav/dashboards-nav.component';
import { DashboardsComponent } from './dashboards.component';
import { DashboardStartComponent } from './dashboard-start/dashboard-start.component';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({


  
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DashboardsRoutingModule,
    SharedModule,
  ],
  declarations: [
    DashboardItemComponent,
    DashboardStartComponent,
    DashboardsComponent,
    DashboardsNavComponent,
    DashboardDetailComponent,
    DashboardEditComponent,
    ChartDetailComponent,
    ChartEditComponent,
    SeriesDetailComponent,
    ChartSeriesEditComponent,
    ShareDashboardComponent,
    AcceptDashboardInvitationComponent,
    CommentsComponent,
    CommentComponent,
    DashFolderComponent,
  ]
})
export class DashboardsModule { }
