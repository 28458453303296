import { ChartSeries } from './series.model';

export class Chart {
    chartId: string
    chartName: string;
    description: string;
    series: ChartSeries[];

    constructor(){
        this.chartName = ''
        this.series = new Array<ChartSeries>();
    }
    // constructor(seed: string) {
    //     this.chartId = seed;
    //     this.name = "Chart " + seed;
    //     this.description = "Description for chart " + seed;
    // }
}