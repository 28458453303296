<div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6">

        <div [hidden]="!loadingSite" class="row justify-content-center mt-5">            
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>           
        </div>

<form class="mt-3" *ngIf="!loadingSite" (ngSubmit)="onSubmit()" #acmPrefForm="ngForm">

    <h3 i18n>Your {{model.siteName}} Preferences</h3>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="alertUpdates" [(ngModel)]="model.alertUpdates" type="checkbox" value="" i18n> Receive alert updates
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="alertFeedback" [(ngModel)]="model.alertFeedback" type="checkbox" value="" i18n> Receive alert feedback
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="alertReports" [(ngModel)]="model.alertReports" type="checkbox" value="" i18n> Receive main reports
      </label>
    </div>

    <hr>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="allUnits" [(ngModel)]="model.allUnits" type="checkbox" value="" i18n> See all units
      </label>
    </div>
    <div [hidden]="model.allUnits" class="form-group">
      <label for="units" i18n>Units</label>
      <angular2-multiselect id="units" [data]="dropdownListUnits" name="selectedUnits" [(ngModel)]="selectedUnits" [settings]="dropdownSettings">
      </angular2-multiselect>
      <hr>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="allAreas" [(ngModel)]="model.allAreas" type="checkbox" value="" i18n> See all areas
      </label>
    </div>
    <div [hidden]="model.allAreas" class="form-group">
      <label for="areas" i18n>Areas</label>
      <angular2-multiselect id="areas" [data]="dropdownListAreas" name="selectedAreas" [(ngModel)]="selectedAreas" [settings]="dropdownSettings">
      </angular2-multiselect>
      <hr>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" name="allAnalysis" [(ngModel)]="model.allAnalysis" type="checkbox" value="" i18n> See all analysis types
      </label>
    </div>
    <div [hidden]="model.allAnalysis" class="form-group">
      <label for="analysis" i18n>Analysis</label>
      <angular2-multiselect id="analysis" [data]="dropdownListAnalysis" name="selectedAnalysis" [(ngModel)]="selectedAnalysis"
        [settings]="dropdownSettings">
      </angular2-multiselect>
      <hr>
    </div>


    <button type="submit" class="btn btn-success" [disabled]="!acmPrefForm.form.valid" i18n>
      Save
    </button>
    <button type="button" class="btn btn-danger" [routerLink]="['../']" i18n>
      Cancel
    </button>

  </form>

    </div>
  </div>
</div>