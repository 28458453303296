<div class="card" [ngClass] = "severity()">
  <div class="card-body">

    <h5 class="card-title">
      <span [innerHTML]="toHistoryTitle() | chatIcon"></span>
      {{ toHistoryTitle() }}</h5>
    <p class="card-text">{{ toHistoryContent() }}</p>
    <p class="card-text float-right">
      <small class="text-muted">
        <!--<span [innerHTML]="alertHistory.utcModified | timeAgo"></span>-->
        <span [innerHTML]="alertHistory.utcModified | date"></span>

        by 
        <span [innerHTML]="alertHistory.modifiedBy | pspUser"></span>
      </small>
    </p>
  </div>
</div>
