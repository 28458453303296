import { concat } from 'rxjs/operator/concat';
import { Subscription } from 'rxjs/Subscription';
import {SignalData} from './../../_models/signal-data.model';
import {DashboardsService} from './../../_services/dashboards.service';
import {Chart} from './../../_models/chart.model';
import {ChartService} from './chart.service';
import {Component,OnInit, OnDestroy, Input} from '@angular/core';
import {Dashboard} from '../../_models/dashboard.model';
import { ChartSeries } from '../../_models/series.model';
// import {forEach} from '@angular/router/src/utils/collection';
import {deflateRawSync} from 'zlib';
import { ActivatedRoute, Router, Params, RouterModule } from '@angular/router';
import { ArrayType } from '@angular/compiler/src/output/output_ast';
import { TemplateParseError } from '@angular/compiler';
import { timeout } from 'rxjs/operators/timeout';
import { unwatchFile } from 'fs';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class ChartComponent implements OnInit {

  data = "";
  xMaxVal = 0;
  xMinVal = 0;
  signals = [];
  changesMade = false;
  sites = ['site 1', 'site 2']

  startDate = new Date();
  endDate = new Date();

  multiscale = false;

  dashSubscription: Subscription;
  clearAllDataSub = new Subscription();

  constructor(
    private dashboardsService: DashboardsService
  ) {}

  @Input() myChart: Chart;
  chart;
  options: Object;


  ngOnInit() {
        
    console.log("ChartComponent ngOnInit()")
    console.log(this.myChart)

    this.clearAllDataSub = this.dashboardsService.clearAllSeries
      .subscribe(
        data => {
          this.clearSeriesData();
        }
      )

    this.options = {
      title : { text : this.myChart.chartName },
      series: [],
      chart: {
        zoomType: 'xy',
        reflow: true
      },
      xAxis: {
        crosshair: true,
        type: 'datetime',
      },
      credits: {
        enabled: false
      },
      // yAxis: {
      //   plotBands: [{ // Light air
      //     from: 100,
      //     to: 1000,
      //     color: 'rgba(0, 255, 0, 0.15)',
      //     label: {
      //         text: 'Good',
      //         style: {
      //             color: '#28a745'
      //         }
      //     }
      // }]
      // }
     
    };

    if (this.myChart.series != null) {
      console.log("chart has " + this.myChart.series.length + " series")
      this.myChart.series.forEach(aSeries => {
        console.log("setting signalData")
        let aSignal = new SignalData();
        aSignal.id = aSeries.sourceId + "_" + aSeries.idAtSource
        aSignal.sourceType = aSeries.sourceType;
        aSignal.sourceId = aSeries.sourceId
        aSignal.idAtSource = aSeries.idAtSource;
        aSignal.name = aSeries.name;
        this.signals.push(aSignal);
      });      
    }

    console.log("signals");
    console.log(this.signals);

    this.dashSubscription = this.dashboardsService.signalChanged
      .subscribe(
        (signal) => {
          console.log("series change detected")
          this.setSeries(signal,this.multiscale);
        }
      )

    this.dashboardsService.dashboardsDatesChanged
      .subscribe(
        data => {
          this.startDate = data['from'];
          this.endDate = data['to'];
          
        }
      )
      
  }


  toggleMultiscale(){
    this.multiscale = !this.multiscale;
    console.log("toggling multiscale");
    this.clearSeries();

    if(!this.multiscale){
      this.chart.addAxis({
        id: 'yAxis'
      })
    }

    this.signals.forEach(signal => {
      
      this.chart.addAxis({
        id: "axis" + signal['id'],
        visible: false
      })
      this.setSeries(signal, this.multiscale);
    });
  }

  clearSeries(){
    while( this.chart.series.length > 0 ) {
      this.chart.series[0].remove( false );
    }
    while( this.chart.yAxis.length > 0 ) {
      this.chart.yAxis[0].remove( false );
    }
  }

  addPlotBand(){
    this.chart.yAxis[0].addPlotBand(
      {
        from: 100,
        to: 1000,
        color: 'rgba(0, 255, 0, 0.15)',
        label: {
            text: 'Good',
            style: {
                color: '#28a745'
            }
        }
      }
    )
  }

  ngOnDestroy() {
    this.dashSubscription.unsubscribe();
    this.clearAllDataSub.unsubscribe();
    console.log("unsubscribing dashSubscription")
  }
  
  clearSeriesData(){
    console.log("clearing all series data")
    this.chart['series'].forEach(seriesElement => {
      seriesElement.setData([]);
    });
  }

  setSeries(signal: {}, multiScale: boolean){
    console.log("signal data change in chartcomponent");
    console.log(signal);
    
    let yIndex = this.signals.findIndex(x => x.id == signal['id']) >= 0;
    if (this.signals.findIndex(x => x.id == signal['id']) >= 0) {
      console.log("series matched in chart");
      this.signals.find(x => x.id == signal['id']).data = signal['data'];
      
      
      var seriesToEdit = this.chart.get(signal['id']);


      if (seriesToEdit == undefined){
        console.log("series " + signal['id'] + " not found in chart " + this.myChart.chartId);
        // add new series

        if (multiScale){
          this.chart.addSeries({
            id: signal['id'],
            "name": signal['name'],
            "tooltip": signal['tooltip'],
            "data":[],
            "yAxis": "axis" + signal['id']
          })
        } else {          
          this.chart.addSeries({
            id: signal['id'],
            "name": signal['name'],
            "tooltip": signal['tooltip'],
            "data":[]
          })
        }
      }

      if (this.chart.get(signal['id']).data.length == 0) {
        console.log("setting data");
        this.chart.get(signal['id']).setData(signal['data']);
      }

      this.chart.series.sort(function(a, b){
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
    })

    }
  }

  saveInstance(chartInstance) {
    console.log("save instance")
    this.chart = chartInstance;
    console.log(this.chart)
}


// setSeriesData(signal: SignalData) {
    
//   console.log("setSeriesData()");
//   console.log(signal);

//   // check signal has data
//   if (signal.data.length <= 0) {
//     console.log("no signal data")
//     return;
//   }

//   let nameForSeries = signal.name // will need changing
//   let series = {};
//   series = { 
//     "name": nameForSeries,
//     "data": []
//   }

//   console.log("processing signal");
 
//   if(signal.data != null) {
//     for (var iData = 0, lenData = signal.data.length; iData < lenData; iData++) {
//       let dateAndValue = signal.data[iData];
//       series['data'].push(
//         [Date.parse(dateAndValue['dateTime']), dateAndValue['value']]
//       )
//     }
//   }

//    console.log(this.chart['series']);
//    let indexToEdit = this.chart['series'].findIndex(x => x.userOptions.id == signal.idAtSource);

//    var seriesToEdit = this.chart.get(signal.idAtSource);

//    if (seriesToEdit == undefined){
     
//      console.log("series " + signal.idAtSource + " not found in chart " + this.myChart.chartId)
//      // add new series
//      this.chart.addSeries({
//        id: signal.idAtSource,
//        "name": signal.name,
//        "tooltip": {
//            valueDecimals: 1,
//            valueSuffix: signal.engUnits
//        },
//        "data":[]
//      })
     
//    }
//     if (this.chart.get(signal.idAtSource).data.length == 0) {
//       this.chart.get(signal.idAtSource).setData(series['data'])      
//     }

//     console.log(this.chart);
//     console.log("finished setData()");

// }

  deleteChart() {
    if(confirm("Are you sure to delete this chart?")) {
      this.dashboardsService.deleteChart(this.myChart.chartId);
    }
  }


}
