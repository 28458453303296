import { AuthenticationService } from "./authentication.service";
import { Subject } from "rxjs/Subject";
import { ConnStrModule } from "./conn-str.module";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";

@Injectable()
export class NotificationService {
  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule,
    private authenticationService: AuthenticationService
  ) {}

  notificationsChanged = new Subject<any>();
  notificationCountChanged = new Subject<any>();

  private notifications: Array<{}>;
  private notificationCount: number;

  // start a timer after 500ms that ticks every 15 seconds
  timerNotifications;
  timerNotificationsStarted = false;

  getNotificationCount() {
    console.log("getNotificationCount()");
    if (!this.timerNotificationsStarted) {
      console.log("notification check not started. Starting");
      this.timerNotificationsStarted = true;
      this.timerNotifications = Observable.timer(500, 15000);
      this.timerNotifications.subscribe(() => {
        if (!this.authenticationService.isAuthenticated()) {
          return;
        }
        this.httpClient
          .get<{}>(this.connStr.pspBaseUrl + "Notifications/UnreadCount")
          .map(response => {
            return response;
          })
          .subscribe(data => {
            this.notificationCount = data["count"];
            this.notificationCountChanged.next(this.notificationCount);
          });
      });
    }
  }

  seenNotification(id: string) {
    console.log("notificationId: " + id);
    return this.httpClient
      .put<{}>(this.connStr.pspBaseUrl + "Notifications/" + id + "/seen", "")
      .map(response => {
        return response;
      })
      .subscribe(data => {
        this.getNotificationCount();
        this.getNotifications(null);
      });
  }

  readAllNotifications() {
    return this.httpClient
      .put<{}>(this.connStr.pspBaseUrl + "Notifications/MarkAllAsSeen", "")
      .map(response => {
        return response;
      })
      .subscribe(data => {
        this.getNotificationCount();
        this.getNotifications(null);
      });
  }

  getNotifications(from: Date) {
    this.httpClient
      .get<{}[]>(this.connStr.pspBaseUrl + "Notifications")
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          this.notifications = data;
          this.notificationsChanged.next(this.notifications.slice());
        },
        error => {
          this.notificationsChanged.next(error);
        }
      );

    // // from is an optional value for when we want to load additional notifications
    // let myParams = new HttpParams();
    // // Begin assigning parameters
    // myParams = myParams.append('from', from.toISOString());

    // let newNotifications = [
    //   {
    //     'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.',
    //     utcTimestamp: new Date(),
    //     url: 'a',
    //     seen: false
    //   },
    //   {
    //     'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.',
    //     utcTimestamp: new Date(),
    //     url: 'b',
    //     seen: false
    //   },
    //   {
    //     'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.',
    //     utcTimestamp: new Date(),
    //     url: 'c',
    //     seen: true
    //   },
    // ];

    // Observable.create(observer => {
    //   observer.next(
    //     newNotifications
    //   )
    //   observer.onCompleted;
    // });

    // return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'notifications', {params: myParams})
    //   .map((response) => {
    //       return response;
    //   })
  }

  getLandingVideo() {
    return this.httpClient
      .get<{}>(this.connStr.pspBaseUrl + "Notifications/LandingVideo")
      .map(response => {
        return response;
      });
  }

  getVideo(videoName: string) {
    return this.httpClient
      .get<{}>(this.connStr.pspBaseUrl + "Notifications/Video/" + videoName)
      .map(response => {
        return response;
      });
  }
}
