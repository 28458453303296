<a 
  class="list-group-item list-group-item-action flex-column align-items-start" 
  [routerLink]="[siteId,report.hashId]"
  [ngClass]="sentCss()"
  routerLinkActive="active">

  
  <i class="fa fa-file-text" aria-hidden="true"></i>
  <b>{{ report.utcFrom | date:'mediumDate'}}</b> to <b>{{ report.utcTo | date:'mediumDate'}}</b>
  <br>

  <span *ngIf="report.createdBy != null"><i class="fa fa-history" aria-hidden="true" i18n></i> Created</span>

  <span *ngIf="report.approvedBy != null"> | <i class="fa fa-thumbs-o-up" aria-hidden="true" i18n></i> Approved</span>
  
  <span *ngIf="report.status.toLowerCase() == 'pending approval'"> | <i class="fa fa-question-circle-o" aria-hidden="true" i18n></i> Pending Approval</span>
  
  <span *ngIf="report.sentBy != null"> | <i class="fa fa-envelope-o" aria-hidden="true" i18n></i> Sent</span>

</a>