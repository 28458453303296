<a 
  class="list-group-item list-group-item-action flex-column align-items-start"
  (click)="onNavigate()">

  <blockquote class="blockquote mb-0">
    <p [style.font-weight]="getSeenStyle()">
      {{ notification.text }}
    </p>
    <footer class="blockquote-footer">{{ notification.utcTimestamp | date:'EEEE HH:mm, d MMM y' }}</footer>
  </blockquote>

</a>