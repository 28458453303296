import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertsService } from '../../_services/alerts.service';

@Component({
  selector: 'app-open-alerts',
  templateUrl: './open-alerts.component.html',
  styleUrls: ['./open-alerts.component.css']
})
export class OpenAlertsComponent implements OnInit, OnDestroy {

  @Input() units: {}[];
  @Input() areas: {}[];
  siteId;
  
  loading = true;
  loadedOk = true;

  alerts;
  filtAlerts = [];
  remAlerts = [];
  prevFilter = [];

  siteSubscription: Subscription = new Subscription();
  alertsSubscription: Subscription = new Subscription();
  
  constructor(
    private alertsService: AlertsService,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy() {
    this.alertsSubscription.unsubscribe();
  }
  ngOnInit() {
   

    this.alertsSubscription = this.alertsService.alertsChanged
    .subscribe(
      (alerts) => {
        console.log("alerts", alerts);
        this.alerts = alerts;
        this.loading = false;
        this.loadedOk = true;
      }, error => {
        this.loading = false;
        this.loadedOk = false;
      }
    );

    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId'];
        this.alertsService.getAlerts(this.siteId, true, false);
      }
    );

  }

  
  filteredAlerts(){
    var toFilter = [];
    toFilter.push(this.units.slice());
    toFilter.push(this.areas.slice());
    if(JSON.stringify(this.prevFilter) == JSON.stringify(toFilter)){
      return this.filtAlerts;
    } else {      
      this.prevFilter = toFilter.slice()
    
      console.log("new filter needed")
      
      this.prevFilter = toFilter.slice()
      this.filtAlerts = [];
      this.remAlerts = [];
      if (!this.units || !this.areas){
        return this.alerts;
      }
      console.log("alerts", this.alerts)
      console.log("units", this.units)
      console.log("areas", this.areas)
      for (const anAlert of this.alerts) {
        var unitMatched = false;
        var areaMatched = false;
        
        for (const alertUnit of anAlert['units']) {
          for (const filterUnit of this.units) {
            if (alertUnit['name'].toLowerCase() == filterUnit['itemName'].toLowerCase()){
              unitMatched = true;
              continue;
            };
          };
        };
        for (const alertArea of anAlert['areas']) {
          for (const filterArea of this.areas) {
            if (alertArea['name'].toLowerCase() == filterArea['itemName'].toLowerCase()){
              areaMatched = true;
              continue;
            }
          };
        };

        if (areaMatched || unitMatched){
          this.filtAlerts.push(anAlert);
        } else {
          this.remAlerts.push(anAlert);
        }
      }
      console.log("filtAlerts",this.filtAlerts)
      return this.filtAlerts;
    }
  }

  remainingAlerts(){
    
  }


}
