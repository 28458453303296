import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msr-home',
  templateUrl: './msr-home.component.html',
  styleUrls: ['./msr-home.component.css']
})
export class MsrHomeComponent implements OnInit {

  gotMsrToken = false;
  loggingIn = false;
  returnUrl: string;
  isAcmUser = false;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.gotMsrToken = this.authenticationService.isMsr();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'sites';
    this.isAcmUser = this.authenticationService.isAcm('');
  }

  msrLogin(){
    this.loggingIn = true;
    this.authenticationService.getMsrToken()
      .subscribe(
        data => {
          this.gotMsrToken = true;
          this.loggingIn = false;
          console.log(this.returnUrl)
          this.router.navigate([this.returnUrl], {relativeTo: this.route});
        },
        error => {
          this.gotMsrToken = false;
          this.loggingIn = false;
          alert("Unable to login to MSR");
        }
      )
  }

}
