<div class="row justify-content-center mb-3">
  <div class="col-sm-8">

    <h3 class="mt-3 mb-3" i18n>Edit User Access</h3>

    <div class="form-group">
      <label for="levelSelect" i18n>Edit Permissions...</label>
      <select id="levelSelect" #selectedLevel class="form-control" (change)="loadCompanyOrSite(selectedLevel.value)">
        <option selected i18n>Choose level...</option>
        <option *ngFor="let aLevel of dropdownListGlobal" value="{{aLevel.id}}">{{aLevel.name}}</option>
      </select>
    </div>


    <div class="form-group" [hidden]= !isCompany>
      <label for="companySelect" i18n>Select company</label>
      <select #selectedCompany class="form-control" id="companySelect" (change)='loadUserRoles("company",selectedCompany.value)'>
        <option selected i18n>Choose Company...</option>
        <option *ngFor="let aCompany of dropdownListCompany" value="{{aCompany.id}}">{{aCompany.name}}</option>
      </select>
    </div>
    <div class="form-group" [hidden]= !isSite>
      <label for="siteSelect" i18n>Select site</label>
      <select #selectedSite class="form-control" id="siteSelect" (change)='loadUserRoles("site",selectedSite.value)'>
        <option selected i18n>Choose Site...</option>
        <option *ngFor="let aSite of dropdownListSites" value="{{aSite.id}}">{{aSite.name}}</option>
      </select>
    </div>

    <div class="form-group" [hidden] = !readyForRole>
      <label for="global" i18n>User Roles</label>
      <angular2-multiselect 
        id="global" 
        [data]="grantableRoles" 
        [(ngModel)]="selectedRoles" 
        [settings]="dropdownSettings">
      </angular2-multiselect>
    </div>

    <button type="submit" class="btn btn-success" (click)="onSubmit()" style="cursor: pointer;" i18n>
      Save
    </button>


    <hr>
    <p i18n>Permissions set:</p>
    {{otherUserAccess()}}
    
    <!-- <ul>
      <li *ngFor="let aPermission of otherUserAccessTree">{{aPermission}}</li>
    </ul> -->

  </div>
</div>
