import { concat } from 'rxjs/operator/concat';
import { Dashboard } from './../../_models/dashboard.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { FormGroup, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { Chart } from '../../_models/chart.model';



@Component({
  selector: 'app-chart-edit',
  templateUrl: './chart-edit.component.html',
  styleUrls: ['./chart-edit.component.css']
})
export class ChartEditComponent implements OnInit {
  // @ViewChild('f') chartForm: NgForm;

  editMode = false;
  dashboardId = '';
  chartId = '';
  chart: Chart = new Chart();

  constructor(
    private route: ActivatedRoute,
    private dashboardsService: DashboardsService,
    private router: Router) { }

  ngOnInit() {

    this.chart = new Chart();

    this.route.params
      .subscribe(
        (params: Params) => {
          this.dashboardId = params['dashId'];
          this.chartId = params['chartId'];
          this.editMode = (params['chartId'] != null);
          console.log("ChartEditComponent dashId = " + this.dashboardId);
          console.log("ChartEditComponent editMode = " + this.editMode);
          
        }
      );

    this.dashboardsService.dashboardChanged.subscribe(
      (data) => {
        console.log("chart-edit dashboard change detected")
        if (this.editMode) {
          this.chart = data.charts.find(chart => chart.chartId == this.chartId);
        }
      },
      error => {
        console.log(error);
      }
    )
     
    this.dashboardsService.getFullDashboard(this.dashboardId);

  }

  onSubmit() {
    if (this.editMode) {
      this.dashboardsService.updateChart(this.chartId, this.chart);
    } else {
      this.dashboardsService.addChart(this.dashboardId, this.chart);
    }
    console.log("current route " + this.route)

    if (this.editMode){
      this.router.navigate(['../../../'], {relativeTo: this.route});
    } else {
      this.router.navigate(['../../'], {relativeTo: this.route});
    }
  }
  
  onCancel() {
    if (this.editMode){
      this.router.navigate(['../../../'], {relativeTo: this.route});      
    } else {
      this.router.navigate(['../../'], {relativeTo: this.route});      
    }
  }

  // getAddedSignals(chartForm) {
  //   if (chartForm) {
  //     console.log("returning chart form signals")
  //     return chartForm.get('signals').controls;      
  //   }
  //   console.log("no chart form signals")
  // }

}
