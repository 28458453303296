import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusText',
  pure: true
})
export class StatusTextPipe implements PipeTransform {

  transform(statusNumber: string): any {

    switch(statusNumber) {
        case '1': { 
          return 'Closed';
        } 
        case '2': { 
          return 'Ongoing';
        } 
        case '3': { 
          return 'Requires Feedback';
        } 
        case '7': { 
          return 'Maintenance Planned';
        } 
        case '8': { 
          return 'Pending Return to Service';
        } 
        case '11': { 
          return 'Feedback Received';
        } 
        case '12': { 
          return 'Pending Approval';
        } 
        case '13': { 
          return 'Maintenance Considered';
        } 
        case '14': { 
          return 'In Creation';
        } 
        case '15': { 
          return 'CMS Pending Approval';
        } 
        default: { 
           return 'Unkown status: ' + statusNumber;
        } 
     }
  }

}
