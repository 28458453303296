<h3 i18n><i class="fa fa-tags" aria-hidden="true"></i> Tag Search</h3>
<form>
  
  <div class="form-row">

    <div class="form-group col-md-4">
      <select #selectedSource class="form-control" (change)="loadSourceSignals(selectedSource.value)" aria-describedby="sourceHelp">
        <option selected i18n>Choose site...</option>
        <option *ngFor="let aSource of sourcesList" value="{{aSource.id}}">{{aSource.name}}</option>
      </select>
      <!-- <small id="sourceHelp" class="form-text text-muted">Select a data source</small> -->
      <small id="sourceHelp" class="form-text text-muted" *ngIf="!sourceSelected" i18n>{{sourcesList.length}} sources</small>
      <small id="sourceHelp" class="form-text text-muted" *ngIf="sourceSelected && signalListLoading" i18n><i class="fa fa-circle-o-notch fa-spin"></i> Getting signals...</small>
      <small id="sourceHelp" class="form-text text-muted" *ngIf="sourceSelected && !sourcesListLoading && !signalListLoading" i18n>{{sourceSignals.length}} signals loaded</small>
    </div>

    <div class="form-group col-md-8">
      <input 
        type="text" 
        class="form-control" 
        id="tagSearch" 
        aria-describedby="searchHelp" 
        placeholder="Search {{sourceSignals.length}} tags"
        [(ngModel)]="filterStr" name="filterStr">
      <small *ngIf="filterStr.length==0" id="searchHelp" class="form-text text-muted" i18n>Search for tag names, descriptions, and units</small>
      <small *ngIf="filterStr.length>0" id="searchHelp" class="form-text text-muted" i18n>First 100 results shown</small>
    </div>

  </div>

</form>

<div class="row justify-content-center">
  <div class="col-md-12">
    <div class="list-group">
    <app-search-item 
    *ngFor="let result of sourceSignals | filterSignals:filterStr | slice:0:100" 
    [signalResult]="result"
    (selectedSignal)="signalSelected($event)"></app-search-item>
    </div>
  </div>
</div>