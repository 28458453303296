<div class="row justify-content-center">

  <div class="col-md-6">
    <form class="form-inline">
        <button [routerLink]="[siteReports.site.siteId,'new']" type="button" class="btn btn-success" i18n><i class="fa fa-file-text" aria-hidden="true"></i> New</button> 
        <h3>&nbsp;{{siteReports.site.name}}</h3>
    </form>

    <app-report-item 
      *ngFor="let report of siteReports.reports" 
      [report]="report" 
      [siteId]="siteReports.site.siteId">
    </app-report-item>

    <hr>
  </div>
</div>
