import { SitesService } from './../../_services/sites.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actionable-home',
  templateUrl: './actionable-home.component.html',
  styleUrls: ['./actionable-home.component.css']
})
export class ActionableHomeComponent implements OnInit {

  companies = [];

  collapsed = false;
  
  constructor(
    private sitesService: SitesService
  ) { }

  ngOnInit() {

    this.sitesService.getCompanies()
      .subscribe(
        data => {
          console.log("companies got");
          this.companies = data;
        }
      )
  }
}