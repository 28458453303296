import { Subject } from 'rxjs/Subject';
import { ConnStrModule } from './conn-str.module';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class MsrService {

  plantItemChanged = new Subject<{}>();
  plantItemsChanged = new Subject<{}[]>();

  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule
  ) { }


  requestPlantItems(siteId: string){
    console.log("request plant items");
    return this.httpClient.get<{}[]>(this.connStr.msrDataUrl + 'FacePlant/' + siteId + '/demo')
      .map((response) => {
          return response;
      })
      .subscribe(
        data => {
          this.plantItemsChanged.next(data);
        },
        error => {
          console.log(error);
          this.plantItemsChanged.error("Error getting plant items");
        })
  }

  requestPlantItem(siteId: string, itemId: string){
    console.log("request plant items");
    return this.httpClient.get<{}>(this.connStr.msrDataUrl + 'FacePlant/' + siteId + '/' + itemId)
      .map((response) => {
          return response;
      })
      .subscribe(
        data => {
          console.log("plant item received from api");
          console.log(data);
          this.plantItemChanged.next(data);
        },
        error => {
          console.log(error);
          this.plantItemChanged.error("Error getting plant item");
        })
  }

}
