import { SignalGetterService } from './../../_services/signal-getter.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tag-sparkline',
  templateUrl: './tag-sparkline.component.html',
  styleUrls: ['./tag-sparkline.component.css']
})
export class TagSparklineComponent implements OnInit {
  @Input() piServer: string;
  @Input() tagName: string;
  @Input() sparkname: string;

  points = [];
  chart;

  options

  loading = true;

  to = new Date(Date.now());    
  from = new Date(this.to);


  constructor(
      private signalGetterService: SignalGetterService
  ) { }
  
  ngOnInit() {

    this.from.setDate(this.from.getDate() - 1);

    this.options = {
        chart:{
            margin:[0, 0, 0, 0],
            backgroundColor:'transparent'
        },
        title:{
            text:''
        },
        credits:{
            enabled:false
        },
        xAxis:{
            labels:{
                enabled:false
            },
            min: this.from.getTime(),
            max: this.to.getTime(),
            ordinal : false,
            startOnTick : false,
            endOnTick : false,
            type : 'datetime',
        },
        yAxis:{
            maxPadding:0,
            minPadding:0,
            gridLineWidth: 0,
            endOnTick:false,
            labels:{
                enabled:false
            }
        },
        legend:{
            enabled:false
        },
        tooltip:{
            enabled:false
        },
        plotOptions:{
            series:{
                animation: false,
                enableMouseTracking:false,
                lineWidth:3,
                shadow:false,
                states:{
                    hover:{
                        lineWidth:1
                    }
                },
                marker:{
                    enabled:false,
                    // radius:0,
                    // states:{
                    //     hover:{
                    //         radius:2
                    //     }
                    // }
                }
            }
        },
        series: [
            {
                type:'area',
                color: '#868e96',
                data: []
            }
        ]
    
    };

    console.log(this.options)

    this.getPiData();

  }

  makeChart(){

    var sparkSeries = [{
      name: 'sparkline',
      data: this.points
    }];
    this.chart.series[0].setData(this.points);
  }

  loadingText(){
      if (this.loading){
          return "loading"
      };
      if (!this.loading){
          if (this.points == null){
              return "no data"
          };
          if (this.points != null){
              return "offline"
          }
      }
  }

  generateRandomDatedData(xMin, xMax){
    var rndData = []
    var rollNum = 10;
    var stepAmount = (xMax - xMin)/1000
    for (var index = 0; index < 1000; index++) {
        var rndNum = Math.random() - 0.5
        rollNum = rollNum + rndNum;
        let today = new Date();
        var rollDate = new Date(xMin + (stepAmount * index))        
        rndData.push(
            [rollDate, rollNum]
        )
    }
    return rndData;
}

isOn(){
    if (this.points == null){      
        return false;
    }
    if (this.points.length == 0){
        // console.log("no points");
        return false;
    }
    let lastIndex = this.points.length - 1;
    let lastTime = this.points[lastIndex][0] / 1000;
    let lastValue = this.points[lastIndex][1];
    let hourAgo = (this.to.getTime() / 1000) - (60*60);
    let timeDiff = lastTime - hourAgo;
    if ( timeDiff < (60*60) && lastValue > 1) {
        return true;
    }
    return false;
}

liveLoad(){
    if( this.isOn() ){
        let lastIndex = this.points.length - 1;
        return this.points[lastIndex][1].toPrecision(3)
    }
    return "0";
}

getPiData(){
    
    this.signalGetterService.getDatedPiData(this.piServer, this.tagName, this.from, this.to)
        .subscribe(
            data => {
                console.log("Spark line data returned");
                console.log(data);
                this.loading = false;
                let minDate = this.to.getTime();
                for (let aPoint of data['piTagList'][0]['piTagData']){
                    let pointDate = Date.parse(aPoint['dateTime'])
                    let pointVal = parseFloat(aPoint['tagValue'])
                    if (pointVal < 2) {
                        pointVal = 0;
                    }
                    this.points.push([pointDate, pointVal])
                    // if( pointDate < minDate ){
                    //     minDate = Date.parse(aPoint['dateTime']);
                    // }
                }
                // if min point is more than an hour from what we expect, add in a zero point
                // if (((minDate - this.from.getTime()) / 1000) > 60 * 60 ){
                //     this.points.unshift([this.from.getTime(),0]);
                // }
                // console.log("points");
                // console.log(this.points);
                this.makeChart();
            },
            error => {
                this.loading = false;
                this.points = null;
            }
        )
}

  saveInstance(chartInstance) {
    this.chart = chartInstance;
    // this.makeChart();
}

}
