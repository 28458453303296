import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {

  @Output() userSelected = new EventEmitter();

  searching = false;
  searched = false;
  searchError = false;
  model = {
    search: ''
  }

  userResults = [];

  constructor(
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
  }

  onSubmit(){
    this.searching = true;
    this.searched = false;
    console.log("Search", this.model);
    this.userResults = [];
    
    this.userManagementService.searchAllUsers(this.model.search)
    .subscribe(data => {
      this.userResults = data;
      this.searching = false;
      this.searched = true;
      this.searchError = false;
    }, error => {
      this.userResults = [];
      this.searching = false;
      this.searched = false;
      this.searchError = true;
    })

    // setTimeout(() => {
    //   this.userResults = [];
    //   this.userResults.push(
    //     {
    //       name: "John Smith",
    //       username: "J12345",
    //       email: "john.smith@uniper.energy"
    //     },
    //     {
    //       name: "John Smath",
    //       username: "J23456",
    //       email: "john.smath@uniper.energy"
    //     },
    //     {
    //       name: "John Smoth",
    //       username: "J34567",
    //       email: "john.smoth@uniper.energy"
    //     },
    // )
    //   this.searching = false;
    //   this.searched = true;
    // },1000)
  }

  onUserSelect(user){
    this.userSelected.emit(user);
  }

}
