import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  model = {
    name: '',
    email: '',
    authId: ''
  }
  submitted = false;
  addedUsers = [];
  adding = false;

  constructor(
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
  }


  onSubmit() { 
    this.adding = true;
    this.submitted = true; 
    this.userManagementService.createNewUser(this.model).subscribe(
      data => {
        this.adding = false;
        this.addedUsers.push(Object.assign({}, this.model));
      }, error => {
        this.adding = false;
        alert("Unable to add user. Error: " + error.error);
      }
    )
  }

  newUser(){
    this.model = {
      name: '',
      email: '',
      authId: ''
    }
    this.submitted = false;
  }

  
  
}
