import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthenticationService } from './../../_services/authentication.service';
import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-alert-pref-edit',
  templateUrl: './site-alert-pref-edit.component.html',
  styleUrls: ['./site-alert-pref-edit.component.css']
})
export class SiteAlertPrefEditComponent implements OnInit {

  siteId;

  loadingSite = true;

  model = {};

  dropdownListUnits = [];
  selectedUnits = [];

  dropdownListAreas = [];
  selectedAreas = [];

  dropdownListAnalysis = [];
  selectedAnalysis = [];



  dropdownSettings = { 
    text:"Select",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true
  };

  colorMap = {
    true: 'table-success',
    false: 'table-warning',
    none: 'table-danger',
    all: 'table-success',
    filtered: 'table-secondary',
  }

  constructor(
    private userManagementService: UserManagementService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId']
        console.log("siteId() " + this.siteId)
        this.getSitePrefs(this.siteId);
      }
    )
    
  }
  

  getSitePrefs(siteId: string){
    this.loadingSite = true;
    this.userManagementService.getSiteAcmPreferences(siteId)
    .subscribe(
      data => {
        console.log(data)
        this.loadingSite = false;
        this.model = data;

        this.dropdownListUnits = [];
        this.selectedUnits = [];
        for (let unit of this.model['availableUnits']){
          this.dropdownListUnits.push({
            id: unit['UnitId'],
            itemName: unit['Name']
          })
        };
        if(this.model['allUnits']){
          this.model['selectedUnits'] = [];
        }
        for (let unit of this.model['selectedUnits']){
          this.selectedUnits.push({
            id: unit['UnitId'],
            itemName: unit['Name']
          })
        };

        this.dropdownListAreas = [];
        this.selectedAreas = [];
        for (let area of this.model['availableAreas']){
          this.dropdownListAreas.push({
            id: area['AreaId'],
            itemName: area['Name']
          })
        };
        if(this.model['allAreas']){
          this.model['selectedAreas'] = [];
        }
        for (let area of this.model['selectedAreas']){
          this.selectedAreas.push({
            id: area['AreaId'],
            itemName: area['Name']
          })
        };

        this.dropdownListAnalysis = [];
        this.selectedAnalysis = [];
        for (let analysis of this.model['availableAnalysis']){
          this.dropdownListAnalysis.push({
            id: analysis['Id'],
            itemName: analysis['Description']
          })
        };
        if(this.model['allAnalysis']){
          this.model['selectedAnalysis'] = [];
        }
        for (let analysis of this.model['selectedAnalysis']){
          this.selectedAnalysis.push({
            id: analysis['Id'],
            itemName: analysis['Description']
          })
        };

      },
      error => {
          this.loadingSite = false;
          // this.infoAlertService.error(error);
      });
    }
  

  onSubmit(){
    console.log(this.model);
    this.loadingSite = true;
    this.model['selectedUnits'] = this.selectedUnits;
    this.model['selectedAreas'] = this.selectedAreas;
    this.model['selectedAnalysis'] = this.selectedAnalysis;
    this.userManagementService.updateSiteAcmPreferences(this.model['site'], this.model)
      .subscribe(
        data => {
          console.log("successful save")
          // go back to main page
          this.router.navigate(["../"],{relativeTo: this.route});
        },
        error => {
          console.log(error);
            // this.infoAlertService.error(error);
        });

  }

}
