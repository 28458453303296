

<div class="row justify-content-center">

    <div class="col-md-3 mb-2">
      <h4 class="display-4">Select a Site</h4>

      <form class="form-inline">
        <select class="form-control" (change)="navigateTo($event.target.value)">
          <option value="">Select a Site</option>
          <option *ngFor="let site of sites" value="{{site.siteId}}">{{site.name}}</option>
        </select>
      </form>

    </div>
  
    <div class="col-md-6">
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="styles">
        <agm-marker 
          *ngFor="let s of sites;" 
          [latitude]="s.latitude | toFloat" 
          [longitude]="s.longitude | toFloat">
          <agm-info-window>
            <a routerLink="{{s.siteId}}">{{s.name}}</a>
          </agm-info-window>
        </agm-marker>
      </agm-map>
    </div>
  
    <div class="col-md-3">
      <!-- placeholder for right-side column -->
    </div>
  </div>
