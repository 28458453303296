import { DashboardsService } from './../../_services/dashboards.service';
import { Dashboard } from './../../_models/dashboard.model';
import { Chart } from './../../_models/chart.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.css']
})
export class DashboardDetailComponent implements OnInit {

  dashboardChangedSub = new Subscription();
  datesChangedSub = new Subscription();

  dashboard: Dashboard = new Dashboard();
  editTitle = false;
  loading = true;
  dashId;

  firstDateSet = true;
  firstDatesGot = false;
  firstDashGot = false;

  comments = [];

  dates = {
    "from": {
      "year": null,
      "month": null,
      "day": null
    },
    "to": {
      "year": null,
      "month": null,
      "day": null
    }
  }

  constructor(
    private dashboardsService: DashboardsService,
    private route: ActivatedRoute,
    private router: Router) { 
    }

    dateTo = new Date();
    dateFrom  = new Date();


  ngOnInit() {

    this.dashboardChangedSub = this.dashboardsService.dashboardChanged
      .subscribe(
        (dashboard: Dashboard) => {
          console.log("new dash emitted");
          this.firstDashGot = true;
          this.dashboard = dashboard;
          this.loading =  false;

          if(this.dashboard['config'] != null){
            // set dates from dashboard config
            console.log("dash dates config found");
          } else {
            // default 1 week
            console.log("no dash dates config");
            this.setDefaultDates(7);
          }

        },
        error => {
          console.log(error);
        }
      )

    this.datesChangedSub = this.dashboardsService.dashboardsDatesChanged
      .subscribe(
        (datesOut: {}) => {
          console.log("new dash dates emitted");
          this.firstDatesGot = true;
          this.dateFrom = datesOut['from'];
          this.dateTo = datesOut['to'];
          this.updateDatePickers();
          this.requestData();
        }
      )

    this.route.params
      .subscribe(
        (params: Params) => {
          this.loading = true;
          this.dashId = params['dashId'];
          this.dashboardsService.getFullDashboard(this.dashId);
          this.dashboardsService.getDashboardComments(this.dashId)
            .subscribe(
              (data) => {
                this.comments = data;
              }
            )
        }
      )

      this.dashboardsService.hideSidebar();
  }

  ngOnDestroy(){
    this.dashboardChangedSub.unsubscribe();
  }

  
  toggleSidebar(){
    this.dashboardsService.toggleSidebar();
  }

  getCharts() {
    return this.dashboard.charts;
  }

  deleteDashboard() {
    if(confirm("Are you sure to delete this dashboard?")) {
      this.dashboardsService.deleteDashboard(this.dashboard.dashboardId);
      this.router.navigate(['../'], {relativeTo: this.route})
    }
  }

  updateDatePickers() {
    console.log("setting date picker values = " + this.dateFrom + " - " + this.dateTo);
    this.dates.from.year = this.dateFrom.getFullYear();
    this.dates.from.month = this.dateFrom.getMonth()+1;
    this.dates.from.day = this.dateFrom.getDate();

    this.dates.to.year = this.dateTo.getFullYear();
    this.dates.to.month = this.dateTo.getMonth()+1;
    this.dates.to.day = this.dateTo.getDate();
    console.log(this.dates)
  }

  onSetDates(){
    this.sendDatePickerDatesToDashService()
  }

  setDefaultDates(days: number){
    console.log("setting range to " + days + " days")
    let dateTo = new Date();
    let dateFrom = new Date();
    dateFrom.setDate(dateTo.getDate() - days);
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.updateDatePickers()
    this.sendDatePickerDatesToDashService();
  }
  
  sendDatePickerDatesToDashService(){
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setFullYear(this.dates.from.year, this.dates.from.month-1, this.dates.from.day)
    dateTo.setFullYear(this.dates.to.year, this.dates.to.month-1, this.dates.to.day)
    this.dashboardsService.updateDates(dateFrom, dateTo)
  }

  requestData() {
    if (!(this.firstDashGot && this.firstDatesGot)){
      console.log("not requesting data yet")
      return;
    }
    console.log("requesting data from dash service")
    this.dashboardsService.requestData();

  }

}
