import { InfoAlertService } from "./../_services/info-alert.service";
import { AuthenticationService } from "./../_services/authentication.service";
import { Component, OnInit } from "@angular/core";
import { Observer } from "rxjs";

@Component({
  selector: "app-forgotten-password",
  templateUrl: "./forgotten-password.component.html",
  styleUrls: ["./forgotten-password.component.css"]
})
export class ForgottenPasswordComponent implements OnInit {
  loading = false;
  sent = false;
  requestText = "Request Reset Link";
  model: any = {};
  constructor(
    private authenticationService: AuthenticationService,
    private infoAlertService: InfoAlertService
  ) {}

  ngOnInit() {}

  SubmitReset() {
    console.log("Requesting password reset for " + this.model.email);
    this.loading = true;
    const resetSubscribtion = this.authenticationService.requestResetLink(
      this.model.email
    );

    resetSubscribtion.subscribe(
      data => {
        console.log("Request Reset Link Success");
        console.log(data);
        this.infoAlertService.success(data);
        this.loading = false;
        this.requestText = "Email sent";
      },
      error => {
        console.log("Request Reset Link Error");
        console.log(error);
        this.requestText = "Error sending email";
        this.infoAlertService.error("Password reset unsuccessful");
        this.loading = false;
      }
    );
    this.sent = true;
  }
}
