import { Router, NavigationStart } from "@angular/router";
import { NotificationService } from "./../../_services/notification.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { Component, OnInit } from "@angular/core";
import { NgClass } from "@angular/common";
import { PspUser } from "../../_models/user.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { switchMap } from "rxjs-compat/operator/switchMap";
import { userInfo } from "os";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  currentUser: PspUser;
  notificationCount = 0;
  name: string;
  refreshSubscription: Subscription;
  browserRefresh: boolean = false;
  currentUserSubscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log(`fromheader`);
    const routerEvents = this.router.events;
    routerEvents
      .filter(
        (event) => event instanceof NavigationStart && !this.router.navigated
      )
      .switchMap(() => this.authenticationService.getUser())
      .subscribe((user) => {
        this.authenticationService.currentUserSubject.next(user);
        console.log(`from refresh`);
      });

    this.authenticationService.currentUserSubject.subscribe(
      (User) => (this.name = User.name.split(" ")[0])
    );

    this.notificationService.notificationCountChanged.subscribe((data) => {
      this.notificationCount = data;
    });

    this.notificationService.getNotificationCount();
  }

  toggleShow() {
    this.show = !this.show;
  }
  toShow() {
    if (this.show) {
      return "show";
    }
  }
  unShow() {
    this.show = false;
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  isAuthenticated() {
    return this.authenticationService.isAuthenticated();
  }

  isAcm() {
    return this.authenticationService.isAcm("");
  }
  isBridgehead() {
    return this.authenticationService.isBridgehead("");
  }
  canViewActionableAlerts() {
    let canView = false;
    let isACM = this.isAcm();
    let isBridgehead = this.isBridgehead();
    if (isACM || isBridgehead) {
      canView = true;
      console.log(`Can view actionable alert: ${canView}`);
    }
    return canView;
  }
  isFromEnerlytics() {
    return this.authenticationService.isFromEnerlytics();
  }

  notificationClass() {
    if (this.notificationCount > 0) {
      return `btn btn-primary, style=background-color:#0078dc`;
    } else {
      return `btn btn-secondary, active style=background-color:#0078dc`;
    }
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }
}
