import { Injectable } from '@angular/core';

@Injectable()
export class FormattingService {

  constructor() { }

  getSeverityColour(severityId: number){

    switch(severityId) {
      case 10: { 
        return "#ff0000";
      } 
      case 20: { 
        return "#ffa500";
      } 
      case 30: { 
        return "#ffff00";
      } 
      case 40: { 
        return "#dbdbdb";
      } 
      case 50: { 
        return "#52f2fa";
      } 
      case 60: { 
        return "#92d050";
      } 
      default: { 
         return "";
      } 
   }
  
}

getStatusColour(statusId: number){

  switch(statusId) {
    // requires feedback
    case 3: { 
      return "#007bff";
    } 
    // feedback received
    case 11: { 
      return "#28a745";
    } 
    // closed
    case 1: { 
      return "#000000";
    } 
    default: { 
       return "#868e96";
    } 
 }

}

}
