import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAny'
})
export class FilterAnyPipe implements PipeTransform {

  transform(values: {}[],filterStr: string): any {
    console.log("filtering any");
    if( values == undefined || filterStr == undefined){
      return values;
    }
    if (values.length === 0 || (filterStr.length === 0 ) ) {
      return values;
  }
  // break the search string into a list of words
  let searchTerms = filterStr.split(" ");
  // console.log(searchTerms)
  const resultArray = [];
  // check each value for all search terms
  for (const value of values) {

    let allMatched = true;
    for (let term of searchTerms) {
      if (
        // we stringify each value so we check the entire contents of the value by default
        JSON.stringify(value).toUpperCase().indexOf(term.toUpperCase()) >= 0

      ) {
        // all terms found - do nothing
      } else {
        // this term was missing. Do not add the alert to the results
        allMatched = false;
        break;
      }
    }
    if (allMatched) {
      resultArray.push(value);
    }

  }
  return resultArray;
}

}
