<div class="row justify-content-center">
  <div class="col-sm-8">

    <h3 i18n>Edit Dashboard</h3>

    <form [formGroup]="dashboardForm">

          <button 
            type="button" 
            class="btn btn-success"
            [disabled]="!dashboardForm.valid"
            (click)="onSubmit()"
            style="cursor: pointer;"
            i18n>Save</button>
          <button 
            type="button" 
            class="btn btn-danger"
            (click)="onCancel()"
           i18n >Cancel</button>

          <div class="form-group">
            <label for="name" i18n>Name</label>
            <input 
              type="text"
              id="name"
              formControlName="name"
              class="form-control">
          </div>

          <div class="form-group">
            <label for="description" i18n>Description</label>
            <textarea 
              type="text"
              id="description"
              class="form-control"
              formControlName="description"
              rows="6">
            </textarea>
          </div>

    </form>
  </div>
</div>