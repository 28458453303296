import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {  JwtHelperService } from '@auth0/angular-jwt';



@Injectable()
export class AuthAdminGuard implements CanActivate {

    constructor(
        private router: Router,
       ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const jwtHelper= new JwtHelperService;
        const userToken = localStorage.getItem('currentUser');
        
        if (userToken) {
            const tokenJson = jwtHelper.decodeToken(userToken);
                              
            if (tokenJson.admin && tokenJson.admin == "True") {
                return true;
            }
               
        }

        // not authorised so redirect to login page with the return url
        this.router.navigate(['/403']);
        return false;
    }
}