<h2>All Users</h2>
<hr>
<form (ngSubmit)="onSubmit()" #filterForm="ngForm" style="max-width: 400px;">
  <div class="input-group mb-3">
    <input type="text" class="form-control" placeholder="Filter users..."
      [(ngModel)]="model.filter" name="filter"
      #filter="ngModel" autocomplete="off">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="submit">
        <i class="fa fa-filter"></i> Filter
      </button>
    </div>
  </div>
</form>
<p>{{users.length}} total users | <span class="text-muted" *ngIf="!filtered || !appliedFilter">No filter applied</span> <span [hidden]="!appliedFilter || !filtered"><span class="text-muted">Filter:</span> {{appliedFilter}}</span> </p>

<div class="container-fluid">
  <div *ngIf="loading">
    <p class="text-center mt-5">
      <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
    </p>
  </div>
  <div class="table-responsive" *ngIf="!loading">

      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th scope="col">              
              <button class="btn btn-link" (click)="sortBy('intId')"><i class="fa fa-sort"></i> IntID</button>
            </th>
            <th scope="col">
              <button class="btn btn-link" (click)="sortBy('userId')"><i class="fa fa-sort"></i> UserID</button>
            </th>
            <th scope="col">
                <button class="btn btn-link" (click)="sortBy('username')"><i class="fa fa-sort"></i> Username</button>
            </th>
            <th scope="col">
              <button class="btn btn-link" (click)="sortBy('name')"><i class="fa fa-sort"></i> Name</button>
            </th>
            <th scope="col">
              <button class="btn btn-link" (click)="sortBy('email')"><i class="fa fa-sort"></i> Email</button>
            </th>
            <th scope="col">
              <button class="btn btn-link" (click)="sortBy('enabled')"><i class="fa fa-sort"></i> Enabled</button>
            </th>
            <th scope="col">
              <button class="btn btn-link" (click)="sortBy('lastLogin')"><i class="fa fa-sort"></i> Last Login</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users" style="cursor: pointer;" [routerLink]="['/Admin', 'EditUser', user.intId]">
            <td>{{user.intId}}</td>
            <td>{{user.userId}}</td>
            <td>{{user.username}}</td>
            <td>{{user.name}}</td>
            <td>{{user.email}}</td>
            <td>
              <i class="fa fa-check text-success" *ngIf="user.enabled"></i>
              <i class="fa fa-times text-danger" *ngIf="!user.enabled"></i>
            </td>
            <td>{{user.lastLogin | date:'yyyy-MM-dd HH:mm'}}</td>
          </tr>
        </tbody>

      </table>

  </div>
  <!-- <div class="row" *ngFor="let user of users">
    <div class="col-lg-6">



      <table style="width: 100%;">
        <tr>
          <td class="text-muted col50">
            Name
          </td>
          <td class="pl-5 col50">
            {{user.name}}
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
            Email
          </td>
          <td class="pl-5 col50">
            {{user.email}}
          </td>
        </tr>
        <tr>
          <td class="text-muted col50 ">
              Int ID
          </td>
          <td class="pl-5 col50">
            {{user.userId}}
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
              User ID
          </td>
          <td class="pl-5 col50">
            abc
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
              Enabled
          </td>
          <td class="pl-5 col50">
            True
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-6">
      <table style="width: 100%;">
        <tr>
          <td class="text-muted col50 ">
              Password set
          </td>
          <td class="pl-5 col50">
            123
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
              Must change password
          </td>
          <td class="pl-5 col50">
            abc
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
            Password last changed
          </td>
          <td class="pl-5 col50">
            John Smith
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
            Failed logins
          </td>
          <td class="pl-5 col50">
            john.smith@unper.energy
          </td>
        </tr>
        <tr>
          <td class="text-muted col50">
            Last logged in
          </td>
          <td class="pl-5 col50">
            True
          </td>
        </tr>
      </table>
    </div>

    <hr>

  </div> -->
</div>