
<div class="text-center mt-5" *ngIf="loading; else normal">
  <h2 i18n>Loading Report</h2>
  <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
</div>

<ng-template #normal>
  <div class="row justify-content-center">
    <div class="col-md-6">

      <div class="mt-5">

        <h4 *ngIf="report.createdBy != null" i18n>
          <i class="fa fa-file-text" aria-hidden="true"></i> 
           Report for 
          <b>{{ report.utcFrom | date:'mediumDate'}}</b> to 
          <b>{{ report.utcTo | date:'mediumDate'}}</b>
        </h4>
        <button *ngIf="canDelete()" [disabled]="working" (click)="onDelete()" type="button" class="btn btn-danger" i18n>
          <i class="fa fa-trash-o" aria-hidden="true"></i> Delete Report
        </button>
                
        <hr>

        <h5 *ngIf="report.createdBy != null" i18n>
          <i class="fa fa-history" aria-hidden="true"></i> Created
          <b>{{ report.utcCreated | date:'mediumDate'}}</b> by
          <span [innerHTML]="report.createdBy | pspUser"></span>
        </h5>

        <h5 *ngIf="report.approvedBy != null" i18n>
          <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> Approved
          <b>{{ report.utcApproved | date:'mediumDate'}}</b> by
          <span [innerHTML]="report.approvedBy | pspUser"></span>
        </h5>

        <div *ngIf="isAcm()">
                    
          <button [disabled]="working" (click)="sendForApproval()"  *ngIf="canSendForApproval()" type="button" class="btn btn-primary">
            <i class="fa fa-check-square-o" aria-hidden="true"></i> {{sendApproveText}}
          </button>

          <div *ngIf="canApprove()">
            
            <button [disabled]="working" (click)="sendForApproval()"  *ngIf="canSendForApproval()" type="button" class="btn btn-primary">
                <i class="fa fa-check-square-o" aria-hidden="true"></i> {{sendApproveText}}
            </button>
            <button [disabled]="working" (click)="onApprove(true)" type="button" class="btn btn-success">
              <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> {{approveSendText}}
            </button>
            <button [disabled]="working" (click)="onApprove(false)" type="button" class="btn btn-success">
              <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> {{approveText}}
            </button>
            <button [disabled]="working" (click)="onReject()" type="button" class="btn btn-danger">
              <i class="fa fa-thumbs-o-down" aria-hidden="true"></i> {{rejectText}}
            </button>

          </div>

        </div>

        <h5 *ngIf="report.sentBy != null" i18n>
          <i class="fa fa-envelope-o" aria-hidden="true" ></i> Last Sent
          <b>{{ report.utcSent | date:'mediumDate'}}</b> by
          <span [innerHTML]="report.sentBy | pspUser"></span>
        </h5>
        <div *ngIf="isAcm()">
          <button [disabled]="working" (click)="onSend()" *ngIf="canSendReport()" type="button" class="btn btn-primary">
            <i class="fa fa-envelope-o" aria-hidden="true"></i> {{sendText}}
          </button>
        </div>
      </div>

      <hr>

    <!-- Pie chart and KPIs  -->
    <div class="row">
      
      <div class="col-sm-6">
        <chart [options]="optionsSeverity" (load)="saveInstanceSeverity($event.context)">
        </chart>
      </div>
      <div class="col-sm-6">
        <chart [options]="optionsStatus" (load)="saveInstanceStatus($event.context)">
        </chart>
      </div>
      <!-- <div class="col-sm-6 text-center">

          <h4 class="mt-4">
            <span class="badge badge-primary">{{feedbackReqStr}}</span>
          </h4>
            
          <h4 class="mt-4">
            <span class="badge badge-secondary">{{acknowledgedStr}}</span>
          </h4>
    
          <h4 class="mt-4">
            <span class="badge" style="background-color: black; color:white">{{closedStr}}</span>
          </h4>
      </div> -->
      
    </div>
      
      <div class="mt-5">

        <div class="btn-group" ngbRadioGroup name="radioBasic" [(ngModel)]="alertmode" (ngModelChange)="alertModeChanged()">
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="allalerts" i18n>All alerts <i class="fa fa-users" aria-hidden="true"></i>
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="myalerts" i18n><i class="fa fa-user" aria-hidden="true"></i> My alerts
          </label>
        </div>

        <h4 i18n>{{report.alerts.length}} alerts</h4>

        <div *ngIf="inEditMode()" class="mb-1">
          <button [disabled]="working" (click)="refreshAlerts()" type="button" class="btn btn-primary" i18n>
            <i class="fa fa-refresh" aria-hidden="true"></i> Update alerts
          </button>
          <button [disabled]="working" (click)="clearAllAlertsAndReload()" type="button" class="btn btn-outline-primary" i18n>
            <i class="fa fa-retweet" aria-hidden="true"></i> Rebuild report
          </button>
        </div>


        <form>
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="filterStr" name="filterStr" placeholder="Filter alerts">
          </div>
        </form>

      </div>
    </div>

  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">

      <div class="btn-group pull-right" ngbRadioGroup name="radioBasic" [(ngModel)]="displayMode">
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="detailed" i18n>Detailed View <i class="fa fa-expand" aria-hidden="true"></i>
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="short" i18n><i class="fa fa-compress" aria-hidden="true"></i> Short View
        </label>
      </div>
    </div>
  </div>


  <div class="row justify-content-center mb-5">
    <div class="col-md-6">
      <!-- <div *ngIf="displayMode=='detailed'" class="list-group">
        <app-alert-item *ngFor="let alertElement of report.alerts | filterAlerts:filterStr:selectedStatus | sortAlerts:'utcModified'"
          [alert]="alertElement">Test</app-alert-item>
      </div> -->

      <div class="list-group">
        <app-report-alert-item-short *ngFor="let alertElement of report.alerts | filterAlerts:filterStr:selectedStatus | sortAlerts:'utcModified'"
          [alert]="alertElement" [mode]="displayMode" [editMode]="inEditMode()">Test</app-report-alert-item-short>
      </div>

    </div>
  </div>

</ng-template>
