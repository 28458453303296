

        <b>For alerts...</b><br>

        <i class="fa fa-industry" aria-hidden="true"></i> Units: 
        <b [ngClass]="colorMap[site.selectedUnitsDesc]">{{site.selectedUnitsDesc}} </b>
        <br>

        <i class="fa fa-cogs" aria-hidden="true"> </i> Areas: 
        <b [ngClass]="colorMap[site.selectedAreasDesc]">{{site.selectedAreasDesc}}</b>
        <br>

        <i class="fa fa-cubes" aria-hidden="true"></i> Items: 
        <b [ngClass]="colorMap[site.selectedAnalysisDesc]">{{site.selectedAnalysisDesc}}</b>

      