import { ActivatedRoute, Router, Params } from '@angular/router';
import { ReportsService } from './../../_services/reports.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {

  modelFrom = {};
  createText = "Create";
  siteId;
  working = false;

  dateSetStr = "Loading date from database...";

  nowDate = Date.now();

  constructor(
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.modelFrom['year'] = (new Date().getFullYear());
    this.modelFrom['month'] = (new Date().getMonth() + 1);
    this.modelFrom['day'] = (new Date().getDate());


    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId'];
        this.getLastReportEndDate();
      }
    );

  }

  getLastReportEndDate(){
    this.reportsService.getLastReportDate(this.siteId)
      .subscribe(
        (data) => {
          this.dateSetStr = "Date-from loaded from most recent report in database."
          console.log("last report date = " + data['date']);
          // this.pickedDate = data['date'];
          this.setPickerDate(data['date']);
        }, 
        error => {
          this.dateSetStr = "Could not get date from database. Date-from set to now."          
        }
      )
    
  }

  setPickerDate(dateStr: string){
    let aDate = new Date(dateStr);
    this.modelFrom['year'] = aDate.getFullYear();
    this.modelFrom['month'] = aDate.getMonth() + 1;
    this.modelFrom['day'] = aDate.getDate();
  }

  pickedDate() {
    let aDate = new Date();
    if (this.modelFrom != null){
      aDate.setFullYear(this.modelFrom['year'], this.modelFrom['month'] - 1, this.modelFrom['day']);
    }
    return aDate;
  }

  createReport(){
    this.working = true;
    this.createText = "Creating..."
    this.reportsService.createReport(this.siteId, this.pickedDate())
      .subscribe(
        data => {
          this.router.navigate(['../',data['hashId']], {relativeTo: this.route})
        },
        error => {
          this.createText = "Failed"
        }
      )
  }

}
