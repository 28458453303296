import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PspUser } from './../_models/user.model';
import { ChartSeries } from './../_models/series.model';
import { ConnStrModule } from './conn-str.module';
import { SignalData } from './../_models/signal-data.model';
import { Signal } from './../_models/signal.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Dashboard } from './../_models/dashboard.model';
import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
import { Chart } from '../_models/chart.model';
import 'rxjs/add/observable/of';

@Injectable()
export class DashboardsService {

  collapseSidebar = false;
  sidebarChanged = new Subject < boolean > ();


  dashboardsChanged = new Subject < Dashboard[] > ();
  dashboardChanged = new Subject < Dashboard > ();
  folderedDashboardsChanged = new Subject < {} > ();

  chartChanged = new Subject < Chart > ();
  dashboardsDatesChanged = new Subject<{}>();
  
  signalChanged = new Subject<{}>();

  sourceListChanged = new Subject<{}>();
  signalListChanged = new Subject<Signal[]>();

  clearAllSeries = new Subject<{}>();
  
  // selectedSeriesListChanged = new Subject<Series[]>();  

  chart = new Chart();
  signalData = new Array<{}>();

  private dashboards: Dashboard[] = new Array < Dashboard > ();
  private dashboard: Dashboard;

  
  private folderedDashboards;

  private searchableSignals: Signal[] = new Array<Signal>();

  private dateFrom = new Date();
  private dateTo = new Date();



  constructor(
    private httpClient: HttpClient,
    private connStr: ConnStrModule) {}


  toggleSidebar() {
    this.collapseSidebar = !this.collapseSidebar;
    this.sidebarChanged.next(this.collapseSidebar);
  }

  hideSidebar() {
    this.collapseSidebar = true;
    this.sidebarChanged.next(this.collapseSidebar);
  }
  showSidebar() {
    this.collapseSidebar = false;
    this.sidebarChanged.next(this.collapseSidebar);
  }

  updateDates(from: Date, to: Date){
    console.log("setting dash service dates = " + from + " - " + to);
    this.dateFrom = from;
    this.dateTo = to;
    this.dashboardsDatesChanged.next({
      'from': this.dateFrom,
      'to': this.dateTo
    })
  }

  moveDashboard(path: string, dashId: string){
    console.log("Moving dashboard: " + dashId)
    let dashToMove = this.pullDashboardById(dashId);
    console.log("found dashboard:")
    console.log(dashToMove)
    let folder = this.findFolder(path);
    if (folder['items'] == null){
      folder['items'] = [];
    }
    folder['items'].push(dashToMove);

    this.saveFolderStructure();
    this.folderedDashboardsChanged.next(this.folderedDashboards);  
  }
  
  findFolder(path: string){
    console.log("findFolder: " + path)
    var foldersStr = path.split("/");
    console.log(foldersStr)
    var foundFolder = this.folderedDashboards;
    console.log(foundFolder)
    foldersStr.forEach(aFolderStr => {
      if (aFolderStr == ''){
        console.log("path is empty, skipping");
        return;
      }
      foundFolder = foundFolder['folders'].find(name => name['name'] == aFolderStr);
      if (foundFolder == null){
        console.log("folder " + aFolderStr + " not found" )
        return;
      }
      console.log("entering directory");
      console.log(foundFolder)
    })
    if (foundFolder == null){
      return;
    }
    if (foundFolder['folders'] == null){
      foundFolder['folders'] = [];
    }
    return foundFolder;
  }

  saveFolderStructure(){
    return this.httpClient.put<{}>(this.connStr.pspBaseUrl + 'dashboards/folder', this.folderedDashboards)
    .map((response) => {
      return response;
    })
    .subscribe(data => {
      console.log("folder structure saved");      
    })
  }

  dashboardList(){
    return this.getFolderDashboards(this.folderedDashboards);
  }

  pullDashboardById(dashId: string){
    let foundDash = null;
    foundDash = this.pullDashboard(this.folderedDashboards, dashId, foundDash);
    return foundDash;
  }

  pullDashboard(aFolder: {}, dashId: string, dash: {}){
    if( dash != null){
      // we already found the dashboard, do nothing
      return dash;
    }
    if (aFolder == null){
      // the folder object is null
      console.log("folder object is null");
      return;
    }
    if (aFolder['items'] != null){
      console.log("folder has dashboards, searching...")
      console.log(aFolder['items'])
      // if this folder has dashboards, search them
      dash = aFolder['items'].find(x => x['dashboardId'] == dashId);
      if (dash != null){
        console.log("dashboard not null")
        console.log(dash)
        // if a dashboard is found, remove it
        var index = aFolder['items'].indexOf(dash);
        aFolder['items'].splice(index, 1);
      }
    }
    // if the dashboard is not found, recursively search the next level
    if (dash == null && aFolder['folders'] != null && aFolder['folders'].length > 0){
      aFolder['folders'].forEach(nestedFolder => {
        dash = this.pullDashboard(nestedFolder,dashId, dash);
      });
    }
    return dash;
  }

  getFolderDashboards(aFolder: {}){
    let listOfDashboards = [];
    // add the dashboards in this folder
    aFolder['items'].forEach(dashElement => {
      listOfDashboards.push(dashElement);
    });
    // for each folder, recursively add the dashboards
    aFolder['folders'].forEach(folderElement => {
      let dashIteration =  this.getFolderDashboards(folderElement);
      dashIteration.forEach(aDash => {
        listOfDashboards.push(aDash);
      });
    });
    return listOfDashboards
  }


  addFolder(path: string, folderName: string){
    let foundFolder = this.findFolder(path);
    if (foundFolder == null){
      return;
    }
    if (foundFolder['folders'] == null){
      foundFolder['folders'] = [];
    }
    foundFolder['folders'].push(
      {
        "name": folderName,
        "items": [],
        "folders":[]
      }
    );
    this.saveFolderStructure();
    this.folderedDashboardsChanged.next(this.folderedDashboards);
  }

  editFolder(path: string, oldName: string, newName: string){
    path = path + '/' + oldName;
    let foundFolder = this.findFolder(path);
    if (foundFolder == null){
      return;
    }
    if (foundFolder['folders'] == null){
      foundFolder['folders'] = [];
    }
    foundFolder['name'] = newName;
    this.saveFolderStructure();
    this.folderedDashboardsChanged.next(this.folderedDashboards);
  }
  loadDashboards() {
    console.log("loadDashboards()")

    return this.httpClient.get < Dashboard[] > (this.connStr.pspBaseUrl + 'dashboards')
      .map((response) => {
        return response;
      })
      .subscribe(
        data => {
          console.log("Dashboards got")
          this.dashboards = data;
          if (this.dashboards == null) {
            this.dashboards = new Array<Dashboard>();
          }
          this.dashboardsChanged.next(this.dashboards.slice());
        },
        error => {
          this.dashboardsChanged.error("Error loading list of dashboards");
          console.log(error)
        }
      )
  }

  loadStructuredDashboards() {
    console.log("loadStructuredDashboards()")

    return this.httpClient.get < {} > (this.connStr.pspBaseUrl + 'dashboards/structured')
      .map((response) => {
        return response;
      })
      .subscribe(
        data => {
          console.log("Foldered dashboards got");
          console.log(data);
          this.folderedDashboards = data;
          if (this.folderedDashboards == null) {
            this.folderedDashboards = new Array<Dashboard>();
          }
          this.folderedDashboardsChanged.next(this.folderedDashboards);
        },
        error => {
          this.folderedDashboardsChanged.error("Error loading list of foldered dashboards");
          console.log(error);
        }
      )
  }

  private loadDashboard(dashboardId: string) {
    console.log("loadDashboard(" + dashboardId + ")")
    
    this.hideSidebar();
    return this.httpClient.get<Dashboard>(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId)
      .map((response) => {
        return response;
      })
      .subscribe(
        data => {
        console.log("Dashboard got from api");
            this.dashboard = data;
            this.dashboardChanged.next(this.dashboard);
        },
        error => {
          this.dashboardChanged.error(error);
        }
      )
  }


  getDashboard(dashboardId: string) {
    return this.dashboards.find(x => x.dashboardId == dashboardId);
  }

  getFullDashboard(dashboardId: string){
    if (this.dashboard != null && this.dashboard.dashboardId == dashboardId){
      this.dashboardChanged.next(this.dashboard);      
    } else {
      this.loadDashboard(dashboardId);
    }
  }

  requestData(){
    // clear all the currently cached data
    // emit an event so the charts update their series
    this.signalData = [];
    this.clearAllSeries.next();
    
    // for each chart, request new data
    this.dashboard.charts.forEach(
      aChart => {
        aChart.series.forEach(
          series => {
            this.getSignalData(series.sourceType, series.sourceId, series.idAtSource);
          }
        )
      }
    )
  }

  addDashboard(dashboard: Dashboard) {
    console.log("addDashboard()")

    return this.httpClient.post<Dashboard>(this.connStr.pspBaseUrl + 'dashboards/', dashboard)
      .map((response) => {
        return response;
      })
      .subscribe(data => {
        console.log("added dashboard");
        console.log(data);
        if (this.dashboards == null) {
          this.dashboards = new Array < Dashboard > ();
        }
        this.showSidebar();
        this.dashboards.push(data);
        this.dashboardsChanged.next(this.dashboards.slice());
      })
  }

  addChart(dashboardId: string, newChart: Chart){
    console.log("addChart()")

    return this.httpClient.post < Chart > (this.connStr.pspBaseUrl + 'dashboards/' + dashboardId + '/charts', newChart)
      .map((response) => {
        return response;
      })
      .subscribe(data => {
        console.log("added chart");
        console.log(data);
        this.loadDashboard(dashboardId);
        this.dashboardChanged.next(this.dashboard);
      })
  }

  updateChart(chartId: string, chart: Chart){
    console.log("updateChart(" + chartId + ")")
    
        return this.httpClient.put<Chart>(this.connStr.pspBaseUrl + 'dashboards/' + this.dashboard.dashboardId + '/charts/' + chartId, chart)
          .map((response) => {
            return response;
          })
          .subscribe(data => {
            console.log("updated chart");
            console.log(data);
            var index = this.dashboard.charts.findIndex(x => x.chartId == chartId);
            if (index > -1) {
              this.dashboard.charts[index] = data;
              this.dashboardChanged.next(this.dashboard);
            }
          })
  }

  updateDashboard(dashboardId: string, dashboard: Dashboard) {
    console.log("updateDashboard(" + dashboard.dashboardId + ")")

    return this.httpClient.put<Dashboard>(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId, dashboard)
      .map((response) => {
        return response;
      })
      .subscribe(data => {
        console.log("updated dashboard");
        console.log(data);
        var index = this.dashboards.findIndex(x => x.dashboardId == dashboardId);
        if (index > -1) {
          this.dashboards[index] = data;
          this.dashboardsChanged.next(this.dashboards.slice());
          // change the main settings of this dashboard
          this.dashboard.name = dashboard.name;
          this.dashboard.description = dashboard.description;
          this.dashboardChanged.next(this.dashboard);
          this.showSidebar();          
        }
      })
  }

  // loadSources(): Observable<Array<{}>> {
  //   return Observable.of(
  //     [
  //       {
  //         sourceType: 'PI',
  //         sourceId: 'CQ'
  //       },
  //       {
  //         sourceType: 'PI',
  //         sourceId: 'RATS'
  //       },
  //       {
  //         sourceType: 'PI',
  //         sourceId: 'GRCHP'
  //       },
  //       {
  //         sourceType: 'RND',
  //         sourceId: 'Sample'
  //       }
  //     ]
  //   );
  //   // return this.httpClient.post < {}[] > (this.connStr.universeDataUrl + 'dataseries/' + dataSource + '/PIdata', tagNames)
  //   //   .map((response) => {
  //   //     return response;
  //   //   })
  // }

  loadSources() {
    return this.httpClient.get<{}[]> (this.connStr.universeDataUrl + 'dataseries/sources')
      .map((response) => {
        return response;
      })
  }

  getSignalData(sourceType: string, dataSource: string, idAtSource: string){
    // sourceType e.g. PI
    // dataSource e.g. Rats
    // idAtSource i.e. the unique identifier at the source

    console.log("getSignalData()");
    console.log(sourceType);
    if(sourceType.toUpperCase() == 'RND'){
      this.getTestSignalData(idAtSource);
      return;
    };
    if(sourceType.toUpperCase() == 'PI'){
      this.getPiData(dataSource, idAtSource);
      return;
    }
    console.log("Unknown source type requested");
  }
  

  getPiData(sourceId: string, idAtSource: string){
    console.log("getPiData()");
    let tagNames = [idAtSource];
    console.log(sourceId)
    console.log(idAtSource)

    let myParams = new HttpParams();  

    let toStr = this.dateTo.toISOString().replace(/T/g," ");
    toStr = toStr.replace(/Z/g,"")

    let fromStr = this.dateFrom.toISOString().replace(/T/g," ");
    fromStr = fromStr.replace(/Z/g,"")
    
    myParams = myParams.append('from', fromStr);
    myParams = myParams.append('to', toStr);

    return this.httpClient.post <{}> (this.connStr.universeDataUrl + 'dataseries/' + sourceId.toUpperCase() + '/PIdata', tagNames, {params: myParams})
      .map((response) => {
        return response;
      })
      .subscribe(
        data => {
          console.log("Got Pi Data - " + idAtSource);
          console.log(data);
          for (var iTag = 0, lenTag = data['piTagList'].length; iTag < lenTag; iTag++) {

            let returnedTagData = data['piTagList'][iTag];

            // if the tag id is in our list of tags, 
            //  and if the date range reqested matches the dates we've requested
            //  then this must be a valid request returned.
            //   pull the points out of the returned data and emitt an event 

            this.dashboard.charts.forEach(chartElement => {
              chartElement.series.forEach(seriesElement => {
                if (
                  seriesElement.sourceType.toLowerCase() == "pi" 
                  && seriesElement.sourceId.toLowerCase() == sourceId.toLowerCase() 
                  && seriesElement.idAtSource.toLowerCase() == idAtSource.toLowerCase()
                ) {
                  console.log("Matching series found");
                  console.log(seriesElement);
                  // this is the source - we need to pull the points out
                  let newSignalData = {};
                  newSignalData['id'] = seriesElement.sourceId + "_" + idAtSource
                  newSignalData['idAtSource'] = idAtSource;
                  newSignalData['data'] = [];


                  let values = returnedTagData['piTagData'];
                  for (var iPoint = 0, lenVals = values.length; iPoint < lenVals; iPoint++) {
                    let pointElement = values[iPoint];
      
                    let dateString = pointElement['dateTime'];      
                    if(!dateString.includes("Z")){
                      dateString = dateString + "Z";
                    }
                    newSignalData['data'].push(
                      [Date.parse(dateString),parseFloat(pointElement['tagValue'])]
                    )
                    
                    // // MAX
                    // let maxDateString = pointElement['dateOfMax'];      
                    // if(!maxDateString.includes("Z")){
                    //   maxDateString = maxDateString + "Z";
                    // }
                    // let maxDate = Date.parse(maxDateString)
                    
                    // // MIN
                    // let minDateString = pointElement['dateOfMin'];      
                    // if(!minDateString.includes("Z")){
                    //   minDateString = minDateString + "Z";
                    // }
                    // let minDate = Date.parse(minDateString)

                    
                    // if (maxDate < minDate) {
                    //   newSignalData['data'].push(
                    //     [maxDate,parseFloat(pointElement['max'])]
                    //   )
                    //   newSignalData['data'].push(
                    //     [minDate,parseFloat(pointElement['min'])]
                    //   )
                    // } else {
                    //   newSignalData['data'].push(
                    //     [minDate,parseFloat(pointElement['min'])]
                    //   )
                    //   newSignalData['data'].push(
                    //     [maxDate,parseFloat(pointElement['max'])]
                    //   )
                    // }

                    
                  };
                  newSignalData['name'] = seriesElement.name;
                  newSignalData["tooltip"] = {
                    valueDecimals: 1,
                    valueSuffix: returnedTagData['engUnits']
                  };
                  
                  console.log("emitting pi signal data");
                  this.signalChanged.next(newSignalData);
                  return;
                  // BREAK OUT 
                }
              });
            });
                          
          };
      })
    }

  getTestSignalData(signalId: string) {
    console.log("getTestSignalData(" + signalId + ")")

    
    let myParams = new HttpParams();  

    let toStr = this.dateTo.toISOString().replace(/T/g," ");
    toStr = toStr.replace(/Z/g,"")

    let fromStr = this.dateFrom.toISOString().replace(/T/g," ");
    fromStr = fromStr.replace(/Z/g,"")
    
    myParams = myParams.append('from', fromStr);
    myParams = myParams.append('to', toStr);


    return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'dashboards/signals/' + signalId + "/data", {params: myParams})
      .map((response) => {
        return response;
      })
      .subscribe(data => {
        console.log("Sample data returned for id " + signalId);
        console.log(data)
          // define the signal returned
          
          let newSignal = {};
          newSignal['id'] = "Sample_" + signalId
          newSignal['sourceId'] = "Sample";
          newSignal['idAtSource'] = signalId;
          newSignal['name'] = "Random walk"
          newSignal['data'] = [];
          data.forEach(datapoint => {
            newSignal['data'].push(
              [Date.parse(datapoint['dateTime']),parseFloat(datapoint['value'])]
            )            
          });
          newSignal["tooltip"] = {
            valueDecimals: 2,
            valueSuffix: ''
          };
          // update the signals array, 
          // and push out a signal update

          // if the signal exists...
          // let signalFound: boolean = false;
          // for (let existingSignal of this.signals ) {
          //   if (existingSignal.signalId === existingSignal.signalId ) {
          //     console.log("Signal exists. Updating signal.");
          //     existingSignal.data = data;
          //     signalFound = true;
          //   }
          // }
          // // if the signal doesn't exist...
          // if (!signalFound) {
          //     console.log("Signal does not exist. Adding new signal.");
          //     let newSignal = new Signal;
          //     newSignal.signalId = signalId;
          //     newSignal.data = data;
          //   this.signals.push(newSignal);
          // }

          // push update 
          this.signalChanged.next(newSignal);
          
      })
  }

  removeFolder(path: string, folderName: string){
    console.log("removing folder " + path + '/' + folderName)
    let theDirectory = this.findFolder(path);
    let index = theDirectory['folders'].findIndex(x => x['name'] == folderName)
    if (index > -1) {
      let itemsToMove = theDirectory['folders'][index]['items'];
      if (itemsToMove != null) {
        console.log("moving dashboards to main list")
        itemsToMove.forEach(itemElement => {
          this.folderedDashboards['items'].push(itemElement);
        });
      }      
      theDirectory['folders'].splice(index, 1);
      this.saveFolderStructure();
      this.folderedDashboardsChanged.next(this.folderedDashboards);
    } else{
      console.log("folder not found")
    }
  }

  deleteDashboard(dashboardId: string) {
    console.log("deleteDashboard(" + dashboardId + ")")

    return this.httpClient.delete(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId, {
        responseType: 'text'
      })
      .map((response) => {
        console.log("deleteDashboard response")
        console.log(response)
        return response;
      })
      .subscribe(data => {
        console.log("deleteDashboard data");
        console.log(data);
        var index = this.dashboards.findIndex(x => x.dashboardId == dashboardId);
        if (index > -1) {
          this.dashboards.splice(index, 1);
          this.dashboardsChanged.next(this.dashboards.slice());
        }
        this.showSidebar();
      })
  }
  deleteChart(chartId: string) {
    console.log("deleteChart(" + chartId + ") dashboard = " + this.dashboard.dashboardId)

    return this.httpClient.delete(this.connStr.pspBaseUrl + 'dashboards/' + this.dashboard.dashboardId + '/charts/' + chartId, {
        responseType: 'text'
      })
      .map((response) => {
        console.log("deleteChart response")
        console.log(response)
        return response;
      })
      .subscribe(data => {
        console.log("deleteChart data");
        console.log(data);
        var indexC = this.dashboard.charts.findIndex(x => x.chartId == chartId);
        if (indexC > -1) {
          console.log("removing chart at index " + indexC);
          this.dashboard.charts.splice(indexC, 1);
          this.dashboardChanged.next(this.dashboard);
        }
      })
  }

  loadSignalList(sourceType: string, sourceId: string){
    console.log('Loading signals')
    console.log('sourceType = ' + sourceType + ' sourceId = ' + sourceId);
    this.searchableSignals = [];
    if (sourceType.toUpperCase() == 'RND'){
      console.log('Loading test signals')
      
          return this.httpClient.get < Signal[] > (this.connStr.pspBaseUrl + 'dashboards/signals')
            .map((response) => {
              return response;
            })
            .subscribe(data => {
              console.log("Signals got")
                // console.log(data);
                this.searchableSignals = data;
                this.signalListChanged.next(this.searchableSignals);
            })
    }
    else if(sourceType.toUpperCase() == 'PI')
    {
      console.log('Loading ' + sourceId + ' signals')
      
          return this.httpClient.get < {}[] > (this.connStr.universeDataUrl + 'dataseries/' + sourceId + '/allPItags')
            .map((response) => {
              return response;
            })
            .subscribe(data => {
              console.log("Signals got")
              console.log(data);
                // console.log(data);
                let mySignals = new Array<Signal>();
                data.forEach(tagElement => {
                  let aSignal = new Signal();
                  aSignal.dataId = tagElement['tagName'];
                  aSignal.name = tagElement['tagName'];
                  aSignal.description = tagElement['tagDescription'];
                  aSignal.measuredIn = tagElement['engUnits'];
                  mySignals.push(aSignal);
                });
                this.searchableSignals = mySignals;
                this.signalListChanged.next(this.searchableSignals);
            })
    }
  }

  getChart(chartId: string) {
    if (this.dashboard != null){
      this.chart = this.dashboard.charts.find(x => x.chartId == chartId);
      this.chartChanged.next(this.chart);
    }
  }

  sendDashboardInvite(dashboardId: string, email: string, accessType: string) {
    console.log("sendDashboardInvite()")

    let invitation = {
      'email': email,
      'type': accessType
    }
    return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId + '/invite', invitation)
      .map((response) => {
        return response;
      })
      
  }

  acceptDashboardInvite(inviteId: string) {
    console.log("acceptDashboardInvite()")

    return this.httpClient.get<{}>(this.connStr.pspBaseUrl + 'dashboards/invite/' + inviteId)
      .map((response) => {
        return response;
      })      
  }

  addDashboardComment(dashboardId: string, comment: string){
    console.log("addDashboardComment()");
    
    let newComment = {
      'comment': comment
    }

    return this.httpClient.post<{}>(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId + '/comments/add', newComment)
      .map((response) => {
        return response;
      })
  }

  getDashboardComments(dashboardId: string){
    console.log("getDashboardComments()");
        return this.httpClient.get<{}[]>(this.connStr.pspBaseUrl + 'dashboards/' + dashboardId + '/comments')
      .map((response) => {
        return response;
      })
  }
  
  addSignalClick(sourceType: string, sourceId: string, idAtSource: string, name: string){
    console.log("signal received by dashboard service");
   
    var index = this.chart.series.findIndex(x => x.sourceType == sourceType && x.sourceId == sourceId && x.idAtSource == idAtSource)

    if (index > -1) {
      console.log("series already added");
    } else {

      let aSeries = new ChartSeries();
      aSeries.name = name
      aSeries.sourceType = sourceType
      aSeries.sourceId = sourceId
      aSeries.idAtSource = idAtSource
            
      this.chart.series.push(aSeries);
      this.chartChanged.next(this.chart);
      console.log(this.chart.series);

    }
  }

  removeSeries(sourceType: string, sourceId: string, idAtSource: string) {
    console.log("attempting to remove series");
    var index = this.chart.series.findIndex(x => x.sourceId == sourceId && x.sourceType == sourceType && x.idAtSource == idAtSource)
    if (index > -1) {
      this.chart.series.splice(index, 1);
      console.log("removed series");
      console.log(this.chart.series)
      this.chartChanged.next(this.chart);
    }
  }

  updateSeries(dashboardId: string, chartId: string, chartSeries: {}[]) {
    console.log("updateSeries()")

    return this.httpClient.put < ChartSeries[] > (this.connStr.pspBaseUrl + 'dashboards/' + dashboardId + '/charts/' + chartId + '/series', chartSeries)
      .map((response) => {
        return response;
      })
      .subscribe(data => {
        console.log("Updated series");
        console.log(data);
        this.chart.series = data;
        this.chartChanged.next(this.chart);
      },
      error => {
        this.chartChanged.error(error);
      })
  }
}
