<div class="row justify-content-center">
  <div class="col text-center">
        
    <div class="m-5">

      <div [hidden]="!loading">
        <!-- <i class="fa fa-circle-o-notch fa-spin fa-5x"></i><br><br> -->
        <app-loading-pulse></app-loading-pulse><br><br>
        Authenticating...
      </div>

      <div [hidden]="!failed" class="text-danger">
        <p>
          <b>Something went wrong</b><br>
          <a routerLink="/login">Please try again</a>
        </p>
        <small>If this problem persists, <br>please <a href='mailto:support@productionsupportportal.com?subject=Production support request'>get in touch</a></small>
      </div>

      <div [hidden]="!tokenGot">
        <p>
          <b>Logged in</b><br>
          <!-- <i class="fa fa-circle-o-notch fa-spin fa-5x"></i><br> -->
          <app-loading-pulse></app-loading-pulse><br>
          Loading data...
        </p>
      </div>

      <div [hidden]="!completed">
        <p>
        <b>Completed</b><br>
        <!-- <i class="fa fa-circle-o-notch fa-spin fa-5x"></i><br> -->
        <app-loading-pulse></app-loading-pulse><br>
        Redirecting...
      </p>
      </div>

    </div>

  </div>
</div>