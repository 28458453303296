import { Injectable } from "@angular/core";

export class PspUser {
  constructor(
    public userId: number,
    public authId: string,
    public name: string,
    public email: string
  ) {}

  // constructor(userStr: string){
  //     this.userId = 123;
  //     this.authId = 'Auth-' + userStr;
  //     this.name = userStr;
  //     this.email = userStr + '@email.com'
  // }
}
