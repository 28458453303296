import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-reports',
  templateUrl: './site-reports.component.html',
  styleUrls: ['./site-reports.component.css']
})
export class SiteReportsComponent implements OnInit {

  @Input() siteReports;
  
  constructor() { }

  ngOnInit() {
  }

}
