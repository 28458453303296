<!-- Main jumbotron for a primary marketing message or call to action -->

<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-3 text-center">
        <h1><span style="color:#ec98a6; font-size:400%"><i class="fa fa-heart"></i></span></h1>
        <i class="text-muted">Happy valentines day</i>
    </div> -->
      <!-- <div class="col-md-9 mt-3"> -->
      <div class="col">
        <div class="row">
          <div class="col-lg-6">
            <h1 class="d-none d-xl-block display-3">
              Predictive Maintenance Hub
            </h1>
            <h1 class="d-xl-none">Predictive Maintenance Hub</h1>
            <p i18n>
              Welcome {{name}} to the new home of
              Production Solutions.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="float-right" *ngIf="videoUrlen != null">
              <p i18n class="text-muted font-italic mb-0">
                Watch the PMH overview
              </p>
              <div *ngIf="!showVideoen" class="card" style="width: 18rem;">
                <img class="card-img-1" src="/assets/phone.jpg" alt="Watch" />
                <div class="card-img-overlay text-center">
                  <div class="card-body text-center">
                    <div class="row">
                      <div class="col">
                        <i
                          class="fa fa-play-circle fa-5x text-light"
                          title="Play"
                          aria-hidden="true"
                          style="cursor: pointer;"
                          (click)="showVideoen = true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="showVideoen">
                <video
                  autoplay
                  src="{{ videoUrlen.videoUrl }}"
                  controls
                  class="img-fluid"
                  i18n
                >
                  Your browser does not support HTML5 video
                </video>
              </div>
              <div>
                <a routerLink="/Videos" i18n>More videos »</a>
              </div>
            </div>

            <div class="text-center" [hidden]="!videoLoadingEn">
              <div>
                <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
                <br />
                <small i18n>Loading video</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center mb-3">
    <div class="col-xl-8">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div
            class="card appCard"
            [ngClass]="{ 'text-white bg-secondary': !canAccessAlerts() }"
          >
            <div class="card-body">
              <h2 class="card-title" i18n>
                <i class="fa fa-exclamation-circle"></i> Alerts
              </h2>
              <p class="card-text" i18n>
                Alerts are issued by our team of skilled engineers.
              </p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <a
                i18n
                *ngIf="canAccessAlerts()"
                class="btn btn-primary"
                routerLink="/alerts"
                role="button"
                >View Alerts »</a
              >
              <button
                *ngIf="canAccessAlerts() && favSite != null"
                class="btn btn-success"
                (click)="goToFavSite()"
              >
                {{ favSite.siteName }}
              </button>
              <a
                i18n
                *ngIf="!canAccessAlerts()"
                class="btn btn-dark"
                href="mailto:support@productionsupportportal.com?subject=ACM Alerts Access"
                >Request Access</a
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <h2 class="card-title" i18n>
                <i class="fa fa-file-text"></i> Reports
              </h2>
              <p i18n class="card-text">
                Reports are issued periodically to keep you informed about
                alerts.
              </p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <div class="float-left">
                <!-- <i class="fa fa-star text-warning"></i> Updated! -->
              </div>
              <a
                class="btn btn-primary"
                routerLink="/reports"
                role="button"
                i18n
                >View Reports »</a
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <!-- <p class="float-right" title="Organise your dashboards using folders"><i class="fa fa-star text-warning"></i> Updated!</p> -->
              <h2 class="card-title" i18n>
                <i class="fa fa-area-chart"></i> Live Data
              </h2>
              <p class="card-text" i18n>
                View data live at your desk, with your tablet, or on your
                mobile.
              </p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <a
                i18n
                class="btn btn-primary"
                routerLink="/dashboards"
                role="button"
                >Dashboards »</a
              >
            </div>
          </div>
        </div>

        <!-- </div>

   </div>
</div>

<div class="row justify-content-center mb-3">
    <div class="col-xl-8">
  
      <div class="row"> -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <h2 class="card-title">
                <i class="fa fa-cogs fa-rotate-90"></i> MSR
              </h2>
              <!-- <p title="Placeholder data only"></p> -->
              <p class="card-text" i18n>
                <!-- <i class="fa fa-star-o text-warning"></i> Alpha testing<br> -->
                Maintenance Strategy Review.<br />
                Review the status of your plant items.
              </p>
            </div>
            <div class="card-footer bg-transparent">
              <div class="float-left">
                <!-- <i class="fa fa-star-o text-warning"></i> Alpha test -->
              </div>
              <div class="float-right">
                <a class="btn btn-primary" routerLink="/MSR" role="button"
                  >MSR »</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <!-- <p class="float-right" title="First release"><i class="fa fa-star text-warning"></i> Updated!</p> -->
              <h2 class="card-title" i18n>
                <i class="fa fa-globe"></i>
                My Fleet
              </h2>
              <p i18n class="card-text">Monitor Alerts at a fleet level.</p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <a
                i18n
                class="btn btn-primary"
                routerLink="/FleetAlerts"
                role="button"
                >View Fleet »</a
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <h2 i18n class="card-title">
                <i class="fa fa-medkit"></i>
                Parts Life Extension
              </h2>
              <p i18n class="card-text">Extend GT life.</p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <a
                class="btn btn-primary"
                href="https://ple.prod.solutions/about"
                role="button"
                >P.L.E. »</a
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="card appCard">
            <div class="card-body">
              <!-- <p class="float-right" title="First release"><i class="fa fa-star text-warning"></i> New!</p> -->
              <h2 i18n class="card-title">
                <i class="fa fa-video-camera"></i>
                How To
              </h2>
              <p i18n class="card-text">
                Short videos to help you through the Predictive Maintenance Hub.
              </p>
            </div>
            <div class="card-footer bg-transparent text-right">
              <div class="float-left">
                <!-- <i class="fa fa-star text-warning"></i> Updated! -->
              </div>
              <a class="btn btn-primary" routerLink="/Videos" role="button" i18n
                >Watch »</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
