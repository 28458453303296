import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "./../_services/authentication.service";
@Component({
  selector: "app-analyst-actionable",
  templateUrl: "./analyst-actionable.component.html",
  styleUrls: ["./analyst-actionable.component.css"]
})
export class AnalystActionableComponent implements OnInit {
  role: string = "Condition Monitoring Analyst";
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    if (this.authenticationService.isBridgehead() === true) {
      this.role = "Condition Monitoring Bridgehead";
    }
  }
}
