import { ChartSeriesEditComponent } from './chart-series-edit/chart-series-edit.component';
import { ChartEditComponent } from './chart-edit/chart-edit.component';
import { DashboardDetailComponent } from './dashboard-detail/dashboard-detail.component';
import { ShareDashboardComponent } from './share-dashboard/share-dashboard.component';
import { AcceptDashboardInvitationComponent } from './accept-dashboard-invitation/accept-dashboard-invitation.component';
import { DashboardEditComponent } from './dashboard-edit/dashboard-edit.component';
import { DashboardStartComponent } from './dashboard-start/dashboard-start.component';
import { DashboardsComponent } from './dashboards.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './../_guards/auth.guard';


const routes: Routes = [
{ path: '',component: DashboardsComponent, canActivate: [AuthGuard], children: [
  { path: '', component: DashboardStartComponent},
  { path: 'new', component: DashboardEditComponent},
  { path: 'invite/:inviteId', component: AcceptDashboardInvitationComponent},
  { path: ':dashId/edit', component: DashboardEditComponent},
  { path: ':dashId/share', component: ShareDashboardComponent},
  { path: ':dashId', component: DashboardDetailComponent},
  { path: ':dashId/chart/new', component: ChartEditComponent},
  { path: ':dashId/chart/:chartId/edit', component: ChartEditComponent},
  { path: ':dashId/chart/:chartId/edit/series', component: ChartSeriesEditComponent},
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule {}