<small class="text-muted">
    Setting user permissions for company will give the user the permissions for <b>all sites</b> in that company.
</small>
<hr>


<div class="row">
    <div class="col">

        <div class="input-group my-1" style="max-width: 500px;">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01"><i class="fa fa-spinner fa-spin fa-fw" [hidden]="!loadingCompanies"></i> Company:</label>
            </div>
            <select class="custom-select" [(ngModel)]="selectedCompany" (ngModelChange)="companyChanged()">
                <option selected>Choose...</option>
                <option *ngFor="let company of companies" [ngValue]="company">{{company.name}}</option>
            </select>
            <div class="input-group-append">
                <button class="btn btn-success" (click)="loadUsers()">
                    <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!adding"></i> Load Users
                </button>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-lg-6">

        <div class="card" style="max-width: 650px;">

            <div class="card-header py-1">
                <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!loadingUsers"></i> 
                <b>Users</b> 
                <span class="text-danger" [hidden]="!adminLoadError"><i class="fa fa-exclamation-triangle"></i> loading error</span>
            </div>
        
            <div class="card-body">
                <ul class="list-group" style="max-height: 400px; overflow: auto; max-width: 650px;">
                <li *ngFor="let user of users" class="list-group-item flex-column align-items-start py-1">
                    <div class="d-flex w-100 mb-0 justify-content-between">
                        <p class="mb-0" title="Name">{{user.name}}</p>
                        <small title="Username" class="text-muted">{{user.username}}</small>
                    </div>
                    <div class="d-flex w-100 mb-0 justify-content-between">
                        <small class="text-muted" title="Email address">{{user.email}}</small>
                        <button class="btn btn-sm btn-link text-danger" (click)="removeUser(user)">remove</button>
                    </div>
                    <div class="d-flex w-100 mb-0">
                        <span *ngFor="let permission of filterUserRoles(user.accessGiven)" class="badge badge-primary mr-1">{{permission}}</span>
                    </div>
                </li>
                </ul>
            </div>
        </div>

    </div>

    <div class="col-lg-6">
        <h3>Add Users</h3>
        <app-user-search (userSelected)="searchedUserSelected($event)"></app-user-search>

            
        <div class="card mt-1" style="max-width: 650px;">

            <div class="card-header py-1">
                <b>Pending users</b> <small class="text-muted"> (must be added before leaving this page)</small>
            </div>
        
            <div class="card-body">
        
                <ul class="list-group" style="max-height: 400px; overflow: auto; max-width: 650px;">
                <li *ngFor="let user of pendingUsers" class="list-group-item flex-column align-items-start py-1">
                    <div class="d-flex w-100 mb-0 justify-content-between">
                    <p class="mb-0" title="Name">{{user.name}}</p>
                    <small title="Username" class="text-muted">{{user.username}}</small>
                    </div>
                    <div class="d-flex w-100 mb-0 justify-content-between">
                    <small class="text-muted" title="Email address">{{user.email}}</small>
                    <button class="btn btn-sm btn-link text-danger" (click)="removePending(user)">remove</button>
                    </div>
                </li>
                </ul>
        
                <div class="input-group mt-1">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Permission level:</label>
                    </div>
                    <select class="custom-select" [(ngModel)]="selectedRole">
                        <option selected>Choose...</option>
                        <option [ngValue]="'basic'">basic</option>
                        <option [ngValue]="'standard'">standard</option>
                        <option [ngValue]="'analyst'">analyst</option>
                        <option [ngValue]="'bridgehead'">bridgehead</option>
                    </select>
                    <div class="input-group-append">
                        <button class="btn btn-success" (click)="addUsers()" [disabled]="pendingUsers.length==0">
                            <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!adding"></i> Add
                        </button>
                    </div>
                </div>

            </div>
        </div>   

    </div>

</div>
