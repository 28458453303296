import { FilterAnyPipe } from './../../_pipes/filter-any.pipe';
import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  originalUsers = [];
  users = [];
  model = {
    filter: ''
  }
  filtered = false;
  appliedFilter = '';

  loading = false;

  constructor(
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.userManagementService.getAllUsers().subscribe(
      data => {
        this.users = data;
        this.originalUsers = this.users.slice();
        this.loading = false;
      }, error => {
        this.users = [];
        alert("Error: " + error.error);
        this.loading = false;
      }
    )
  }

  onSubmit(){
    this.users = this.originalUsers.slice();
    
    let filterPipe = new FilterAnyPipe();
    this.appliedFilter = this.model.filter;

    this.users = filterPipe.transform(this.users, this.appliedFilter);    
    this.filtered = true;
  }

  lastSortedBy = '';
  sortBy(fieldName){
    if (this.lastSortedBy == fieldName) {
      this.users.reverse();
      } else {
        this.lastSortedBy = fieldName;
        this.users.sort(function(a, b){
          if(a[fieldName] < b[fieldName]) return -1;
          if(a[fieldName] > b[fieldName]) return 1;
          return 0;
        })
        this.users = this.users.slice();
    }
  }

}
