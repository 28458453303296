import { Params } from "@angular/router";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { ConnStrModule } from "./conn-str.module";

import { BlobFile } from "./../_models/blob-file.model";
import { DataStorageService } from "./data-storage.service";
import { Subscription } from "rxjs/Subscription";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Alert } from "./../_models/alert.model";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";

@Injectable()
export class AlertsService {
  alertsChanged = new Subject<Alert[]>();
  alertChanged = new Subject<Alert>();
  alertFilesChanged = new Subject<BlobFile[]>();
  analysisTypesChanged = new Subject<{ [id: number]: string }[]>();
  statusTypesChanged = new Subject<{ [id: number]: string }[]>();
  openStatusTypesChanged = new Subject<{}[]>();
  severitiesChanged = new Subject<{ [id: number]: string }[]>();

  archivedAlertsSiteId = "";
  archivedAlerts = {};

  archivedAlertsSiteIdChanged = new Subject<string>();
  archivedAlertsChanged = new Subject<{}>();
  archivedAlertsSearchChanged = new Subject<string>();

  alertErrorChanged = new Subject();

  alertSeverity = new Subject<number>();
  noSeverity = new Subject<{}>();

  private alerts: Alert[] = new Array<Alert>();
  private alert: Alert = new Alert();
  private alertFiles: BlobFile[] = new Array<BlobFile>();

  constructor(
    private httpClient: HttpClient,
    private dataStorageService: DataStorageService,
    private connStr: ConnStrModule
  ) {}

  addAlert(siteId: string, alert: Alert) {
    console.log("addAlert() siteId = " + siteId);
    this.dataStorageService.addAlert(siteId, alert).subscribe(data => {
      console.log("added alert");
      console.log(data);
      this.alert = data;
      this.alertChanged.next(this.alert);
    });
  }

  setFavouriteAlertsSite(siteId: string, siteName: string, userId: string) {
    let favSite = {
      siteId: siteId,
      siteName: siteName
    };
    let userFavs = {
      userId: userId,
      prevSite: favSite
    };
    let userFavsStr = JSON.stringify(userFavs);
    localStorage.setItem("userFavsStr", userFavsStr);
  }

  getFavouriteAlertsSite(userId: string) {
    let userFavs = localStorage.getItem("userFavsStr");
    if (!userFavs) {
      return null;
    }
    let userFavsObj = JSON.parse(userFavs);
    if (userFavsObj.userId != userId) {
      return null;
    }
    if (!userFavsObj.prevSite) {
      return null;
    }
    return userFavsObj.prevSite;
  }

  getAlert(siteId: string, alertId: string) {
    console.log("GetAlert() alertId = " + alertId + " siteId = " + siteId);
    this.dataStorageService.getAlertData(siteId, alertId).subscribe(data => {
      this.alert = data;
      this.alertChanged.next(this.alert);
      this.alertSeverity.next(this.alert.severity.id);
    });
  }

  deleteAlert(siteId: string, alertId: string) {
    console.log("deleteAlert");
    return this.httpClient
      .delete<{}>(`${this.connStr.pspBaseUrl}sites/${siteId}/alerts/${alertId}`)
      .map(response => {
        return response;
      });
  }

  updateStatus(siteId: string, alertId: string, statusId: number) {
    console.log("update alert status");
    return this.httpClient
      .put<{}>(
        `${this.connStr.pspBaseUrl}sites/${siteId}/alerts/${alertId}/status`,
        { id: statusId }
      )
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          this.getAlert(siteId, alertId);
        },
        error => {
          alert("Unable to update alert status");
        }
      );
  }

  closeAlert(siteId: string, alertId: string) {
    console.log("update alert status");
    return this.httpClient
      .post<{}>(
        `${this.connStr.pspBaseUrl}sites/${siteId}/alerts/${alertId}/closeAlert`,
        {}
      )
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          this.getAlert(siteId, alertId);
        },
        error => {
          alert("Unable to close the alert");
        }
      );
  }

  clearSeverity() {
    this.alertSeverity.next(0);
    this.noSeverity.next({});
  }

  getAlertsStatusSummary(siteId: string) {
    console.log("getAlertsStatusSummary");
    return this.httpClient
      .get<{}>(
        this.connStr.pspBaseUrl + "sites/" + siteId + "/allAlertsStatusSummary"
      )
      .map(response => {
        return response;
      });
  }

  getAlertsSeveritySummary(siteId: string) {
    console.log("getAlertsSeveritySummary");
    return this.httpClient
      .get<{}>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/allAlertsSeveritySummary"
      )
      .map(response => {
        return response;
      });
  }

  updateAlert(siteId: string, alertId: string, alert: Alert) {
    console.log("updateAlert() alertId = " + alertId + " siteId = " + siteId);
    this.dataStorageService
      .updateAlertData(siteId, alertId, alert)
      .subscribe(data => {
        this.alert = data;
        this.alertChanged.next(this.alert);
      });
  }

  updateAlertNotes(siteId: string, alertId: string, notes: {}[]) {
    console.log(
      "updateAlertNotes() alertId = " + alertId + " siteId = " + siteId
    );
    return this.httpClient
      .put<{}>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/notes",
        notes
      )
      .map(response => {
        return response;
      })
      .subscribe(data => {});
  }

  searchArchivedAlerts(siteId: string, search: string) {
    this.archivedAlerts["search"] = search;
    this.archivedAlertsSiteId = siteId;
    console.log("searchArchivedAlerts() " + siteId);

    let myParams = new HttpParams();
    // Begin assigning parameters
    myParams = myParams.append("search", this.archivedAlerts["search"]);
    myParams = myParams.append("onlyOpen", false.toString());

    return this.httpClient
      .get<{}>(
        this.connStr.pspBaseUrl +
          "sites/" +
          this.archivedAlertsSiteId +
          "/alerts/archived",
        { params: myParams }
      )
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          if (data["search"] == this.archivedAlerts["search"]) {
            console.log("alerts returned");
            console.log(data);
            this.archivedAlerts["alerts"] = data["alerts"];
            console.log("Data returned for search = " + data["search"]);
            this.archivedAlertsChanged.next(this.archivedAlerts);
          } else {
            console.log(
              "Data returned for previous search = " + data["search"]
            );
          }
        },
        error => {
          this.archivedAlerts = [];
          this.archivedAlertsChanged.error("Error loading archived alerts");
        }
      );
  }

  getAlerts(siteId: string, onlyOpen: boolean, filter: boolean) {
    if (filter) {
      console.log("getMyAlerts() " + siteId);
      this.dataStorageService
        .getMyAlertsData(siteId, onlyOpen)
        .subscribe((data: Alert[]) => {
          this.alerts = data;
          this.alertsChanged.next(this.alerts);
        });
    } else {
      console.log("getAllAlerts() " + siteId);
      this.dataStorageService
        .getAlertsData(siteId, onlyOpen)
        .subscribe((data: Alert[]) => {
          this.alerts = data;
          this.alertsChanged.next(this.alerts);
        });
    }
  }

  getAnalysisTypes() {
    console.log("getAnalysisTypes()");
    var myArray: { [id: number]: string }[];
    myArray = [
      { 2: "Oil Analysis (Lubricating)" },
      { 3: "ACM" },
      { 4: "Performance" },
      { 5: "Environmental" },
      { 6: "Visual Inspection" },
      { 7: "VA (Hand Held)" },
      { 8: "OLVMS" },
      { 9: "CMS (Wind)" },
      { 10: "Borescope" },
      { 11: "Oil Analysis (Insulating)" },
      { 12: "OLCMS (Combustion)" },
      { 13: "Thermography" },
      { 14: "Electrical Test" },
      { 15: "NDT" },
      { 16: "Bearing Inspection" },
      { 20: "Acoustic" },
      { 22: "Cycle Chemistry Report" },
      { 23: "Grease Analysis" },
      { 24: "Lab Analysis" }
    ];
    this.analysisTypesChanged.next(myArray);

    // COMMENTED OUT UNTIL NEXT API RELEASE
    // return this.httpClient.get<{[id: number]: string}[]>(this.pspBaseUrl + 'analysisTypes')
    //   .map((response) => {
    //     return response;
    //   })
    //   .subscribe(data => {
    //         this.analysisTypesChanged.next(data);
    //   })
  }

  getStatusTypes() {
    console.log("getStatusTypes()");
    var myArray: { [id: number]: string }[];
    myArray = [
      { 1: "Closed" },
      { 15: "CMS Pending Approval" },
      { 11: "Feedback Received" },
      { 14: "In Creation" },
      { 13: "Maintenance Considered" },
      { 7: "Maintenance Planned" },
      { 2: "Ongoing" },
      { 12: "Pending Approval" },
      { 8: "Pending Return to Service" },
      { 3: "Requires Feedback" },
      { 4: "Pending Translation" }
    ];
    this.statusTypesChanged.next(myArray);

    // COMMENTED OUT UNTIL NEXT API RELEASE
    // return this.httpClient.get<{[id: number]: string}[]>(this.pspBaseUrl + 'analysisTypes')
    //   .map((response) => {
    //     return response;
    //   })
    //   .subscribe(data => {
    //         this.analysisTypesChanged.next(data);
    //   })
  }

  getOpenStatusTypes() {
    console.log("getStatusTypes()");
    var myArray: {}[];
    myArray = [
      { id: 11, description: "Feedback Received" },
      { id: 13, description: "Maintenance Considered" },
      { id: 7, description: "Maintenance Planned" },
      { id: 2, description: "Ongoing" },
      { id: 8, description: "Pending Return to Service" },
      { id: 3, description: "Requires Feedback" },
      { id: 4, description: "Pending Translation" }
    ];
    this.openStatusTypesChanged.next(myArray);
  }

  statusIsPending(statusId: string) {
    if (statusId == "12" || statusId == "14" || statusId == "15" || statusId == "4") {
      return true;
    }
    return false;
  }
  statusIsOngoing(statusId: string) {
    if (
      statusId == "13" ||
      statusId == "7" ||
      statusId == "2" ||
      statusId == "8"
    ) {
      return true;
    }
    return false;
  }

  getSeverities() {
    console.log("getSeverities()");

    return this.httpClient
      .get<{ [id: number]: string }[]>(this.connStr.pspBaseUrl + "severities")
      .map(response => {
        return response;
      })
      .subscribe(data => {
        this.severitiesChanged.next(data);
      });
  }

  getAlertFiles(siteId: string, alertId: string) {
    this.alertFiles = [];
    console.log("getAlertFiles() alertId = " + alertId + " siteId = " + siteId);
    this.dataStorageService.getAlertFiles(siteId, alertId).subscribe(data => {
      this.alertFiles = data;
      this.alertFilesChanged.next(this.alertFiles);
    });
  }

  unlinkFile(siteId: string, alertId: string, fileId: string) {
    console.log(
      "unlinkFile() alertId = " +
        alertId +
        " siteId = " +
        siteId +
        " fileId = " +
        fileId
    );
    this.dataStorageService
      .unlinkFile(siteId, alertId, fileId)
      .subscribe(data => {
        var index = this.alertFiles.findIndex(
          x => x["hashId"].toString() == fileId.toString()
        );
        if (index > -1) {
          console.log("unlinked file");
          this.alertFiles.splice(index, 1);
        }
        this.alertFilesChanged.next(this.alertFiles);
      });
  }

  addFeedback(
    siteId: string,
    alertId: string,
    alertFeedback: string,
    sendEmailUpdate: boolean,
    requiresBridgehead: boolean
  ) {
    console.log(
      "addFeedback() alertId = " +
        alertId +
        " siteId = " +
        siteId +
        " feedback = " +
        alertFeedback +
        "requiresBridgehead: " +
        requiresBridgehead
    );

    let feedbackObj = {
      feedback: alertFeedback
    };

    // Initialize Params Object
    let myParams = new HttpParams();
    // Begin assigning parameters
    myParams = myParams
      .append("sendUpdate", sendEmailUpdate.toString())
      .append("requiresBridgeHead", requiresBridgehead.toString());

    return this.httpClient
      .post<string>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/addFeedback",
        feedbackObj,
        { params: myParams }
      )
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          console.log("Feedback added got");
          console.log(data);
          this.alert.feedback = data["feedback"];
          this.alertChanged.next(this.alert);
        },
        errorData => {
          this.alertErrorChanged.next(errorData);
        }
      );
  }

  sendEmailUpdate(siteId: string, alertId: string) {
    console.log(
      "sendEmailUpdate() alertId = " + alertId + " siteId = " + siteId
    );

    return this.httpClient
      .post(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/sendUpdate",
        null,
        {
          responseType: "text"
        }
      )
      .map(response => {
        return response;
      });
  }

  approveAlert(siteId: string, alertId: string) {
    console.log("approveAlert() alertId = " + alertId + " siteId = " + siteId);

    return this.httpClient
      .put(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/approve",
        null
      )
      .map(response => {
        return response;
      });
  }
  findMentionedTags(siteId: string, alertId: string) {
    console.log("findMentionedTags");
    //return this.httpClient.post<{}[]>(this.connStr.universeDataUrl + 'dataseries/' + siteId + '/FindTags', textToSearch)
    return this.httpClient
      .get<{}[]>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/tags"
      )
      .map(response => {
        return response;
      });
  }

  getAlertRecipients(siteId: string, alertId: string) {
    console.log("getAlertRecipients");
    //return this.httpClient.post<{}[]>(this.connStr.universeDataUrl + 'dataseries/' + siteId + '/FindTags', textToSearch)
    return this.httpClient
      .get<{}[]>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/recipients"
      )
      .map(response => {
        return response;
      });
  }

  setFileToSendWithUpdates(
    siteId: string,
    alertId: string,
    fileId: string,
    send: boolean
  ) {
    console.log("setFileToSendWithUpdates()");
    let sendWithUpdates = {
      send: send
    };
    return this.httpClient
      .put<{}>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/files/" +
          fileId +
          "/sendWithUpdates",
        sendWithUpdates
      )
      .map(response => {
        return response;
      });
  }

  saveFileOrdering(siteId: string, alertId: string, fileIds: {}[]) {
    console.log("saveFileOrdering()");
    return this.httpClient
      .put<{}>(
        this.connStr.pspBaseUrl +
          "sites/" +
          siteId +
          "/alerts/" +
          alertId +
          "/fileOrdering",
        fileIds
      )
      .map(response => {
        return response;
      });
  }

  getFleetAlertSummary(companyName: string) {
    return this.httpClient
      .get<{}>(
        this.connStr.pspBaseUrl +
          "companies/" +
          companyName +
          "/alertSeverityKpis/"
      )
      .map(response => {
        return response;
      });
  }

  getFleetActionableAlerts(companyName: string) {
    return this.httpClient
      .get<{}[]>(
        this.connStr.pspBaseUrl +
          "companies/" +
          companyName +
          "/ActionableAlerts"
      )
      .map(response => {
        return response;
      });
  }
}
