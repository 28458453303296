<div class="jumbotron jumbotron-fluid">
  <div class="container">

    <h1 class="display-3" i18n>
      <i class="fa fa-globe"></i> Smart Fleet
    </h1>    
    <p class="lead" i18n>Understand your Fleet</p>
      
  </div>
</div>

<app-fleet-alerts-kpi *ngFor="let company of companies" [company]="company"></app-fleet-alerts-kpi>