<!-- <div class="row">
  <div class="col">
    <div class="bootstrap-vertical-nav">

      <div class="mt-2">
        <a class="btn btn-success btn-block" [routerLink]="['./new']" role="button"><i class="fa fa-plus-square" aria-hidden="true"></i> New Dashboard</a>
        <hr>
        <div class="list-group">
          <app-dashboard-item *ngFor="let aDashboard of dashboards" [dashboard]=aDashboard></app-dashboard-item>
        </div>

        <div *ngIf="loading" class="row justify-content-center">
          <div>
            <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
          </div>
        </div>

      </div>

    </div>
  </div>
</div> -->
<div class="row">
    <div class="col">
      <div class="bootstrap-vertical-nav">
  
        <div class="mt-2">
          <a class="btn btn-success btn-block" [routerLink]="['./new']" role="button" i18n><i class="fa fa-plus-square" aria-hidden="true"></i> New Dashboard</a>
          <hr>

          <button class="btn btn-sm btn-link" (click)="newFolder()" i18n><i class="fa fa-folder"></i> New Folder</button>
          <!-- <button class="btn btn-sm btn-link" (click)="saveFolders()"><i class="fa fa-floppy-o"></i> Save</button> -->
           

          <div class="list-group list-group-flush">
            <app-dash-folder *ngFor="let folder of dashboards.folders" [folders]="folder.folders" [name]="folder.name" [items]="folder.items">
            </app-dash-folder>
          </div>
          <div class="list-group list-group-flush">
            <app-dashboard-item *ngFor="let item of dashboards.items" [dashboard]="item">
            </app-dashboard-item>
          </div>
  
          <div *ngIf="loading" class="row justify-content-center">
            <div>
              <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
            </div>
          </div>
  
        </div>
  
      </div>
    </div>
  </div>
  
  <!-- <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Modal title</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>One fine body&hellip;</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
    </div>
  </ng-template> -->