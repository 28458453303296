
<div class="card" style="max-width: 650px;">

  <div class="card-header py-1">
    <b>User Search</b> <small class="text-muted"> using name or email</small>
  </div>

  <div class="card-body">
    <form (ngSubmit)="onSubmit()" #searchForm="ngForm">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Find a user..."
          [(ngModel)]="model.search" name="search"
          #search="ngModel" autocomplete="off">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="submit">
            <i class="fa fa-search" [hidden]="searching || searchError"></i>
            <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!searching"></i>
            <i class="fa fa-exclamation-triangle text-danger" [hidden]="!searchError"></i> Search
          </button>
        </div>
      </div>
    </form>
    <small [hidden]="!searching"><span class="text-muted">Searching for:</span> {{model.search}}</small>
    <small [hidden]="!searched"><span class="text-muted">{{userResults.length}} results for:</span> {{model.search}} <span class="text-muted">(limited to 50 results)</span></small>

    <span class="text-muted" *ngIf="userResults.length == 0 && searched">No users found</span>


    <div class="list-group" style="max-height: 400px; overflow: auto;">
      <a *ngFor="let user of userResults" 
        class="list-group-item list-group-item-action flex-column align-items-start py-1"
        (click)="onUserSelect(user)"
        style="cursor: pointer;">
        <div class="d-flex w-100 mb-0 justify-content-between">
          <p class="mb-0" title="Name">{{user.name}}</p>
          <small title="Username" class="text-muted">{{user.username}}</small>
        </div>
        <small class="text-muted" title="Email address">{{user.email}}</small>
        <small *ngIf="!user.email" class="text-muted" title="No email address"><i>no email set</i></small>
      </a>
    </div>

  </div>

</div>
