import { Dashboard } from './../../_models/dashboard.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard-edit',
  templateUrl: './dashboard-edit.component.html',
  styleUrls: ['./dashboard-edit.component.css']
})
export class DashboardEditComponent implements OnInit {
  editMode = false;
  dashboardForm: FormGroup;
  dashboardId: string;

  constructor(
    private route: ActivatedRoute,
    private dashboardsService: DashboardsService,
    private router: Router) { }

  ngOnInit() {
    

    this.dashboardsService.hideSidebar();

    this.route.params
    .subscribe(
      (params: Params) => {
        this.dashboardId = params['dashId'];
        console.log("dashId = " + this.dashboardId);
        // if params has a dashboardId property, it will return true
        // if there is no 'dashboardId' parameter, it will be false
        this.editMode = (params['dashId'] != null);
        // console.log(this.editMode);
        this.initForm();
      }
    );

    this.dashboardsService.dashboardsChanged.subscribe(
      (data) => {
        if (this.editMode) {
          this.initForm()
        }
      }
    )

  }

  private initForm() {
    let dashName = '';
    let dashDesc = '';

    if (this.editMode) {
       const dashboard = this.dashboardsService.getDashboard(this.dashboardId);
       if (dashboard != null) {
        dashName = dashboard.name;
        dashDesc = dashboard.description;
       }
    }

    this.dashboardForm = new FormGroup({
      'name': new FormControl(dashName, Validators.required),
      'description': new FormControl(dashDesc)
    });
  }


  onSubmit() {
    if (this.editMode) {
      this.dashboardsService.updateDashboard(this.dashboardId, this.dashboardForm.value);
    } else {
      this.dashboardsService.addDashboard(this.dashboardForm.value);      
    }
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}
