import { Chart } from './chart.model';

export class Dashboard {
    dashboardId: string;
    name: string;
    description: string;
    charts: Chart[];

    // constructor(seed: string){
    //     this.dashboardId = seed;
    //     this.name = "Dashboard " + seed;
    //     this.description = "This is the description for the dashboard with name " + seed;
    //     this.charts = new Array<Chart>();
    //     this.charts.push(new Chart(seed));
    // }
}