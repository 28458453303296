import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.css']
})
export class ReportItemComponent implements OnInit {

  @Input() report;
  @Input() siteId;

  constructor() { }

  ngOnInit() {
  }

  sentCss() {
    if(this.report['sentBy'] != null){
      return 'sent';
    }
    return 'notSent';
  }

}
