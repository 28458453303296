import { AuthenticationService } from "./../../_services/authentication.service";
import { Site } from "./../../_models/site.model";
import { SitesService } from "./../../_services/sites.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { AlertsService } from "../../_services/alerts.service";
import { Alert } from "../../_models/alert.model";

@Component({
  selector: "app-alert-edit",
  templateUrl: "./alert-edit.component.html",
  styleUrls: ["./alert-edit.component.css"]
})
export class AlertEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private sitesService: SitesService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  editMode = false;

  loadingSeverities = true;
  loadingStatuses = true;
  loadingAnalysis = true;
  loadingAlert = true;

  alertForm: FormGroup;

  isAcmUser = false;
  is_bridgehead = false;
  siteId: string;
  alertId: string;
  alert: Alert = new Alert();
  site: Site;

  analysisTypes: { [id: number]: string }[] = [];
  statusTypes: { [id: number]: string }[] = [];

  dropdownListUnits = [];
  selectedUnits = [];

  dropdownListAreas = [];
  selectedAreas = [];

  unfilteredDropdownListItems = [];
  dropdownListItems = [];
  selectedItems = [];

  dropdownListAnalysis = [];
  selectedAnalysis = [];

  dropdownListStatus = [];
  selectedStatus = [];

  // severity = 0;
  // dropdownListSeverity = [];
  // selectedSeverity = [];

  dropdownSettings = {
    text: "Select",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true
  };

  dropdownSettingsSingle = {
    text: "Select",
    singleSelection: true,
    enableSearchFilter: false
  };

  ngOnInit() {
    this.alert.summary = {};
    this.alert.recommendation = {};
    this.alert.feedback = {};

    this.alert.summary["newContent"] = "";
    this.alert.recommendation["newContent"] = "";
    this.alert.feedback["newContent"] = "";

    this.isAcmUser = this.authenticationService.isAcm(this.siteId);
    this.is_bridgehead = this.authenticationService.isBridgehead(this.siteId);

    if (!this.isAcmUser && !this.is_bridgehead) {
      this.router.navigate(["/403"]);
    }

    this.route.params.subscribe((params: Params) => {
      this.siteId = params["siteId"];
      this.alertId = params["alertId"];
      console.log("siteId = " + this.siteId);
      // if params has a alertId property, it will return true
      // if there is no 'alertId' parameter, it will be false
      this.editMode = params["alertId"] != null;
      console.log("Edit mode");
      this.initForm();
    });

    // this.alertsService.severitiesChanged
    // .subscribe(
    //   (data) => {

    //     this.severities = data;

    //     this.dropdownListSeverity = [];

    //     for (let severity in data){
    //       let value = data[severity];
    //       this.dropdownListSeverity.push({
    //         id: parseInt(severity),
    //         itemName: value
    //       })
    //     };
    //   }
    // )

    this.alertsService.analysisTypesChanged.subscribe(data => {
      this.loadingAnalysis = false;
      this.analysisTypes = data;
      this.initAnalysisTypes();
    });
    this.alertsService.alertChanged.subscribe(data => {
      if (this.editMode) {
        this.loadingAlert = false;
        this.alert = data;
        this.initForm();
      }
    });
    this.alertsService.statusTypesChanged.subscribe(data => {
      this.loadingStatuses = false;
      this.statusTypes = data;
      this.initStatusTypes();
    });
    this.sitesService.getSite(this.siteId);
    this.sitesService.siteChanged.subscribe(data => {
      this.site = data;
      console.log("site", this.site);

      this.initUnitAreaItem();

      this.initForm();
    });

    console.log("init requests");

    this.alertsService.getSeverities();
    this.alertsService.getAnalysisTypes();
    this.alertsService.getStatusTypes();

    if (this.editMode) {
      console.log("edit mode - get alert");
      this.alertsService.getAlert(this.siteId, this.alertId);
    } else {
      this.loadingAlert = false;
    }
  }

  private initUnitAreaItem() {
    console.log("initUnitAreaItem");

    this.dropdownListUnits = [];
    for (var unit of this.site.units) {
      this.dropdownListUnits.push({
        id: unit["unitId"],
        itemName: unit["name"],
        shortName: unit["shortName"]
      });
    }

    // sort areas
    this.site.areas.sort(function(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    this.dropdownListAreas = [];
    for (var area of this.site.areas) {
      this.dropdownListAreas.push({
        id: area["areaId"],
        itemName: area["name"]
      });
    }

    this.dropdownListItems = [];
    for (var item of this.site.items) {
      this.dropdownListItems.push({
        id: item["itemId"],
        itemName: item["name"],
        unitId: item["unitId"],
        areaId: item["areaId"]
      });
    }
    this.unfilteredDropdownListItems = this.dropdownListItems.slice();

    console.log("initUnitAreaItem end");
  }

  private initAnalysisTypes() {
    this.dropdownListAnalysis = [];

    // iterate throught the list of analysis types
    for (let analysis in this.analysisTypes) {
      let value = this.analysisTypes[analysis];
      // iterate through the properties of the analysis type to find the id and name
      for (var prop in value)
        this.dropdownListAnalysis.push({
          id: parseInt(prop),
          itemName: value[prop]
        });
    }
  }

  private initStatusTypes() {
    this.dropdownListStatus = [];
    console.log(this.statusTypes);
    // iterate throught the list of status types
    for (let status in this.statusTypes) {
      let value = this.statusTypes[status];
      // iterate through the properties of the status type to find the id and name
      for (var prop in value)
        this.dropdownListStatus.push({
          id: parseInt(prop),
          itemName: value[prop]
        });
    }
    console.log("status types set");
  }

  private initForm() {
    this.selectedItems = [];
    this.selectedAreas = [];
    this.selectedUnits = [];
    this.selectedAnalysis = [];
    this.selectedStatus = [];

    if (this.editMode) {
      console.log("initForm in edit mode");
      if (this.alert != null) {
        for (var item of this.alert.items) {
          this.selectedItems.push({
            id: item["itemId"],
            itemName: item["name"]
          });
        }

        for (var area of this.alert.areas) {
          this.selectedAreas.push({
            id: area["areaId"],
            itemName: area["name"]
          });
        }

        for (var unit of this.alert.units) {
          this.selectedUnits.push({
            id: unit["unitId"],
            itemName: unit["name"]
          });
        }

        for (var analysis of this.alert.analysisTypes) {
          this.selectedAnalysis.push({
            id: analysis["id"],
            itemName: analysis["description"]
          });
        }

        this.selectedStatus.push({
          id: this.alert.status.id,
          itemName: this.alert.status.description
        });

        // this.selectedSeverity = [];
        // this.selectedSeverity.push({
        //   id: this.alert.severity.id,
        //   itemName:this.alert.severity.description
        // })
      }
    }
    console.log(this.alert);
    this.alertForm = new FormGroup({
      statusTypes: new FormControl(this.alert.status.id),
      name: new FormControl(this.alert.name, Validators.required),
      summary: new FormControl(
        this.alert.summary == null ? "" : this.alert.summary["newContent"],
        Validators.required
      ),
      recommendation: new FormControl(
        this.alert.recommendation == null
          ? ""
          : this.alert.recommendation["newContent"]
      ),
      units: new FormControl(this.selectedUnits),
      areas: new FormControl(this.selectedAreas),
      items: new FormControl(this.selectedItems),
      analysisTypes: new FormControl(
        this.dropdownListAnalysis,
        Validators.required
      ),
      severity: new FormControl(this.alert.severity.id.toString())
    });
    console.log(this.alertForm);
    console.log("init form end");
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  formToAlert() {
    let anAlert: Alert = new Alert();

    console.log("form");
    console.log(this.alertForm);

    anAlert.name = this.alertForm.value["name"];
    console.log(`Alert Name: ${anAlert.name}`);

    anAlert.summary = this.alertForm.value["summary"];
    anAlert.recommendation = this.alertForm.value["recommendation"];
    if (this.alertForm.value["units"]) {
      this.alertForm.value["units"].forEach(unitElement => {
        anAlert.units.push({
          unitId: unitElement["id"],
          name: "itemName"
        });
      });
    }
    if (this.alertForm.value["areas"]) {
      this.alertForm.value["areas"].forEach(areaElement => {
        anAlert.areas.push({
          areaId: areaElement["id"],
          name: "itemName"
        });
      });
    }
    if (this.alertForm.value["items"]) {
      this.alertForm.value["items"].forEach(itemElement => {
        anAlert.items.push({
          itemId: itemElement["id"],
          name: "itemName"
        });
      });
    }
    if (this.alertForm.value["analysisTypes"]) {
      this.alertForm.value["analysisTypes"].forEach(analysisElement => {
        anAlert.analysisTypes.push({
          id: analysisElement["id"],
          description: "itemName"
        });
      });
    }
    anAlert.status = { id: 0, description: "" };
    if (this.alertForm.value["statusTypes"] && !this.is_bridgehead) {
      this.alertForm.value["statusTypes"].forEach(statusElement => {
        anAlert.status.id = statusElement["id"];
        anAlert.status.description = statusElement["itemName"];
      });
    } else {
      anAlert.status.id = 3;
      anAlert.status.description = "Requires Feedback";
    }

    anAlert.severity = {
      id: parseInt(this.alertForm.value["severity"]),
      description: "" // only the id is needed to set values in the API
    };

    return anAlert;
  }

  onSubmit() {
    let anAlert = this.formToAlert();
    console.log({ anAlert });

    if (this.editMode) {
      if (!this.is_bridgehead) {
        this.alertsService.updateAlert(this.siteId, this.alertId, anAlert);
        console.log(`Alert updated!`);
      }
      //its a bridgehead user so needs to approve alert to change it to requires feedback
      else {
        let approve_alert_error: boolean = false;
        const approve_alert = this.alertsService.approveAlert(
          this.siteId,
          this.alertId
        );

        approve_alert.subscribe(
          data => {
            console.log(`approve_alert:${data}`);
            this.alertsService.updateAlert(this.siteId, this.alertId, anAlert);
            console.log(`Alert updated!`);
          },
          error => {
            console.log(`approve alert error: ${error.error}`);
            approve_alert_error = true;
            window.alert(`Error updating alert: ${error.error}`);
          }
          // () => {
          //   if (!approve_alert_error) {
          //     console.log(`email update: ` + approve_alert_error);
          //     if (
          //       window.confirm(
          //         "Alert Saved!. Do you want to send email update to customer?"
          //       )
          //     ) {
          //       this.alertsService.sendEmailUpdate(this.siteId, this.alertId);
          //     }
          //   }
          // }
        );
      }
    } else {
      this.alertsService.addAlert(this.siteId, anAlert);
    }
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  onCancel() {
    console.log("onCancel()");
    if (this.editMode) {
      this.router.navigate(["../"], { relativeTo: this.route });
    } else {
      this.router.navigate(["../"], { relativeTo: this.route });
    }
  }

  unitAreaChanged(eventIn) {
    console.log("unit or area changed");
    this.filterItems();
  }

  filterItems() {
    console.log("dropdownListUnits", this.dropdownListUnits);
    console.log("selectedUnits", this.selectedUnits);
    console.log("dropdownListItems", this.unfilteredDropdownListItems);
    this.dropdownListItems = [];

    for (const anItem of this.unfilteredDropdownListItems) {
      var unitMatched = false;
      var areaMatched = false;

      for (const aUnit of this.selectedUnits) {
        if (aUnit["id"] == anItem["unitId"]) {
          unitMatched = true;
          continue;
        }
      }
      for (const anArea of this.selectedAreas) {
        if (anArea["id"] == anItem["areaId"]) {
          areaMatched = true;
          continue;
        }
      }

      if (areaMatched && unitMatched) {
        this.dropdownListItems.push(anItem);
      } else {
        // not a match
      }
    }
  }
}
