<div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-5">


    <h1 i18n>Account Details</h1>

    <!-- Error alert area -->
    <app-info-alert></app-info-alert>

    <!-- Loading spinner -->
    <div *ngIf="loading; else userDetails">
      <p class="text-center mt-5">
        <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
      </p>
    </div>

    <ng-template #userDetails>

      <form (ngSubmit)="onSubmit()" #accountForm="ngForm">
        <div class="form-group">
          <label for="name" i18n>Name</label>
          <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name" #name="ngModel">
          <div [hidden]="name.valid || name.pristine" class="alert alert-danger" i18n>
            Name is required
          </div>
        </div>

        <!-- <div class="form-group">
            <label for="ccEmail">CC Email Address</label>
            <input 
              class="form-control" 
              id="ccEmail" 
              [(ngModel)]="model.ccEmail" 
              [email]="model.ccEmail!=''"
              name="ccEmail" 
              #ccEmail="ngModel">
            <small id="ccEmail" class="form-text text-muted">Emails sent to your main account will also be sent to this account.</small>        
            <div [hidden]="ccEmail.valid || ccEmail.pristine" class="alert alert-danger">
                Invalid email
              </div>    
          </div> -->

        <button type="submit" class="btn btn-success" [disabled]="!accountForm.form.valid" i18n>Save</button>

      </form>
    </ng-template>

  </div>
</div>
</div>  
