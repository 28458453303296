<a class="list-group-item list-group-item-action flex-column align-items-start" [ngClass]="severity()" [routerLink]="['/alerts', siteId, alert.hashId]"
routerLinkActive="active">

<p class="mb-1">{{ alert.name }}

  <span [ngSwitch]="alert.status.id">

    <!-- Requires feedback -->      
    <span *ngSwitchCase="'3'">
      <span class="badge badge-primary">
        {{ alert.status.description }}
      </span>
    </span>

    <!-- Feedback received -->
    <span *ngSwitchCase="'11'">
      <span class="badge badge-success">
        {{ alert.status.description }}
      </span>
    </span>

    <!-- All other statuses -->
    <span *ngSwitchDefault>
      <span class="badge badge-secondary">
        {{ alert.status.description }}
      </span>
    </span>

  </span>
  <span i18n>
    <i *ngIf="MaintenanceOrderNum() != ''"><br>Maint. order <b>{{MaintenanceOrderNum()}}</b></i>
    <i *ngIf="NotificationNum() != ''"><br>Notif. no. <b>{{NotificationNum()}}</b></i>
  </span>


</p>

</a>
