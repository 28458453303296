    
<div class="row justify-content-center">
  <div class="col text-center">
    <div *ngIf="loading">
      <h1>Loading {{company.name}} data</h1>
      <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
    </div>    
  </div>
</div>

<div class="row justify-content-center">


  <!-- LEFT COLUMN -->
  <div class="col-lg-3">
    <h4 class="display-4">{{company.name}}</h4>
    <h5 i18n>
      {{fleetAlertData.alertCount}} Alert<span [hidden]="fleetAlertData.alertCount == '1'">s</span> 
      across {{siteCountStr()}} Site<span [hidden]="siteCountStr() == '1'">s</span>
    </h5>
  </div>
  


  <!-- CENTRE COLUMN -->
  <div class="col-lg-6">

    <div [hidden]="siteCount() == 0">

    <div class="row">
      <div class="col-sm-6">
        
          <div class="card">
            <div class="card-body">

              <p class="card-subtitle mb-2 text-muted" i18n>{{company.name}} Alerts by Severity</p>
              <chart class="piechart" [options]="optionsAllSeverity" (load)="saveInstanceAllSeverity($event.context)">
              </chart>
    
              <a (click)="sevCollapsed = !sevCollapsed" [routerLink]="" class="card-link" i18n><i class="fa fa-angle-down"></i> Details</a>
          
              <div [ngbCollapse]="sevCollapsed">

                  <div *ngFor="let aSite of sitesCritSeverity">
                    {{aSite.name}} - 
                    <span [routerLink]="['/alerts', aSite.id]" class="badge badge-danger" style="cursor: pointer;">
                      {{aSite.alerts}} alerts
                    </span><br>
                  </div>

              </div>

            </div>
          </div>

        </div>
        
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">

              <p class="card-subtitle mb-2 text-muted" i18n>{{company.name}} Alerts by Status</p>
              <chart class="piechart" [options]="optionsAllStatus" (load)="saveInstanceAllStatus($event.context)">
              </chart>

              <a (click)="statCollapsed = !statCollapsed" [routerLink]="" class="card-link" i18n><i class="fa fa-angle-down"></i> Details</a>
          
              <div [ngbCollapse]="statCollapsed">

                <b i18n>Alerts with feedback</b>
                <div *ngIf="sitesSentFeedback.length == 0" i18n> None</div>
                <div *ngFor="let aSite of sitesSentFeedback">
                  {{aSite.name}} - 
                  <span [routerLink]="['/alerts', aSite.id]" class="badge badge-success" style="cursor: pointer;">
                    {{aSite.alerts}} alerts
                  </span><br>
                </div>

                <hr>

                <b i18n>Alerts requiring feedback</b>
                <div *ngFor="let aSite of sitesRequireFeedback">
                  {{aSite.name}} - 
                  <span [routerLink]="['/alerts', aSite.id]" class="badge badge-primary" style="cursor: pointer;">
                    {{aSite.alerts}} alerts
                  </span><br>
                </div>

              </div>
    
            </div>    
        </div>
      </div>
    </div>
  
    <div class="mt-4" id="sevchartcontainer" [style.height.px]="chartHeight">
      <chart [options]="optionsKpiSeverity" (load)="saveInstanceSeverity($event.context)">
      </chart>
    </div>

  </div>
    <!-- <pre>{{fleetAlertData | json}}</pre> -->
  </div>


  <!-- RIGHT COLUMN -->
  <div class="col-lg-3">
  </div>
  

</div>

<hr>
