<h2>Edit User</h2>
<small class="text-muted">
  Edit the details for a user. User access rights are set in the <i>Access rights</i> menu.
</small>
<hr>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6">

      <form (ngSubmit)="onSubmit()" #userForm="ngForm" style="max-width: 650px;" autocomplete="off">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" id="name"
            required autocomplete="off" 
            [(ngModel)]="model.name" name="name"
            #name="ngModel">
          <div [hidden]="name.valid || name.pristine"
                class="text-danger">
            Name is required
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" email class="form-control" id="email"
            required autocomplete="off" 
            [(ngModel)]="model.email" name="email" 
            #email="ngModel">
          <div [hidden]="email.valid || email.pristine"
                class="text-danger">
            Email is required
          </div>
        </div>

        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" class="form-control" id="username"
            autocomplete="off" readonly 
            [(ngModel)]="model.username" name="username" 
            #username="ngModel">
        </div>

        <div class="form-check pb-2">
          <input class="form-check-input" type="checkbox" name="isActive" [(ngModel)]="model.enabled">
          <label class="form-check-label">
            Enabled
          </label>
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!userForm.form.valid || submitted">
          <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!updating"></i> Submit
        </button>
        <button type="button" role="button" class="btn btn-outline-success" (click)="sendWelcomeEmail()" [disabled]="welcomeSendClicked">
          <i class="fa fa-envelope-o" [hidden]="welcomeSending || welcomeError || welcomeSent"></i> 
          <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!welcomeSending"></i> 
          <i class="fa fa-check" [hidden]="!welcomeSent"></i> 
          <i class="fa fa-exclamation-triangle" [hidden]="!welcomeError"></i> 
          Send welcome email
        </button>
      </form>
      
    </div>
  </div>
</div>

