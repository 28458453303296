  
<div class="container-fluid">
  <div class="row justify-content-center text-center mb-3 ml-3 mr-3">
    <div class="col-md-6 col-lg-4 col-xl-3 mt-5">

     

      <div *ngIf="refreshing">
        <h1><i class="fa fa-circle-o-notch fa-spin fa-5x"></i></h1>
      </div>
      <br>
      
      <button class="loginBtn loginBtn--enerlytics" (click)="goToEnerlyticsLogin()" i18n>
        Login with Enerlytics
      </button>
      <p>or </p>
      <p i18n>Enter PMH account details </p>

      <div *ngIf="!refreshing">
      <form (ngSubmit)="login()" role="form" class="m-3">
                
        <div class="form-group">
          <label for="username"i18n>Username</label>
          <input type="text" class="form-control" id="name" 
            required name="username" #username/>
          <small id="usernameHelp" class="form-text text-muted" i18n>Your username can be found in your <i>Welcome</i> or <i>Password Reset</i> email</small>
          <div class="alert alert-danger"
            [hidden]="!usernameIsNull" i18n>
              Username is required
          </div>
        </div>

        <div class="form-group">
          <label for="password" i18n>Password</label>
          <input type="password" class="form-control" id="password" 
            required name="password" #password/>
          <div class="alert alert-danger"
            [hidden]="!passwordIsNull" i18n>
              Password is required
          </div>
        </div>

        <app-info-alert></app-info-alert>

        <div class="form-check mb-2">
          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
            [checked]="rememberMe" (change)="onRememberMeChange()">
          <label class="form-check-label" for="defaultCheck1" i18n>
            Remember me
          </label>
        </div>

        <button type="submit" [disabled]="loading" class="btn btn-primary" i18n>Login</button>

        
        <div *ngIf="loading">
          <i class="fa fa-circle-o-notch fa-spin fa-2x"></i>
        </div>

      </form>
     
      <div class="mt-2">
        <a routerLink="/resetpassword"i18n><i class="fa fa-key" aria-hidden="true" ></i> Don't know your password?</a>
      </div>
      <div class="mt-2">
        <a routerLink="/RequestAccount"i18n><i class="fa fa-user-plus" aria-hidden="true" ></i> Don't have an account?</a>
      </div>
      <br>
          
    </div>
    </div>
  </div>

   <div class="row justify-content-center text-center">
      <div class="col-md-9 col-lg-9 col-xl-6">
      <hr>
    </div>
  </div>

  <div class="row justify-content-center text-center mb-3">
    <div class="col-md-6 col-lg-4 col-xl-3">
           
      <p i18n>
        or<br>
        Sign in with another account
      </p>
      
      <button type="submit" (click)="goToPSLogin()" id="PSloginButton" class="loginBtn loginBtn--smarthub" i18n>
        Login with PMH
      </button>
      <small id="PSloginButtonHelp" class="form-text text-muted" i18n>Uses the same username and password</small>

     

      

    </div>
  </div> 
  
</div>

