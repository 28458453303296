import { ConnStrModule } from "./../_services/conn-str.module";
import { AuthenticationService } from "./../_services/authentication.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/take";
import { Site } from "../_models/site.model";
import { DataStorageService } from "./../_services/data-storage.service";
import Hashids from "hashids";
// import * as Hashids from "hashids";
import { v1 as uuidv1, v5 as uuidv5 } from "uuid"; // For version 5

//https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private connStrModule: ConnStrModule,
    private dataStorageService: DataStorageService
  ) {}
  site: Site;
  Sites: Site[];

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: "Bearer " + token,
      },
    });
  }
  addAuthenticationToken(req) {
    // Get access token from Local Storage
    const accessToken = this.authenticationService.getToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return req;
    }

    // We clone the request, because the original request is immutable
    return req.clone({
      setHeaders: {
        Authorization: "Bearer " + this.authenticationService.getToken(),
      },
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //req = this.addToken(req, this.authenticationService.getToken());

    if (req.url.toLowerCase().indexOf(this.connStrModule.pspBaseUrl) >= 0) {
      console.log(req.url.toString());
      let migratedSites: Site[] = JSON.parse(
        localStorage.getItem("migratedSites")
      );

      if (migratedSites) {
        this.redirectEnerlytics(migratedSites, req);
      }
    } else {
      return next.handle(req);
    }
    return <any>(
      next
        .handle(this.addToken(req, this.authenticationService.getToken()))
        .catch((error) => {
          if (req.url.includes("refreshtoken") || req.url.includes("token")) {
            //we dont want to intercept refreshes or token request
            console.log("hub is not intercepting login");
            return next.handle(req);
          }

          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== 401) {
            console.log(`error.status: ${error.status}`);

            return Observable.throw(error);
          }

          if (this.isRefreshingToken) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.tokenSubject
              .filter((result) => result !== null)
              .take(1)
              .switchMap(() => next.handle(this.addAuthenticationToken(req)));
          } else {
            this.isRefreshingToken = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.tokenSubject.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this.authenticationService
              .refreshHubToken()
              .switchMap((data: any) => {
                this.authenticationService.processHubTokens(data);
                let newToken = this.authenticationService.getToken();
                if (newToken) {
                  this.tokenSubject.next(newToken);
                  req = this.addToken(req, newToken);
                  this.isRefreshingToken = false;
                  return next.handle(req);
                }
              })
              .catch((err: any) => {
                this.isRefreshingToken = false;

                this.authenticationService.logout();
                return Observable.throw(error);
              });
          }
        })
    );
  }
  private redirectEnerlytics(migratedSites: Site[], req: HttpRequest<any>) {
    migratedSites.forEach((site) => {
      let regexp = new RegExp(`sites/${site.siteId}`);
      if (req.url.match(regexp)) {
        console.log("Site has migrated");
        let alertId = req.url.split("/")[7];
        if (alertId) {
          const HashId = new Hashids(
            "ProductionSupportSalt",
            6,
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          );
          let intAlertId = HashId.decode(alertId)[0].toString();
          let enerlyticsAlertId = uuidv5(intAlertId, site.enerlyticsId);
          window.location.href = `${this.connStrModule.enerlyticsBaseUrl}/predictive-maintenance-hub/${site.enerlyticsId}/alert/${enerlyticsAlertId}`;
        } else {
          window.location.href = `${this.connStrModule.enerlyticsBaseUrl}/predictive-maintenance-hub/${site.enerlyticsId}/alert-list`;
        }
      }
    });
  }

  logoutUser() {
    this.authenticationService.logout();
    return Observable.throw("");
  }
}
