import { DashboardsService } from "./../../_services/dashboards.service";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.css"]
})
export class CommentsComponent implements OnInit {
  @Input() comments;
  @Input() dashId;

  adding = false;

  comment = {
    newComment: ""
  };

  constructor(private dashboardsService: DashboardsService) {}

  ngOnInit() {}

  commentStr() {
    if (this.adding) {
      return "Adding...";
    }
    return "Comment";
  }

  onComment() {
    this.adding = true;
    console.log("add comment for " + this.dashId);
    console.log(this.comment);

    this.dashboardsService
      .addDashboardComment(this.dashId, this.comment.newComment)
      .subscribe(
        data => {
          if (this.comments == null) {
            this.comments = [];
          }
          this.comments.splice(0, 0, data);
          this.adding = false;
        },
        error => {
          console.log(error);
          alert("Unable to add comment");
          this.adding = false;
        }
      );

    this.comment = {
      newComment: ""
    };
  }

  onCommentCancel() {
    this.comment = {
      newComment: ""
    };
  }
}
