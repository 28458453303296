import { AdminHomeComponent } from "./admin/home/admin-home.component";
import { AuthAdminGuard } from "./_guards/auth-admin.guard";
import { MsoInterceptor } from "./_interceptors/mso.interceptor";
import { UniverseInterceptor } from "./_interceptors/universe.interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { DashboardsModule } from "./dashboards/dashboards.module";
// import { TimeAgoPipe } from './_pipes/time-ago.pipe';

import { SharedModule } from "./shared/shared.module";

import { FormattingService } from "./_services/formatting.service";
import { MsrService } from "./_services/msr.service";
import { AuthMsrGuard } from "./_guards/auth-msr.guard";
import { NotificationService } from "./_services/notification.service";
import { ReportsService } from "./_services/reports.service";
import { UserManagementService } from "./_services/user-management.service";
import { ConnStrModule } from "./_services/conn-str.module";

import { RouterModule } from "@angular/router";
//import { JwtHelperService } from "@auth0/angular-jwt";
import { DataStorageService } from "./_services/data-storage.service";
import { SitesService } from "./_services/sites.service";
import { AuthInterceptor } from "./_interceptors/auth.interceptor";
import { AlertsService } from "./_services/alerts.service";
import { AuthGuard } from "./_guards/auth.guard";
import { AuthenticationService } from "./_services/authentication.service";
import { ChartService } from "./shared/chart/chart.service";
import { DashboardsService } from "./_services/dashboards.service";
import { DropshowDirective } from "./shared/dropshow.directive";
// import { AlertHistoryComponent } from './alerts/alert-detail/alert-history/alert-history.component';
import { AppRouting } from "./app.routing";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatIconModule } from '@angular/material/icon';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule } from '@angular/material';

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./core/header/header.component";
import { DropdownDirective } from "./shared/dropdown.directive";
import { HomeComponent } from "./core/home/home.component";
import { ReportsComponent } from "./reports/reports.component";
// import { DashboardsComponent } from './dashboards/dashboards.component';
// import { DashboardsNavComponent } from './dashboards/dashboards-nav/dashboards-nav.component';
// import { DashboardDetailComponent } from './dashboards/dashboard-detail/dashboard-detail.component';
import { NotFoundComponent } from "./not-found/not-found.component";
// import { DashboardItemComponent } from './dashboards/dashboard-item/dashboard-item.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// import { DashboardStartComponent } from './dashboards/dashboard-start/dashboard-start.component';
import { SigninComponent } from "./auth/signin/signin.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from "./login/login.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component";
// import { DashboardEditComponent } from './dashboards/dashboard-edit/dashboard-edit.component';
// import { ChartDetailComponent } from './dashboards/chart-detail/chart-detail.component';
// import { ChartEditComponent } from './dashboards/chart-edit/chart-edit.component';
// import { SeriesDetailComponent } from './dashboards/series-detail/series-detail.component';
// import { AlertFileComponent } from './alerts/alert-file/alert-file.component';
// import { AlertEditComponent } from './alerts/alert-edit/alert-edit.component';
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { AccessComponent } from "./user/access/access.component";
import { PreferencesComponent } from "./user/preferences/preferences.component";
import { ManageAccountComponent } from "./user/manage-account/manage-account.component";
import { NotAuthorisedComponent } from "./not-authorised/not-authorised.component";
// import { ChartSeriesEditComponent } from './dashboards/chart-series-edit/chart-series-edit.component';

import { ChartModule } from "angular2-highcharts";
import { HighchartsStatic } from "angular2-highcharts/dist/HighchartsService";
// Import ng-dygraphs library
// import { NgDygraphsModule } from 'ng-dygraphs';
import { SiteRowComponent } from "./user/preferences/site-row/site-row.component";
import { AlertListSearchComponent } from "./alerts/alert-list-search/alert-list-search.component";
import { SignalGetterService } from "./_services/signal-getter.service";
import { FileOrderingComponent } from "./alerts/file-ordering/file-ordering.component";
import { FooterComponent } from "./core/footer/footer.component";
import { MultiChartComponent } from "./dashboards/multi-chart/multi-chart.component";
// import { ShareDashboardComponent } from './dashboards/share-dashboard/share-dashboard.component';
// import { AcceptDashboardInvitationComponent } from './dashboards/accept-dashboard-invitation/accept-dashboard-invitation.component';
// import { CommentsComponent } from './dashboards/comments/comments.component';
// import { CommentComponent } from './dashboards/comment/comment.component';
import { SiteReportsComponent } from "./reports/site-reports/site-reports.component";
import { ReportItemComponent } from "./reports/report-item/report-item.component";
import { ReportDetailComponent } from "./reports/report-detail/report-detail.component";
import { ReportAlertItemShortComponent } from "./reports/report-alert-item-short/report-alert-item-short.component";
import { NewReportComponent } from "./reports/new-report/new-report.component";
import { ReportHeaderComponent } from "./reports/report-header/report-header.component";
// import { AlertsHomeComponent } from './alerts/alerts-home/alerts-home.component';
import { ReportsHomeComponent } from "./reports/reports-home/reports-home.component";
import { NotificationComponent } from "./notifications/notification/notification.component";
import { NotificationsComponent } from "./notifications/notifications/notifications.component";
// import { AlertArchiveComponent } from './alerts/alert-archive/alert-archive.component';
import { RequestAccountComponent } from "./user/request-account/request-account.component";
import { MsrComponent } from "./msr/msr/msr.component";
import { MsrHomeComponent } from "./msr/msr-home/msr-home.component";
import { MsrDetailComponent } from "./msr/msr-detail/msr-detail.component";
import { MsrSitesComponent } from "./msr/msr-sites/msr-sites.component";
import { MsrListComponent } from "./msr/msr-list/msr-list.component";
import { MsrItemComponent } from "./msr/msr-item/msr-item.component";
// import { DashFolderComponent } from './dashboards/dash-folder/dash-folder.component';
import { FleetAlertsComponent } from "./fleet-alerts/fleet-alerts.component";
import { FleetAlertsHomeComponent } from "./fleet-alerts/fleet-alerts-home/fleet-alerts-home.component";
import { FleetAlertsKpiComponent } from "./fleet-alerts/fleet-alerts-kpi/fleet-alerts-kpi.component";
import { VideosComponent } from "./videos/videos.component";
import { TutorialVideosComponent } from "./videos/tutorial-videos/tutorial-videos.component";
import { VideoBoxComponent } from "./videos/video-box/video-box.component";
import { AlertPreferencesComponent } from "./user/alert-preferences/alert-preferences.component";
import { SiteAlertPreferencesComponent } from "./user/site-alert-preferences/site-alert-preferences.component";
import { SiteAlertPrefEditComponent } from "./user/site-alert-pref-edit/site-alert-pref-edit.component";
import { ExternalComponent } from "./login/external/external.component";
import { AlertsModule } from "./alerts/alerts.module";
import { LoadingPulseComponent } from "./core/loading-pulse/loading-pulse.component";
import { AnalystActionableComponent } from "./analyst-actionable/analyst-actionable.component";
import { ActionableHomeComponent } from "./analyst-actionable/actionable-home/actionable-home.component";
import { ActionableCompanyComponent } from "./analyst-actionable/actionable-company/actionable-company.component";
import { AdminComponent } from "./admin/admin.component";
import { AdminsComponent } from "./admin/admins/admins.component";
import { UsersComponent } from "./admin/users/users.component";
import { AddUserComponent } from "./admin/add-user/add-user.component";
import { CompaniesAdminComponent } from "./admin/companies-admin/companies-admin.component";
import { SitesAdminComponent } from "./admin/sites-admin/sites-admin.component";
import { UserSearchComponent } from "./admin/user-search/user-search.component";
import { UserAccessComponent } from "./admin/user-access/user-access.component";
import { GlobalAdminComponent } from "./admin/global-admin/global-admin.component";
import { EditUserComponent } from "./admin/edit-user/edit-user.component";
// import { registerLocaleData } from "@angular/common";  /*Set in the terminal using configuration flag during dev or build so no longer needed here*/
// import localeEn from "@angular/common/locales/en";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
export function highchartsFactory() {
  return require("highcharts");
}
// registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    DropshowDirective,
    HomeComponent,
    ReportsComponent,
    NotFoundComponent,
    SigninComponent,
    LoginComponent,
    PasswordResetComponent,
    ForgottenPasswordComponent,
    FileUploadComponent,
    AccessComponent,
    PreferencesComponent,
    ManageAccountComponent,
    NotAuthorisedComponent,
    SiteRowComponent,
    AlertListSearchComponent,
    FileOrderingComponent,
    FooterComponent,
    MultiChartComponent,
    SiteReportsComponent,
    ReportItemComponent,
    ReportDetailComponent,
    ReportAlertItemShortComponent,
    NewReportComponent,
    ReportHeaderComponent,
    ReportsHomeComponent,
    NotificationComponent,
    NotificationsComponent,
    RequestAccountComponent,
    MsrComponent,
    MsrHomeComponent,
    MsrDetailComponent,
    MsrSitesComponent,
    MsrListComponent,
    MsrItemComponent,
    FleetAlertsComponent,
    FleetAlertsHomeComponent,
    FleetAlertsKpiComponent,
    VideosComponent,
    TutorialVideosComponent,
    VideoBoxComponent,
    AlertPreferencesComponent,
    SiteAlertPreferencesComponent,
    SiteAlertPrefEditComponent,
    ExternalComponent,
    LoadingPulseComponent,
    AnalystActionableComponent,
    ActionableHomeComponent,
    ActionableCompanyComponent,
    AdminComponent,
    AdminsComponent,
    UsersComponent,
    AddUserComponent,
    CompaniesAdminComponent,
    SitesAdminComponent,
    AdminHomeComponent,
    UserSearchComponent,
    UserAccessComponent,
    GlobalAdminComponent,
    EditUserComponent

    // TitledTimeAgoPipe,
    // TimeAgoPipe,
  ],
  imports: [
    NgbModule,
    SharedModule,
    BrowserModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production
    }),
    AppRouting,
    FormsModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    //HttpModule,
    RouterModule,
    // NgDygraphsModule,
    // ChartModule.forRoot(require('highcharts'))
    ChartModule,
    AlertsModule,

    DashboardsModule
  ],
  exports: [],
  providers: [
    // CheckForUpdateService,
    // LogUpdateService,
    // PromptUpdateService,
    AlertsService,
    DashboardsService,
    ChartService,
    AuthenticationService,
    AuthGuard,
    AuthAdminGuard,
    AuthMsrGuard,
    SitesService,
    DataStorageService,
    //JwtHelperService,
    ConnStrModule,
    UserManagementService,
    SignalGetterService,
    ReportsService,
    NotificationService,
    MsrService,
    FormattingService,

    // interceptors are executed in the order they appear here
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniverseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsoInterceptor,
      multi: true
    },
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
