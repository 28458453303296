import { Component, OnInit } from "@angular/core";
import { InfoAlertService } from "./../_services/info-alert.service";
import { AuthenticationService } from "./../_services/authentication.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { throwError } from "rxjs";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.css"]
})
export class PasswordResetComponent implements OnInit {
  model = {
    passwordA: "",
    passwordB: ""
  };
  loading = false;
  codeOk = false;
  otherError = false;
  resetId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private infoAlertService: InfoAlertService
  ) {}

  ngOnInit() {
    // reset login status
    this.authenticationService.logout(true);

    //get user's password reset link
    this.route.params.subscribe((params: Params) => {
      this.resetId = params["resetId"];
    });
    this.checkResetLink();
  }

  checkResetLink() {
    this.loading = true;
    this.authenticationService.checkResetLink(this.resetId).subscribe(
      data => {
        if (data.status === 200) {
          this.loading = false;
          this.otherError = false;
          this.codeOk = true;
        } else {
          throwError(new Error(data.body));
        }
      },
      error => {
        this.otherError = true;
        this.infoAlertService.error(error.error);
        this.loading = false;
        this.codeOk = false;
        if (error.error.indexOf("expired") >= 0) {
          this.otherError = false;
        }
      }
    );
  }

  SubmitReset() {
    if (this.model.passwordA != this.model.passwordB) {
      return;
    }
    this.loading = true;
    this.authenticationService
      .resetPassword(this.resetId, this.model.passwordA)
      .subscribe(
        data => {
          console.log({ data });

          this.infoAlertService.success(data);
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 1500);
          this.loading = false;
        },
        error => {
          this.infoAlertService.error(error);
          this.loading = false;
        }
      );
  }
}
