import { SeverityTextPipe } from './../../../_pipes/severity-text.pipe';
import { StatusTextPipe } from './../../../_pipes/status-text.pipe';
import { AlertHistory } from './../../../_models/alert-history.model';
import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.css']
})
export class AlertHistoryComponent implements OnInit {
  @Input() alertHistory: AlertHistory;
  constructor() { }

  ngOnInit() {
  }

  toHistoryTitle() {
    switch(this.alertHistory.fieldChanged.toLowerCase()) {
      case 'name': { 
        return "Alert Renamed";
      } 
      case 'severity': { 
        return "Severity Changed";
      } 
      case 'status': { 
        return "Status Changed";
      } 
      case 'recommendation': { 
        return 'New Recommendation';
      } 
      case 'feedback': { 
        return 'New Feedback';
      } 
      case 'summary': { 
        return 'New Summary';
      } 
      default: { 
         return this.alertHistory.fieldChanged
      } 
   } 
  }

  toHistoryContent() {
    switch(this.alertHistory.fieldChanged.toLowerCase()) {
      case 'name': { 
        return this.alertHistory.newContent;
      } 
      case 'severity': { 
        return new SeverityTextPipe().transform(this.alertHistory.newContent);
      } 
      case 'status': { 
        return new StatusTextPipe().transform(this.alertHistory.newContent);
      } 
      case 'recommendation': { 
        return this.alertHistory.newContent;
      } 
      case 'feedback': { 
        return this.alertHistory.newContent;
      } 
      case 'summary': { 
        return this.alertHistory.newContent;
      } 
      default: { 
         return this.alertHistory.newContent;
      } 
   }
  }

  severity() {
    switch(this.alertHistory.currentSeverity) {
      case '10': { 
        return "severity10";
      } 
      case '20': { 
        return "severity20";
      } 
      case '30': { 
        return "severity30";
      } 
      case '40': { 
        return "severity40";
      } 
      case '50': { 
        return "severity50";
      } 
      case '60': { 
        return "severity60";
      } 
      default: { 
         return "severityNeutral";
      }
   }
  }

}
