<h2>Manage Admins</h2>
<small class="text-muted">
  Only trusted users who are authorised to see everything on PMH should be admins.
  <br> Admins have the ability to make other users admins, see all PMH users, and change access rights.
</small>
<hr>

<div class="row">
  <div class="col-md-6 my-3">

    <app-user-search (userSelected)="searchedUserSelected($event)"></app-user-search>
  
  </div>
  <div class="col-md-6 my-3">

    <div class="card" style="max-width: 650px;">

      <div class="card-header py-1">
        <b>Pending admins</b> <small class="text-muted">(must be added before leaving this page)</small>
      </div>

      <div class="card-body">

        <ul class="list-group" style="max-height: 400px; overflow: auto; max-width: 650px;">
          <li *ngFor="let user of pendingAdmins" class="list-group-item flex-column align-items-start py-1">
            <div class="d-flex w-100 mb-0 justify-content-between">
              <p class="mb-0" title="Name">{{user.name}}</p>
              <small title="Username" class="text-muted">{{user.username}}</small>
            </div>
            <div class="d-flex w-100 mb-0 justify-content-between">
              <small class="text-muted" title="Email address">{{user.email}}</small>
              <button class="btn btn-sm btn-link text-danger" (click)="removePending(user)">remove</button>
            </div>
          </li>
        </ul>

        <button class="btn btn-success mt-1" (click)="addAdmins()" [disabled]="pendingAdmins.length==0">
          <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!adding"></i> Add to Admins
        </button>
      </div>
    </div>    
    
    <div class="card mt-3" style="max-width: 650px;">

      <div class="card-header py-1">
        <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!loadingAdmins"></i> 
        <b>Admins</b> 
        <span class="text-danger" [hidden]="!adminLoadError"><i class="fa fa-exclamation-triangle"></i> loading error</span>
      </div>

      <div class="card-body">
        <ul class="list-group" style="max-height: 400px; overflow: auto; max-width: 650px;">
          <li *ngFor="let user of admins" class="list-group-item flex-column align-items-start py-1">
            <div class="d-flex w-100 mb-0 justify-content-between">
              <p class="mb-0" title="Name">{{user.name}}</p>
              <small title="Username" class="text-muted">{{user.username}}</small>
            </div>
            <div class="d-flex w-100 mb-0 justify-content-between">
              <small class="text-muted" title="Email address">{{user.email}}</small>
              <button class="btn btn-sm btn-link text-danger" (click)="removeAdmin(user)">remove</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


