import { DashboardsService } from './../../_services/dashboards.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Dashboard } from './../../_models/dashboard.model';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboards-nav',
  templateUrl: './dashboards-nav.component.html',
  styleUrls: ['./dashboards-nav.component.css']
})
export class DashboardsNavComponent implements OnInit {
  sidebarSubscription: Subscription;
  dashboardSubscription: Subscription;
  // collapseNavbar = true;
  loading = true;

  // dashboards: Dashboard[];
  dashboards;

  navShowing: boolean;

  constructor(
    private dashboardsService: DashboardsService,
    private router: Router,
    private route: ActivatedRoute,
    // private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.dashboards = {};
    this.dashboards['folders'] = [];
    this.dashboards['items'] = [];
    this.dashboards['name'] = '';

    // this.dashboardsService.moveDashboardClick
    //   .subscribe(
    //     data => {
    //       this.modalService.open(null).result.then((result) => {
    //         // this.closeResult = `Closed with: ${result}`;
    //       }, (reason) => {
    //         // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //       });
    //     }
    //   )

    this.sidebarSubscription = this.dashboardsService.sidebarChanged
      .subscribe(
        (sidebarCollapsed: boolean) => {
          this.navShowing = sidebarCollapsed;
          // change if navbar shown 
          // this.collapseNavbar = sidebarCollapsed;
        }
      )

      // this.dashboardSubscription = this.dashboardsService.dashboardsChanged
      this.dashboardSubscription = this.dashboardsService.folderedDashboardsChanged
      .subscribe(
        (data) => {
          // change if navbar shown 
          console.log("Dashboard change detected")
          this.dashboards = data;
          this.loading = false; 
        }
      )

      // this.collapseNavbar = this.dashboardsService.collapseSidebar;
      // this.dashboardsService.loadDashboards();
      this.dashboardsService.loadStructuredDashboards();
  }

  newFolder() {
    let newFolderName = prompt("Folder name", "New Folder");
        
    if (newFolderName == null){
      return;
    }

    if (newFolderName == ''){
      return;
    }

    newFolderName = newFolderName.replace(/\//g," ");

    console.log("new folder: " + newFolderName);
    this.dashboardsService.addFolder('', newFolderName);
  }

  saveFolders(){
    this.dashboardsService.saveFolderStructure();
  }

}
