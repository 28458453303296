import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MsrService } from './../../_services/msr.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-msr-detail',
  templateUrl: './msr-detail.component.html',
  styleUrls: ['./msr-detail.component.css']
})
export class MsrDetailComponent implements OnInit {

  siteId = "GRCHP";
  itemId = "demo"
  loading = true;

  chartEoh;
  eohOptions: Object;

  plantItemSubscription:Subscription = new Subscription;

  plantMsr;

  eoh = 12650;
  totalEoh = 40000;
  eohSeries = [];
  detailsCollapsed = true;
  sphericalCollapsed = true;
  treeCollapsed = true;
  sapCollapsed = true;

  constructor(
    private msrService: MsrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(){
    this.plantItemSubscription.unsubscribe();
  }

  ngOnInit() {

    this.plantMsr = {
      plantStrategy: {
        cbmGate1_FailureReason: ''
      }
  }

    this.plantItemSubscription = this.msrService.plantItemChanged
      .subscribe(
        data => {
          this.plantMsr = data;

          // sort notification order high to low
          if(this.plantMsr['notifications'] != null){
            this.plantMsr['notifications'].sort(function(a, b){
              if(a.priorty < b.priorty) return -1;
              if(a.priorty > b.priorty) return 1;
              return 0;
            })
          }
        
          this.loading = false;
          
        },
        error => {
          alert("Error loading plant item");
          this.loading = false;
          console.log(error);
          if (error['status'] != null && error['status'] == 403){
            this.router.navigate(['/403']);
          } else {
            this.router.navigate(['../'], {relativeTo: this.route});
          }
          
        }
      )

    this.eohOptions = {
      chart: {
        type: 'pie',
        marginTop: 0,
        reflow: true,
      },
      title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: -5,
          style: {
            fontSize: '1.5rem',
            color:'#495057'
          }
      },
      subtitle: {
          text: ''
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,             
          }
            // showInLegend: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: 'EOH',
        innerSize: '70%',
        data: []
      }],
  };

  this.msrService.requestPlantItem(this.siteId,this.itemId);

  }

  setChartTitle(){    
    console.log("chart title");
    let eohPercent = ((this.plantMsr['maxEOH'] - this.plantMsr['currentEOH']) / this.plantMsr['maxEOH']) * 100;
    console.log(eohPercent);

    this.chartEoh.setTitle({
      text: eohPercent.toPrecision(2) + '%<br>Remaining',
      align: 'center',
      verticalAlign: 'middle',
      y: -5,
      style: {
        fontSize: '1.5rem',
        color:'#495057'
      }
  })
  }

  cbmConfidence(){
    if(this.plantMsr != null){
      return this.plantMsr['plantStrategy']['maintenanceStrategyDecision']['cbmConfidence'] * 100;
    }
  }

  buildEohPie() {
console.log("building pie")
    this.eohSeries.push(
      {
        name: "Used",
        y: this.eoh,
        color: "#203351",
      }
    );
    this.eohSeries.push(
      {
        name: "Remaining",
        y: (this.totalEoh - this.eoh),
        color: "#38993f",
      }
    );
    this.chartEoh['series'][0].setData(this.eohSeries);
    
  }


  saveInstanceEoh(chartInstance) {
    console.log("save instance")
    this.chartEoh = chartInstance;
    this.buildEohPie();
    this.setChartTitle();
  }

}
