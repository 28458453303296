import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router )  { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const jwtHelper = new JwtHelperService;
        if (localStorage.getItem('currentUser')) {

            if (jwtHelper.isTokenExpired(localStorage.getItem('currentUser'))) {
                           
                 console.log("Token expired");
            } else {
                return true; // logged in so return true
            }
               
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}