import { SitesService } from './../../_services/sites.service';
import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies-admin',
  templateUrl: './companies-admin.component.html',
  styleUrls: ['./companies-admin.component.css']
})
export class CompaniesAdminComponent implements OnInit {

  loadingCompanies = true;
  loadingUsers = false;
  usersLoadError = false;
  selectedCompany;
  selectedRole;
  adminLoadError = false;
  companies = [];
  users = [];
  pendingUsers = [];
  adding = false;

  selectedTab = 'company';

  constructor(
    private userManagementService: UserManagementService,
    private sitesService: SitesService
  ) { }

  ngOnInit() {

    this.sitesService.getAllCompanies().subscribe(
      data => {
        this.companies = data;
        this.loadingCompanies = false;
      }, error => {
        alert("Unable to get companies list. Error: " + error.error);
        this.loadingCompanies = false;
      }
    )
  }


  filterUserRoles(accessGiven){
    if (!accessGiven){
      return [];
    } 
    if (!accessGiven.Companies) {
      return [];
    }
    for (const accessCompany of accessGiven.Companies) {
      if (accessCompany.Name.toLowerCase() == this.selectedCompany.name.toLowerCase()){
        return accessCompany.Roles;
      }
    }
  }

  companyChanged(){
    this.users = [];
  }

  loadUsers(){
    this.loadingUsers = true;
    this.usersLoadError = false;
    this.users = [];
    console.log(this.selectedCompany);
    this.userManagementService.getUsers("company", this.selectedCompany['name']).subscribe(
      data => {
        for (const user of data) {
          user['accessGiven'] = JSON.parse(user['accessGiven']);
        }
        this.users = data;
        console.log("users", data)
        this.loadingUsers = false;
        this.usersLoadError = false;
      }, error => {
        this.usersLoadError = true;
        this.loadingUsers = false;
      }
    )
  }

  searchedUserSelected(user){
    console.log("User selected", user);
    if (this.pendingUsers.findIndex(x => x.username == user.username) == -1){
      this.pendingUsers.push(user);
    }
  }

  addUsers(){
    this.adding = true;
    var userIds = [];
    this.pendingUsers.forEach(element => {
      userIds.push(element.intId);
    });
    this.userManagementService.addUsers(userIds,"company", this.selectedCompany['name'], this.selectedRole).subscribe(
      data => {
        for (const pendingUser of this.pendingUsers) {
          if (this.users.findIndex(x => x.intId == pendingUser.intId) == -1){
            this.users.push(pendingUser);
          }
        };
        this.pendingUsers = [];
        this.adding = false;
      }, error => {
        this.adding = false;
        alert("Error adding user: " + error.error);        
      }
    )

  }

  removeUser(user){
    if (confirm(`Remove company user permissions for ${user['name']}?`)){

      this.userManagementService.removeUser(user.intId, "company", this.selectedCompany['name']).subscribe(
        data => {
          for (let index = this.users.length - 1; index >= 0; index--) {
            const element = this.users[index];
            if (element.username == user.username){
              this.users.splice(index, 1);
            }        
          }          
        }
      )
    }
  }
  removePending(user){
    for (let index = this.pendingUsers.length - 1; index >= 0; index--) {
      const element = this.pendingUsers[index];
      if (element.username == user.username){
        this.pendingUsers.splice(index, 1);
      }        
    }
    
  }

}
