<div *ngIf="alertCountStr() != '0' && company.name != 'Demo'">
  <div class="row justify-content-center">
    <div class="col text-center">
      <div *ngIf="loading">
        <h1 i18n>Loading {{ company.name }} data</h1>
        <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <!-- LEFT COLUMN -->
    <div class="col-lg-3">
      <h4 class="display-4">{{ company.name }}</h4>
      <h5 i18n *ngIf="!isBridgehead()">
        {{ alertCountStr() }} Alert<span [hidden]="alertCountStr() == '1'"
          >s</span
        >
      </h5>
    </div>

    <!-- CENTRE COLUMN -->

    <div class="col-lg-6">
      <div class="list-group">
        <div *ngFor="let siteElement of fleetAlertData">
          <div *ngIf="siteElement.alerts.length > 0">
            <app-alert-item-short
              *ngFor="
                let alertElement of siteElement.alerts | myfilter: filterArgs
              "
              [alert]="alertElement"
              [siteId]="siteElement.siteId"
            ></app-alert-item-short>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT COLUMN -->
    <div class="col-lg-3"></div>
  </div>

  <hr />
</div>
