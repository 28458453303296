
<a [routerLink]="['./..']">
  &larr; Back to Sites</a> <br>
  
  <div class="col text-center">
    <div class="row justify-content-center">
      <h4> - PLACEHOLDER DATA - </h4>
    </div>
  </div>

  <div *ngIf="site != null" class="row justify-content-center">

      <div class="col-md-3">
        <h4 class="display-4">{{ site.name }}</h4>
        <h5>
          <strong id="live">Live: </strong>
          <i class="fa fa-thermometer-half" aria-hidden="true"></i> {{temperature}} &nbsp; | &nbsp;
          <i class="fa fa-flag-o" aria-hidden="true"></i> {{wind}}
        </h5>
    
      </div>
    
      <div class="col-md-6">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="styles">
          <agm-marker *ngIf="lat!=0" [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
        <!-- <small *ngIf="isAcmUser" (click)="setLongLatZoom()" class="pull-right text-muted"><i class="fa fa-map-marker" aria-hidden="true"></i> Edit location</small> -->
      </div>
    
      <div class="col-md-3">
        <!-- placeholder for right-side column -->
      </div>
    </div>

  <div class="row justify-content-center">
  <div class="col-md-6">

    <form>
      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="filterStr" name="filterStr" placeholder="Filter Plant Items">
      </div>
    </form>
    
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-lg-6 col-md-6">

    <div *ngIf="plantItems.length == 0 && loading == false" class="text-center">
      <h4>No Plant Items</h4>
    </div>

    <div *ngIf="plantItems.length > 0" class="list-group">
      <app-msr-item *ngFor="let anItem of plantItems | filterAny:filterStr" [plantItem]="anItem" [siteId]="site.siteId"></app-msr-item>
    </div>

  </div>
</div>