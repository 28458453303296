import { AlertRecipientsComponent } from './alert-recipients/alert-recipients.component';
import { MoonPhaseComponent } from './../moon-phase/moon-phase.component';
import { FormsModule } from '@angular/forms';
import { AlertItemShortComponent } from './alert-item-short/alert-item-short.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsRoutingModule } from './alerts.routing';

import { AlertsComponent } from './alerts.component';
import { AlertsHomeComponent } from './alerts-home/alerts-home.component';
import { AlertArchiveComponent } from './alert-archive/alert-archive.component';
import { AlertEditComponent } from './alert-edit/alert-edit.component';
import { AlertFileComponent } from './alert-file/alert-file.component';
import { AlertHistoryComponent } from './alert-detail/alert-history/alert-history.component';
import { AlertItemComponent } from './alert-item/alert-item.component';
import { AlertDetailComponent } from './alert-detail/alert-detail.component';
import { AlertListComponent } from './alert-list/alert-list.component';
import { OpenAlertsComponent } from './open-alerts/open-alerts.component';



@NgModule({
  imports: [
    CommonModule,
    AlertsRoutingModule,
    SharedModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    AlertsComponent,
    AlertListComponent,
    AlertDetailComponent,
    AlertHistoryComponent,
    AlertFileComponent,
    AlertEditComponent,
    AlertArchiveComponent,
    AlertsHomeComponent,
    MoonPhaseComponent,
    AlertRecipientsComponent,
    OpenAlertsComponent
    
  ]
})
export class AlertsModule { }
