import { DashboardsService } from './../../_services/dashboards.service';
import { Dashboard } from './../../_models/dashboard.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.css']
})
export class DashboardItemComponent implements OnInit {
  @Input() dashboard;

  constructor(
    private dashboardsService: DashboardsService
  ) { }

  ngOnInit() {
  }

  hideNav(){
    this.dashboardsService.hideSidebar();
  }

  folderSelecter(){
    // this.dashboardsService.startMoveDash();
  }

}
