<div class="row">
  <div class="col">
    <div class="card m-2">
      <div class="card-body">
            
        <div *ngIf="loading">
          <h3 i18n>Loading...</h3>
        </div>

        <div *ngIf="!loading && loadedOk">

          <h3 i18n>Open alerts for selected unit / area</h3>
          <app-alert-item-short *ngFor="let alert of filteredAlerts()"
            [alert]="alert"></app-alert-item-short>

          <hr>

          <h3 i18n>Other open alerts</h3>
          <app-alert-item-short *ngFor="let alert of remAlerts"
            [alert]="alert"></app-alert-item-short>

        </div>

      </div>
    </div>
  </div>
</div>