
<h1 class="display-4">{{videoTitle}}</h1>

<div *ngIf="videoUrl != null">
  <video preload="metadata" src="{{videoUrl.videoUrl}}" controls poster="{{videoUrl.thumbnailUrl}}" class="img-fluid">
      Your browser does not support HTML5 video
  </video>
</div>

<!-- while getting video url -->
<div class="text-center" *ngIf="loading">          
  <div>
    <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
    <br>
    <small>Loading video</small>
  </div>
</div>

<!-- in case of error -->
<div class="text-center text-danger" *ngIf="videoError">          
  <div>
    <i class="fa fa-times fa-5x"></i>
    <br>
    <small>Unable to load video</small>
  </div>
</div>