<h2>Add User</h2>
<small class="text-muted">
  Enter the details for a new user. User access rights are set after users are created. <br>
  <b>Using an existing username will overwrite the user with that username.</b>
</small>
<hr>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6">

      <form (ngSubmit)="onSubmit()" #userForm="ngForm" style="max-width: 650px;" autocomplete="off">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" id="name"
            required autocomplete="off" 
            [(ngModel)]="model.name" name="name"
            #name="ngModel">
          <div [hidden]="name.valid || name.pristine"
                class="text-danger">
            Name is required
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" email class="form-control" id="email"
            required autocomplete="off" 
            [(ngModel)]="model.email" name="email" 
            #email="ngModel">
          <div [hidden]="email.valid || email.pristine"
                class="text-danger">
            Email is required
          </div>
        </div>

        <div class="form-group">
          <label for="username">Username</label>    
          <input type="text" class="form-control" id="username"
            required autocomplete="off" 
            [(ngModel)]="model.authId" name="username" 
            #username="ngModel">
          <small class="text-muted">Must be unique. Case insensitive. KIDs for Uniper employees, email addresses for external.</small>
          <div [hidden]="username.valid || username.pristine"
                class="text-danger">
            Username is required
          </div>
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!userForm.form.valid || submitted">
            <i class="fa fa-spinner fa-spin fa-fw" [hidden]="!adding"></i> Submit
          </button>
        <button type="button" class="btn btn-default" (click)="newUser(); userForm.reset()">New User</button>
      </form>      
    </div>
    <div class="col-lg-6">
            
      <h4>Users added:</h4>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let user of addedUsers">{{user.name}} | <span class="text-muted">{{user.email}}</span> | {{user.authId}}</li>
      </ul>
      
    </div>
  </div>
</div>

