import { SitesService } from './../../_services/sites.service';
import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sites-admin',
  templateUrl: './sites-admin.component.html',
  styleUrls: ['./sites-admin.component.css']
})
export class SitesAdminComponent implements OnInit {

  loadingSites = true;
  loadingUsers = false;
  usersLoadError = false;
  selectedSite;
  selectedRole;
  adminLoadError = false;

  sites = [];
  users = [];
  pendingUsers = [];
  adding = false;

  constructor(
    private userManagementService: UserManagementService,
    private sitesService: SitesService
  ) { }

  ngOnInit() {

    this.sitesService.getAllSites().subscribe(
      data => {
        console.log("AllSites", data);
        this.sites = data;
        this.loadingSites = false;
      }, error => {
        alert("Unable to get sites list. Error: " + error.error);
        this.loadingSites = false;
      }
    )
  }


  filterUserRoles(accessGiven){
    if (!accessGiven){
      return [];
    } 
    if (!accessGiven.Companies) {
      return [];
    }
    for (const accessCompany of accessGiven.Companies) {
      for (const accessSite of accessCompany.Sites) {
        if (accessSite.Name.toLowerCase() == this.selectedSite.siteId.toLowerCase()){
          return accessSite.Roles;
        }
      }
    }
  }

  siteChanged(){
    this.users = [];
  }

  loadUsers(){
    this.loadingUsers = true;
    this.usersLoadError = false;
    this.users = [];
    console.log(this.selectedSite);
    this.userManagementService.getUsers("site", this.selectedSite['siteId']).subscribe(
      data => {
        for (const user of data) {
          user['accessGiven'] = JSON.parse(user['accessGiven']);
        }
        this.users = data;
        console.log("users", data)
        this.loadingUsers = false;
        this.usersLoadError = false;
      }, error => {
        this.usersLoadError = true;
        this.loadingUsers = false;
      }
    )
  }

  searchedUserSelected(user){
    console.log("User selected", user);
    if (this.pendingUsers.findIndex(x => x.username == user.username) == -1){
      this.pendingUsers.push(user);
    }
  }

  addUsers(){
    this.adding = true;
    var userIds = [];
    this.pendingUsers.forEach(element => {
      userIds.push(element.intId);
    });
    this.userManagementService.addUsers(userIds,"site", this.selectedSite['siteId'], this.selectedRole).subscribe(
      data => {
        // for (const pendingUser of this.pendingUsers) {
        //   if (this.users.findIndex(x => x.intId == pendingUser.intId) == -1){
        //     this.users.push(pendingUser);
        //   }
        // };
        // this.pendingUsers = [];
        this.adding = false;
        this.loadUsers();
      }, error => {
        this.adding = false;
        alert("Error adding user: " + error.error);        
      }
    )

  }

  removeUser(user){
    if (confirm(`Remove site user permissions for ${user['name']}?`)){

      this.userManagementService.removeUser(user.intId, "site", this.selectedSite['siteId']).subscribe(
        data => {
          for (let index = this.users.length - 1; index >= 0; index--) {
            const element = this.users[index];
            if (element.username == user.username){
              this.users.splice(index, 1);
            }        
          }          
        }
      )


    }
  }
  removePending(user){
    for (let index = this.pendingUsers.length - 1; index >= 0; index--) {
      const element = this.pendingUsers[index];
      if (element.username == user.username){
        this.pendingUsers.splice(index, 1);
      }        
    }
    
  }

}
