<div class="mb-5">


  <!-- <div class="row">
    <div class="col">
      <button type="button" class="btn btn-primary d-block d-md-none btn-block" (click)="toggleSidebar()">
        Show / Hide Dashboards
      </button>
    </div>
  </div> -->

  <div *ngIf="loading; else showDash" class="row justify-content-center">
    <div>
      <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
    </div>
  </div>

  <ng-template #showDash>

  <div class="row">
    <div class="col">
      <h1>{{ dashboard.name }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col">
      {{ dashboard.description }}
    </div>
  </div>

<div class="row">
  <div class="col">
    <a class="btn btn-outline-primary btn-sm" [routerLink]="'edit'" role="button" i18n><i class="fa fa-pencil" aria-hidden="true" ></i> Edit Dashboard</a>
    <a class="btn btn-outline-danger btn-sm" (click)="deleteDashboard()" role="button"i18n><i class="fa fa-trash" aria-hidden="true" ></i> Delete Dashboard</a>
    <a class="btn btn-outline-success btn-sm" [routerLink]="'share'" role="button"i18n><i class="fa fa-share-alt" aria-hidden="true" ></i> Share Dashboard</a>
  </div>
</div>
  

<div class="row mt-3">

  <div class="col-sm-4">
      <label i18n>From</label> 
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                name="dFrom" [(ngModel)]="dates.from" ngbDatepicker #dFrom="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="dFrom.toggle()" type="button">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <label>To</label> 
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                name="dTo" [(ngModel)]="dates.to" ngbDatepicker #dTo="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="dTo.toggle()" type="button">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    <button (click)="onSetDates()" class="btn btn-success"i18n>Set dates</button>
  </div>

</div>

  <h5 class="mt-3" i18n>Daterange: {{dateFrom | date:'mediumDate'}} -  {{dateTo | date:'mediumDate'}}</h5>

  <!-- <pre>Model: {{ dates | json }}</pre> -->

  <hr>
    
  <div class="row">
    <div class="col">

      <app-chart *ngFor="let aChart of dashboard.charts" [myChart]=aChart></app-chart>

      <a class="btn btn-success pull-right" [routerLink]="'chart/new'" role="button" i18n>Add Chart</a>
      
    </div>
  </div>
  
  <hr>

  <div class="mt-2">
    <app-comments [comments]="comments" [dashId]="dashId"></app-comments>
  </div>


</ng-template>


</div>