import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severityText',
  pure: true
})
export class SeverityTextPipe implements PipeTransform {

  transform(severityNumber: string): any {

    switch(severityNumber) {
        case '10': { 
          return 'Significant indications of damage requiring immediate action';
        } 
        case '20': { 
          return 'Plant operating with suspected or known fault with signs of slow degradation';
        } 
        case '30': { 
          return 'Deviations have been noted. Close monitoring being applied';
        } 
        case '40': { 
          return 'Communication / instrumentation - no data';
        } 
        case '50': { 
          return 'Plant unavailable';
        } 
        case '60': { 
          return 'Satisfactory';
        } 
        default: { 
           return 'Unkown severity: ' + severityNumber;
        } 
     }
  }

}
