<div class="jumbotron jumbotron-fluid">
  <div class="container">

    <h1 class="display-3" i18n>
      <i class="fa fa-video-camera"></i> PMH Videos
    </h1>
    <p class="lead" i18n>Quick introductions to the Predictive Maintenance Hub</p>

  </div>
</div>

<div class="row justify-content-center mb-5">

  <!-- LEFT COLUMN -->
  <div class="col-lg-3">
  </div>

  <!-- CENTRE COLUMN -->
  <div class="col-lg-6">

    <div class="row mb-5">
      <div class="col">
        <app-video-box [videoName]="'ACMOverview720en.mp4'" [videoTitle]="'Overview'"></app-video-box>
      </div>
    </div>

    <hr>

    <div class="row mb-5">
      <div class="col">
        <app-video-box [videoName]="'ViewAlerts720.mp4'" [videoTitle]="'Alerts'"></app-video-box>
      </div>
    </div>

    <hr>

    <div class="row mb-5">
      <div class="col">
        <app-video-box [videoName]="'ACMAlertPreferences720.mp4'" [videoTitle]="'Alert Preferences'"></app-video-box>
      </div>
    </div>

    <hr>

    <div class="row mb-5">
      <div class="col">
        <app-video-box [videoName]="'ACMDashboardFinal720p.mp4'" [videoTitle]="'Dashboards'"></app-video-box>
      </div>
    </div>

  </div>

  <!-- RIGHT COLUMN -->
  <div class="col-lg-3">
  </div>


</div>
