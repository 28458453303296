import { PspUser } from './../_models/user.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pspUser',
  pure: true
})
export class PspUserPipe implements PipeTransform {

  transform(aUser: PspUser): any {
    
      return `<a href="mailto:${aUser.email}">${aUser.name}</a>`
  }

}
