import { DashboardsService } from './../_services/dashboards.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {
  
  _opened: boolean;

  constructor(
    private dashboardsService: DashboardsService
  ) { }

  ngOnInit() {
    this.dashboardsService.sidebarChanged
      .subscribe(
        data => {
          console.log("toggling sidebar")
          this._opened = data;
        }
      ); 
  }

  toggleNav(){
    console.log("toggle nav click")
    this.dashboardsService.toggleSidebar();
  }

}