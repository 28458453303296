import { AlertsService } from './../../_services/alerts.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Alert } from './../../_models/alert.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.css']
})
export class AlertItemComponent implements OnInit {
  @Input() alert: Alert;
  @Input() siteId: string = "";
  alertId: string;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertsService: AlertsService) { }

  ngOnInit() {
    // console.log(this.route)
    this.route.params
    .subscribe(
      (params: Params) => {
        this.alertId = params['alertId'];
        if (params['siteId'] != null){
          this.siteId = params['siteId'];          
        } 
      }
    );
  }

  severity() {
    switch(this.alert.severity.id) {
      case 10: { 
        return "severity10";
      } 
      case 20: { 
        return "severity20";
      } 
      case 30: { 
        return "severity30";
      } 
      case 40: { 
        return "severity40";
      } 
      case 50: { 
        return "severity50";
      } 
      case 60: { 
        return "severity60";
      } 
      default: { 
         return "severityNeutral";
      } 
   } 
  }

  MaintenanceOrderNum(){
    if(this.alert['notes'] == null){
      return ""
    }
    var maintNote = this.alert['notes'].find( x => x.name == 'Maintenance Order Number');
    if (maintNote == null){
      return "";      
    }

      if (maintNote['description'].length == 0){
        return "";
      }
      
      return maintNote['description']
  }

  NotificationNum(){
    if(this.alert['notes'] == null){
      return ""
    }
    var maintNote = this.alert['notes'].find( x => x.name == 'Notification Number');
    if (maintNote == null){   
      return "";      
    }

    if (maintNote['description'].length == 0){         
      return "";
    }
    
    return maintNote['description'];
  }

}
