import { Component, OnInit, Input } from '@angular/core';

import * as SunCalc from 'suncalc';

@Component({
  selector: 'app-moon-phase',
  templateUrl: './moon-phase.component.html',
  styleUrls: ['./moon-phase.component.css']
})
export class MoonPhaseComponent implements OnInit {

  @Input() lat: number = 90
  @Input() long: number = 0;


  constructor() { }

  ngOnInit() {
  }

  moonPhase(){
    let moonInfo = SunCalc.getMoonIllumination(Date.now());
    let phase = moonInfo['phase'];
    this.moonAngle();
    return phase;
  }

  moonImg(){
    return '../../assets/moon/' + Math.floor(this.moonPhase() * 25) + '.png';
  }

  phaseDesc(){
    if(this.moonPhase() < ((1/8) * 1)){
      return "New Moon";
    };
    if(this.moonPhase() < ((1/8) * 2)){
      return "Waxing Crescent";
    };
    if(this.moonPhase() < ((1/8) * 3)){
      return "First Quarter";
    };
    if(this.moonPhase() < ((1/8) * 4)){
      return "Waxing Gibbous";
    };
    if(this.moonPhase() < ((1/8) * 5)){
      return "Full Moon";
    };
    if(this.moonPhase() < ((1/8) * 6)){
      return "Waning Gibbous";
    };
    if(this.moonPhase() < ((1/8) * 7)){
      return "Last Quarter";
    };
    if(this.moonPhase() <= ((1/8) * 8)){
      return "Waning Crescent";
    };
  }

  myRotation = {
    'transform': 'rotate(0deg)'
  }

  moonAngle(){
    let positionInfo = SunCalc.getMoonPosition(Date.now(), this.lat, this.long);
    let angle = (SunCalc.getMoonIllumination(Date.now())['angle'] * 180) / Math.PI;
    let parallacticAngle = (positionInfo['parallacticAngle'] * 180) / Math.PI
    let zenithAngle = -(angle - parallacticAngle + 90);

    // console.log("angle = " + angle)
    // console.log("parallacticAngle = " + parallacticAngle)
    // console.log("rotation = " + zenithAngle)
    // console.log("")
    this.myRotation = {
     'transform': 'rotate(' + zenithAngle + 'deg)'
    }
  }


}
