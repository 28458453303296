import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {

  constructor(
    private userManagementService: UserManagementService
  ) { }

  pendingAdmins = [];
  admins = [];

  adding = false;
  loadingAdmins = false;
  adminLoadError = false;

  ngOnInit() {
    this.GetAdmins();
  }

  GetAdmins(){    
    this.loadingAdmins = true;
    this.adminLoadError = false;
    this.userManagementService.getAdmins().subscribe(
      data => {
        this.admins = data;
        this.loadingAdmins = false;
        this.adminLoadError = false;
      }, error => {
        this.admins = [];
        this.loadingAdmins = false;
        this.adminLoadError = true;        
      }
    )
  }

  searchedUserSelected(user){
    console.log("User selected", user);
    if (this.pendingAdmins.findIndex(x => x.username == user.username) == -1){
      this.pendingAdmins.push(user);
    }
  }

  addAdmins(){
    this.adding = true;
    var adminIds = [];
    this.pendingAdmins.forEach(element => {
      adminIds.push(element.intId);
    });
    this.userManagementService.addAdmins(adminIds).subscribe(
      data => {
        // add users to admin box
        for (const pendingAdmin of this.pendingAdmins) {
          if (this.admins.findIndex(x => x.intId == pendingAdmin.intId) == -1){
            this.admins.push(pendingAdmin);
          }
        };
        this.pendingAdmins = [];
        this.adding = false;
      }, error => {
        this.adding = false;
        alert("Error adding admins: " + error.error);        
      }
    )



    setTimeout( ()=> {
    },1000)
  }

  removeAdmin(user){
    if (confirm(`Remove admin rights for ${user['name']}?`)){

      this.userManagementService.removeAdmin(user.intId).subscribe(
        data => {
          for (let index = this.admins.length - 1; index >= 0; index--) {
            const element = this.admins[index];
            if (element.username == user.username){
              this.admins.splice(index, 1);
            }        
          }          
        }
      )


    }
  }
  removePending(user){
    for (let index = this.pendingAdmins.length - 1; index >= 0; index--) {
      const element = this.pendingAdmins[index];
      if (element.username == user.username){
        this.pendingAdmins.splice(index, 1);
      }        
    }
    
  }

}
