import { AlertsHomeComponent } from './alerts-home/alerts-home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AlertArchiveComponent } from './alert-archive/alert-archive.component';
import { AlertsComponent } from './alerts.component';
import { AlertDetailComponent } from './alert-detail/alert-detail.component';
import { AlertEditComponent } from './alert-edit/alert-edit.component';
import { AlertListComponent } from './alert-list/alert-list.component';
import { AuthGuard } from './../_guards/auth.guard';


const routes: Routes = [
  { path: '', component: AlertsComponent, canActivate: [AuthGuard], children: [    
    { path: '', component: AlertsHomeComponent, canActivate: [AuthGuard] },
    { path: 'archived/:siteId', component: AlertArchiveComponent, canActivate: [AuthGuard] },
    { path: ':siteId', component: AlertListComponent, canActivate: [AuthGuard] },
    { path: ':siteId/new', component: AlertEditComponent, canActivate: [AuthGuard] },
    { path: ':siteId/:alertId', component: AlertDetailComponent, canActivate: [AuthGuard]},
    { path: ':siteId/:alertId/edit', component: AlertEditComponent, canActivate: [AuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlertsRoutingModule {}
// export const routing: ModuleWithProviders = RouterModule.forChild(routes);