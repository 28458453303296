<nav
  *ngIf="severitySent"
  [style.background-color]="severityColour"
  class="navbar sticky-top"
></nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
