<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="display-3" i18n>
      <i class="fa fa-file-text"></i> Reports</h1>
    <p class="lead" i18n>Reports allow you to review alerts on a periodic basis.</p>
    <!-- <hr class="my-4"> -->
  </div>
</div>

<div *ngIf="loading; else normal">
  <p class="text-center mt-5">
    <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
  </p>
</div>

<ng-template #normal>

  <app-site-reports 
    *ngFor="let site of reports" 
    [siteReports]="site">
  </app-site-reports>

</ng-template>