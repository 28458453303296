<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="display-3" i18n>
      <i class="fa fa-exclamation-circle"></i> Alerts
    </h1>
    <p class="lead" i18n>
      Alerts are the main method of communication for our service.
    </p>
    <hr class="my-4" />
    <p i18n>Please select a site to view alerts.</p>

    <!-- <label for="typeahead-focus" i18n>Search for a site to view alerts:</label>
    <input
      id="typeahead-focus"
      type="text"
      class="form-control"
      [editable]="false"
      [(ngModel)]="siteName"
      [ngbTypeahead]="search"
      [resultFormatter]="inputFormatSiteName"
      [inputFormatter]="inputFormatSiteName"
      (selectItem)="navigateTo($event.item)"
      (focus)="focus$.next($event.target.value)"
      (click)="click$.next($event.target.value)"
      #instance="ngbTypeahead"
    /> -->

    <!-- <pre>Site Names: {{ siteName | json }}</pre> -->
    <form class="form-inline">
      <select class="form-control" (change)="navigateTo($event.target.value)">
        <option value="" i18n>Select a Site</option>
        <option *ngFor="let site of sites" value="{{ site.siteId }}">{{
          site.name
        }}</option>
      </select>
    </form>
    <button
      *ngIf="favSite != null"
      class="btn btn-success mt-2"
      (click)="goToFavSite()"
    >
      {{ favSite.siteName }}
    </button>
    <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin fa-2x"></i>

    <br />
    <app-info-alert></app-info-alert>
    <hr />

    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="styles">
      <agm-marker
        *ngFor="let s of sites"
        [latitude]="s.latitude | toFloat"
        [longitude]="s.longitude | toFloat"
      >
        <agm-info-window>
          <a routerLink="{{ s.siteId }}">{{ s.name }}</a>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
