import { ConnStrModule } from "./../_services/conn-str.module";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { InfoAlertService } from "./../_services/info-alert.service";
import { AuthenticationService } from "./../_services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  model = {
    username: "",
    password: ""
  };

  usernameIsNull = false;
  passwordIsNull = false;

  @ViewChild("username", { static: false }) usernameInp: ElementRef;
  @ViewChild("password", { static: false }) passwordInp: ElementRef;

  loading = false;
  refreshing = false;
  returnUrl: string;
  rememberMe = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private infoAlertService: InfoAlertService,
    private connStrModule: ConnStrModule
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.rememberMe = this.authenticationService.RememberMeLogin();

    var refreshOk = true;

    if (this.rememberMe) {
      this.refreshing = true;
      this.authenticationService.refreshHubToken().subscribe(
        data => {
          this.authenticationService.processHubTokens(data);
          refreshOk = true;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          refreshOk = false;
          this.refreshing = false;
        }
      );
    } else {
      this.refreshing = false;
      refreshOk = false;
    }

    if (!refreshOk) {
      // reset login status
      this.authenticationService.logout();
    }
  }
  goToEnerlyticsLogin() {
    switch (this.authenticationService.locale) {
      case "es":
        window.location.href = this.enerlyticsLoginUrl(
          this.connStrModule.es_clientId,
          this.connStrModule.es_smartHubBaseUrl
        );
        break;
      case "de":
        window.location.href = this.enerlyticsLoginUrl(
          this.connStrModule.de_clientId,
          this.connStrModule.de_smartHubBaseUrl
        );
        break;
      default:
        window.location.href = this.enerlyticsLoginUrl(
          this.connStrModule.default_clientId,
          this.connStrModule.smartHubBaseUrl
        );
        break;
    }
    /*  if (this.authenticationService.locale === "es") {
      window.location.href = this.enerlyticsesLoginUrl();
    } else {
      window.location.href = this.enerlyticsLoginUrl();
    } */
  }

  goToPSLogin() {
    window.location.href =
      "https://acm-login-app.azurewebsites.net/login?client_id=smarthub&grant_type=authorization_code";
  }

  enerlyticsLoginUrl(clintId: string, redirectUri: string) {
    return `https://udl.uniper.engineering/authenticate/authorize?response_type=code&scope=standard_user&client_id=${clintId}&redirect_uri=
      ${redirectUri}
      ExternalAuth/enerlytics`;
  }
  /*   enerlyticsesLoginUrl() {
    return (
      "https://udl.uniper.engineering/authenticate/authorize?response_type=code&scope=standard_user&client_id=TeG74HzlMMPHqGkzCJDRMQQOrDOi6PQY&redirect_uri=" +
      this.connStrModule.es_smartHubBaseUrl +
      "ExternalAuth/enerlytics"
    );
  } */

  onRememberMeChange() {
    this.rememberMe = !this.rememberMe;
    this.authenticationService.changeRememberMe(this.rememberMe);
  }

  login() {
    this.model.username = this.usernameInp.nativeElement["value"];
    this.model.password = this.passwordInp.nativeElement["value"];
    this.usernameIsNull = false;
    this.passwordIsNull = false;

    if (this.model.password.length == 0) {
      this.passwordIsNull = true;
    }
    if (this.model.username.length == 0) {
      this.usernameIsNull = true;
    }
    if (this.model.username.length == 0 || this.model.password.length == 0) {
      return;
    }

    this.loading = true;

    this.authenticationService
      .login(this.model.username, this.model.password)
      .subscribe(
        data => {},
        error => {
          this.infoAlertService.error(error.error);
          this.loading = false;
        },

        () => {
          this.authenticationService
            .getUser()
            .subscribe(user =>
              this.authenticationService.currentUserSubject.next(user)
            );
          this.router.navigate([this.returnUrl]);
        }
      );
  }
}
