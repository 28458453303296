<div class="div text-center" >
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div>
                    <img src="/assets/Uniper_Logo_RGB_White.png" height="50" alt="">
                </div>
                <div>
                    <a href="http://www.universeom.com"><small class="text-muted">&copy; {{ currentYear() }} Uniper Production Solutions</small></a>
                </div>
                <div class="text-right">
                    <a class="btn btn-light btn-sm m-2" [routerLink]='["Admin"]'><i class="fa fa-lock"></i> Admin</a>
                </div>
            </div>
        </div>        
    </div>
    <!-- <div>
        <img src="/assets/Uniper_Logo_RGB_White.png" height="50" alt="">
    </div>
    <div>
        <a href="http://www.universeom.com"><small class="text-muted">&copy; {{ currentYear() }} Uniper Production Solutions</small></a>
    </div>
    <div>
        <button class="btn btn-dark m-2"><i class="fa fa-lock"></i> Admin</button>
    </div> -->

</div>