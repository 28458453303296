<a class="list-group-item list-group-item-action flex-column align-items-start pb-0" [routerLink]="['/MSR/sites', siteId, plantItem.id]"
  routerLinkActive="active">
  <p>
    <b>{{plantItem.functionalLocation}}</b><br>
    <span class="text-muted">      
      <span class="d-none d-lg-block"><i class="fa fa-heartbeat"></i> 69% EOH remaining | <i class="fa fa-wrench"></i> {{plantItem.strategy}}</span>      
      <span class="d-lg-none"><i class="fa fa-heartbeat"></i> 69% | <i class="fa fa-wrench"></i> {{plantItem.strategy}}<br></span>
    </span>
    {{plantItem.name}}<br>
  </p>
</a>