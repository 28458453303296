import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-admin',
  templateUrl: './global-admin.component.html',
  styleUrls: ['./global-admin.component.css']
})
export class GlobalAdminComponent implements OnInit {

  loadingUsers = true;
  adminLoadError = false;

  adding = false;
  users = [];
  pendingUsers = [];
  selectedRole;

  constructor(
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
    this.loadUsers();
  }

  loadUsers(){
    this.userManagementService.getGlobalUsers().subscribe(
      data => {
        for (const user of data) {
          user['accessGiven'] = JSON.parse(user['accessGiven']);
        }
        this.users = data;
        this.loadingUsers = false;
      }, error => {
        this.loadingUsers = false;
        this.adminLoadError = true;
        alert("Unable to get users with global access rights. Error: " + error.error);
      }
    )
  }

  removeUser(user){
    if (confirm(`Remove global user permissions for ${user['name']}?`)){

      this.userManagementService.removeUser(user.intId, "global", "null").subscribe(
        data => {
          for (let index = this.users.length - 1; index >= 0; index--) {
            const element = this.users[index];
            if (element.username == user.username){
              this.users.splice(index, 1);
            }        
          }          
        }
      )
    }
  }
  removePending(user){
    for (let index = this.pendingUsers.length - 1; index >= 0; index--) {
      const element = this.pendingUsers[index];
      if (element.username == user.username){
        this.pendingUsers.splice(index, 1);
      }        
    }    
  }
  addUsers(){
    this.adding = true;
    var userIds = [];
    this.pendingUsers.forEach(element => {
      userIds.push(element.intId);
    });
    this.userManagementService.addUsers(userIds,"global", "null", this.selectedRole).subscribe(
      data => {
        // for (const pendingUser of this.pendingUsers) {
        //   if (this.users.findIndex(x => x.intId == pendingUser.intId) == -1){
        //     this.users.push(pendingUser);
        //   }
        // };
        // this.pendingUsers = [];
        this.loadUsers();
        this.adding = false;
      }, error => {
        this.adding = false;
        alert("Error adding user: " + error.error);        
      }
    )

  }
  searchedUserSelected(user){
    console.log("User selected", user);
    if (this.pendingUsers.findIndex(x => x.username == user.username) == -1){
      this.pendingUsers.push(user);
    }
  }

}
