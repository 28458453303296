import { Alert } from './../_models/alert.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAlerts',
  pure: true
})
export class FilterAlertsPipe implements PipeTransform {

  // transform(alerts: Alert[], filterStr: string, filterProp: string): any {
    transform(alerts: Alert[], filterStr: string, statuses: {}[]): any {
      console.log("filtering");
      console.log(statuses);
      if (alerts.length === 0 || (filterStr.length === 0 && statuses == null ) ) {
      return alerts;      
    }
    // break the search string into a list of words
    let searchTerms = filterStr.split(" ");
    // console.log(searchTerms)
    const resultArray = [];
    // check each alert for all search terms
    for (const alert of alerts) {

      let statusFound = false;
      // if there are no statuses sent, skip the status search
      if (statuses == null || statuses.length == 0){
        statusFound = true;
      }
      else 
      {
        for(let aStatus of statuses){
          if (alert.status.id == aStatus["id"] || alert.severity.id == aStatus["id"]) {
            statusFound = true;
            continue;
          }
        }
      }
      if (!statusFound){
        continue;
      }

      let allMatched = true;
        for (let term of searchTerms) {
          if (
            // we stringify each alert so we check the entire contents of the alert by default
            JSON.stringify(alert).toUpperCase().indexOf(term.toUpperCase()) >= 0

            // alert.name.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0 || 
            // alert.summary.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0 ||
            // alert.recommendation.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0 ||
            // alert.feedback.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0 ||
            // alert.status.description.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0 ||
            // alert.severity.description.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0

          ) {
            // all terms found - do nothing
          } else {
            // this term was missing. Do not add the alert to the results
            allMatched = false;
            break;
          }
        }
      if (allMatched) {
        resultArray.push(alert);
      }

    }
    return resultArray;
  }

}
