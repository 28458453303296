import { UserManagementService } from './../../_services/user-management.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  model;
  submitted = false;
  updating = false;
  userId;

  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {

    this.model = {
      name: '',
      email: '',
      enabled: null
    }

    this.route.params
    .subscribe(
      (params: Params) => {
        this.userId = params['userId'];

      this.userManagementService.getUserDetails(this.userId).subscribe(
        data => {
          this.model = data;
        }, error => {
          alert("Error loading user: " + error.error);
        }
      )

      }
    );


  }


  onSubmit() { 
    this.updating = true;
    this.submitted = true; 
    this.userManagementService.updateUser(this.userId, this.model).subscribe(
      data => {
        this.updating = false;
      }, error => {
        this.updating = false;
        alert("Unable to edit user. Error: " + error.error);
      }
    )
  }

  welcomeSendClicked = false;
  welcomeSending = false;
  welcomeSent = false;
  welcomeError = false;
  sendWelcomeEmail(){
    this.welcomeSending = true;
    this.welcomeSendClicked = true;
    this.userManagementService.sendWelcomeEmail(this.model.username).subscribe(
      data =>{
        this.welcomeSending = false;
        this.welcomeSent = true;
      }, error => {
        this.welcomeSending = false;
        this.welcomeError = true;
      }
    )
  }
  
  
}
