import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-msr-item',
  templateUrl: './msr-item.component.html',
  styleUrls: ['./msr-item.component.css']
})
export class MsrItemComponent implements OnInit {

  @Input() plantItem;
  @Input() siteId = '';

  constructor() { }

  ngOnInit() {
    if (this.plantItem == null){
    this.plantItem = {};
    }
  }

}
