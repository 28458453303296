<!-- <div class="content">
  <app-header></app-header>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="push"></div>
</div>
<app-footer></app-footer> -->


<div class="content">

  <app-header></app-header>
  
  <router-outlet></router-outlet>

  <div class="push"></div>
</div>
<app-footer></app-footer>