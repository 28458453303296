import { retry } from 'rxjs/operator/retry';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ReportsService } from './../../_services/reports.service';
import { Component, OnInit, Input } from '@angular/core';
import { Alert } from '../../_models/alert.model';

@Component({
  selector: 'app-report-alert-item-short',
  templateUrl: './report-alert-item-short.component.html',
  styleUrls: ['./report-alert-item-short.component.css']
})
export class ReportAlertItemShortComponent implements OnInit {

  @Input() alert: Alert;
  @Input() mode: string;
  @Input() editMode: boolean;

  siteId;
  reportId;

  reviewedText = "Reviewed";
  removeText = "Remove";

  constructor(
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params
    .subscribe(
      (params: Params) => {
        this.siteId = params['siteId'];
        this.reportId = params['reportId'];
      }
    );
  }

  toggleReview(){
    this.alert['reviewed'] = !this.alert['reviewed'];
    console.log(this.alert);
    this.reviewedText = "Updating..."
    this.reportsService.toggleReportAlertReview(this.siteId, this.reportId, this.alert['hashId'], this.alert['reviewed'])
      .subscribe(
        (data) => {
          console.log("pached alert review");
          this.reviewedText = "Reviewed"          
        }, 
        (error) => {
          console.log("error patching alert");
          console.log(error);
        }
      )
  }

  removeAlert(){

    let confirmRemove = confirm("Are you sure?");

    if (confirmRemove) {
      console.log("Remove alert from report");
      this.removeText = "Removing..."
      this.reportsService.removeReportAlert(this.siteId, this.reportId, this.alert['hashId'])
        .subscribe(
          data => {
            this.removeText = "Removed"
            this.reportsService.alertRemoved.next(data);
          }
        )
    }

  }


}
