<div class="jumbotron jumbotron-fluid" [hidden]="collapsed">
  <div class="container">
    <h1 class="display-3" i18n>
      <i class="fa fa-globe"></i> Actionable Alerts
    </h1>
    <p class="lead" i18n>
      Alerts in creation, pending approval, or requiring feedback<br />
      <span class="text-muted">This page will refresh every 3 minutes</span>
    </p>
  </div>
</div>
<button class="btn btn-link" (click)="collapsed = !collapsed" i18n>
  <i class="fa fa-chevron-up" aria-hidden="true"></i> Show / Hide header
</button>
<app-actionable-company
  *ngFor="let company of companies"
  [company]="company"
></app-actionable-company>
