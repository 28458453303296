<!-- <a class="list-group-item list-group-item-action flex-column align-items-start" 
  routerLinkActive="active">

  <p><strong>{{ signalResult.name }}</strong></p>
  <small class="text-muted">Measured in: {{ signalResult.units }}</small>
  
  <p>{{ (signalResult.description.length>140)? (signalResult.description | slice:0:140)+'...':(signalResult.description) }}</p>
</a> -->

<div>
  <button 
    type="button" 
    class="btn btn-success btn-sm" 
    style="cursor: pointer;"
    (click)="onSignalAddClick()"><i class="fa fa-plus" aria-hidden="true"></i>
  </button>
  <strong>
    {{ signalResult.name }}
  </strong>
  <br>
    <small class="text-muted" i18n>Measured in: {{ signalResult.measuredIn }}</small>
  <br>
  <small class="text-muted">
    {{ (signalResult.description.length>140)? (signalResult.description | slice:0:140)+'...':(signalResult.description) }}
  </small>

</div>
<hr>
