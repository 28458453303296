import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatIcon',
  pure: true
})
export class ChatIconPipe implements PipeTransform {

  transform(text: string): any {

    if( 
        text.toUpperCase().indexOf('feedback'.toUpperCase()) > -1 ||
        text.toUpperCase().indexOf('summary'.toUpperCase()) > -1 ||
        text.toUpperCase().indexOf('recommendation'.toUpperCase()) > -1        
    ){
        return '<i class="fa fa-comment-o" aria-hidden="true"></i>'
    }
  }

}
