import { InfoAlertService } from './../_services/info-alert.service';
import { SitesService } from './../_services/sites.service';
import { Subscription } from 'rxjs/Subscription';
import { AlertsService } from './../_services/alerts.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Site } from './../_models/site.model';
import { Alert } from './../_models/alert.model';
import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../_services/data-storage.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  loading = true;
  sites: Site[];
  siteSubscription: Subscription;
  severityColour = '';
  severitySent = false;

  lat = 50;
  lng = 10;
  zoom = 3;
  styles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]

  constructor(    
    private route: ActivatedRoute,
    private router: Router,
    private alertsService: AlertsService,
    private sitesService: SitesService,
    private dataStorageService: DataStorageService,
    private infoAlertService: InfoAlertService
  ) { }

  ngOnInit() {

    this.alertsService.alertSeverity
      .subscribe(
        data => {
          this.severitySent = true;
          this.severityColour = this.getSeverityColour(data);
          console.log("severity colour " + this.severityColour)
        }
      )
    this.alertsService.noSeverity
      .subscribe(
        data => {
          this.severitySent = false;
        }
      )
  }


  getSeverityColour(severityId: number){

    switch(severityId) {
      case 10: { 
        return "#ff0000";
      } 
      case 20: { 
        return "#ffa500";
      } 
      case 30: { 
        return "#ffff00";
      } 
      case 40: { 
        return "#dbdbdb";
      } 
      case 50: { 
        return "#52f2fa";
      } 
      case 60: { 
        return "#92d050";
      } 
      default: { 
         return "";
      } 
   }  
}

  // navigateTo(siteId: string){
  //   console.log(siteId);
  //   this.router.navigate([siteId], {relativeTo: this.route});
  // }



}
