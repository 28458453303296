<div class="mt-1" style="position:relative; height: 50px;">
  <span class="badge badge-pill badge-light" style="position:absolute; bottom:2px; left:2px; z-index:99">{{sparkname}} 
    <span *ngIf="isOn()" class="text-primary" title="Live load"><i class="fa fa-bolt"></i> {{liveLoad()}} MW</span>
    <span *ngIf="!isOn() && !loading" class="text-danger"><i class="fa fa-bolt"></i> {{loadingText()}}</span>
    <span *ngIf="loading" class="text-warning"><i class="fa fa-bolt" i18n></i> loading</span>
  </span>
  <chart
    [options]="options"
    (load)="saveInstance($event.context)">
  </chart>
</div>