<div class="row justify-content-center">
  <div class="col-sm-8">

    <h3 i18n>Edit Chart</h3>

    <form (ngSubmit)="onSubmit()" #chartForm="ngForm">
     
      <button type="button" class="btn btn-success" [disabled]="!chartForm.valid" (click)="onSubmit()"i18n>Save</button>
      <button *ngIf="editMode" type="button" class="btn btn-success" [routerLink]="['./series']" i18n>Edit Series</button>
      <button type="button" class="btn btn-danger" (click)="onCancel()" i18n>Cancel</button>

      <div class="form-group">
          <label for="chartName" i18n>Name</label>
          <input type="text" class="form-control" id="chartName"
                 required
                 [(ngModel)]="chart.chartName" name="chartName"
                 #chartName="ngModel">
          <p [hidden]="chartName.valid || chartName.pristine"
               class="text-danger"i18n>
            Chart name required
          </p>
      </div>

      <div class="form-group">
        <label for="chartDesc" i18n>Description</label>
        <textarea 
          type="text" 
          class="form-control" 
          id="chartDesc" 
          name="chartDesc" 
          [(ngModel)]="chart.description" name="chartDesc"
          rows="2">
        </textarea>
      </div>

    </form>

  </div>
</div>
