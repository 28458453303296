import { DashboardsService } from './../../_services/dashboards.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-folder',
  templateUrl: './dash-folder.component.html',
  styleUrls: ['./dash-folder.component.css']
})
export class DashFolderComponent implements OnInit {

  @Input() name = '';
  @Input() folders = [];
  @Input() items = [];
  @Input() directory = '';

  expanded = false;

  constructor(
    private dashboardsService: DashboardsService
  ) { }

  ngOnInit() {
    if (this.folders == null){
      this.folders = [];
    }
    if (this.items == null){
      this.items = [];
    }
    console.log("directory:" + this.folderPath())
  }

  moveDashboard(dashId: string){
    console.log("Move dashboard:" + dashId + " to directory " + this.directory + "/" + this.name);
    this.dashboardsService.moveDashboard(this.directory + '/' + this.name, dashId);
  }

  dashboardList(){
    return this.dashboardsService.dashboardList();
  }

  folderPath(){
    return this.directory + "/" + this.name;
  }

  newFolder() {
    let newFolderName = prompt("Folder name", "New Folder");
        
    if (newFolderName == null){
      return;
    }

    if (newFolderName == ''){
      return;
    }

    newFolderName = newFolderName.replace(/\//g," ");

    console.log("new folder: " + this.folderPath() + '/' + newFolderName);
    this.dashboardsService.addFolder(this.folderPath(), newFolderName);
    this.expanded = true;
  }

  editName() {
    let oldFolderName = name;
    let newFolderName = prompt("Edit folder name", oldFolderName);
        
    if (newFolderName == null){
      return;
    }
    if (newFolderName == ''){
      return;
    }

    newFolderName = newFolderName.replace(/\//g," ");

    console.log("edited folder name: " + this.folderPath() + '/' + newFolderName);
    this.dashboardsService.editFolder(this.folderPath(), oldFolderName, newFolderName);
  }

  deleteFolder(){
    if(confirm("Are you sure?")){
      this.dashboardsService.removeFolder(this.directory, this.name);
    }
  }

}
