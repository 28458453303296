import { SitesService } from './../../_services/sites.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fleet-alerts-home',
  templateUrl: './fleet-alerts-home.component.html',
  styleUrls: ['./fleet-alerts-home.component.css']
})
export class FleetAlertsHomeComponent implements OnInit {

  companies = [];

  constructor(
    private sitesService: SitesService
  ) { }

  ngOnInit() {

    this.sitesService.getCompanies()
      .subscribe(
        data => {
          console.log("companies got");
          this.companies = data;
        }
      )
  }

}
